import React from 'react'
import { Box, Typography } from "@mui/material";
import { styles } from './logo.styles';
import { routes } from "routing/Routes";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default function PartTimeLogo({language}) {
    const user = useSelector((state) => state.profile.accessToken);
    const history = useHistory();
    return (
        <Box sx={styles.logo}>
            <div style={styles.logoImgWrapper} onClick={() => history.push(!user ? routes().signup : routes().resumes)}>
                <img style={styles.logoImg} src="../../logo/logo.svg" alt="logo" />
            </div>
            <Typography sx={styles.title}>{language['Resume.title']}</Typography>
        </Box>
    )
}
