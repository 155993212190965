export const styles = {
  section: {
    maxWidth: "970px",
    margin: "0px auto 70px auto",
    "@media (max-width: 1000px)": {
      padding: "0 12px",
      margin: "0 auto",
    },
    "@media (max-width: 800px)": {
      padding: "0",
    },
  },
  block: {
    columnGap: '10px',
    marginBottom: "10px",
    "@media (max-width: 800px)": {
      padding: "0 10px",
    },
  },
  container: {
    display: "flex",
    border: "1px solid #ccc",
    "@media (max-width: 800px)": {
      flexDirection: "column",
      border: "none",
    },
  },
  selfIntroduction: {
    "@media (min-width: 801px)": {
      paddingTop: "13px",
      maxWidth: "300px",
      width: "100%",
      borderRight: "1px solid #ccc",
      padding: "0 16px",
    },
  },
  fields: {
    width: "100%",
  },
};
