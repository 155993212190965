import React from "react";
import ModalComponent from "components/modal/ModalComponent";
import Sharing from "components/sharing/Sharing";
import { useHistory } from "react-router-dom";
import { routes } from "routing/Routes";
import { useSelector } from "react-redux";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { styles } from "./dotButtonMenu.styles";

export default function DotButtonMenu({
  show,
  setShow,
  onDelete,
  openShareModal,
  setOpenShareModal,
  resume,
}) {
  const history = useHistory();
  const language = useSelector((state) => state.footer.language);

  return (
    <div style={{ position: "relative" }}>
      <button sx={styles.actionBox} onClick={() => setShow(!show)}>
        <FiberManualRecordIcon sx={styles.fiberIcon} />
        <FiberManualRecordIcon sx={styles.fiberIcon} />
        <FiberManualRecordIcon sx={styles.fiberIcon} />
      </button>

      {show && (
        <div style={styles.menu}>
          <button>
            <div style={styles.itemMenu}>
              <button onClick={() => {
                setShow(false);
                setOpenShareModal(!openShareModal);
              }}>
                <img
                  src="../../svg/share.svg"
                  style={styles.menuImage}
                  alt=""
                />
                <span>{language['Resume.forms.share']}</span>
              </button>
            </div>
          </button>
          <button
            onClick={() => {
              setShow(false);
              history.push(routes({ id: resume._id }).resumeById);
            }}
          >
            <div style={styles.itemMenu}>
              <img
                src="../../svg/download.svg"
                style={styles.menuImage}
                alt=""
              />
              <span>{language['Resume.forms.downloadPDF']}</span>
            </div>
          </button>
          <button onClick={() => {
            setShow(false);
            onDelete();
          }}>
            <div style={styles.itemMenu}>
              <img src="../../svg/delete.svg" style={styles.menuImage} alt="" />
              <span>{language['Resume.file.delete']}</span>
            </div>
          </button>
        </div>
      )}

      <ModalComponent isOpen={openShareModal}>
        <Sharing setIsOpen={setOpenShareModal} />
      </ModalComponent>
    </div>
  );
}
