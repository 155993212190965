import React from "react";
import DoubleInput from "custom/inputs/doubleInput/DoubleInput";

import UploadFile from "../../custom/buttons/uploadButton/UploadFile";
import AutoInput from "../../custom/inputs/autoInput/AutoInput";
import Textarea from "../../custom/inputs/textarea/Textarea";
import TextInput, { TextInputType } from "custom/inputs/textInput/TextInput";

export default function ExperienceIntroduction({
  values,
  handleChange,
  setFieldValue,
  errors,
  isSubmitted,
  language,
}) {
  return (
    <div style={{ position: "relative", padding: "0 16px" }}>
      <Textarea
        name={"motivation"}
        title={language['Resume.offer.motivation']}
        placeholder={language['Resume.offer.reason']}
        value={values.motivation}
        handleChange={handleChange}
        errors={errors.motivation}
      />

      <DoubleInput
        name={"workHistory"}
        type={"text"}
        title={language['Resume.forms.workhistory']}
        value={values.workHistory}
        handleChange={handleChange}
        errors={errors.workHistory}
        isRequired={true}
        setFieldValue={setFieldValue}
        isSubmitted={isSubmitted}
      />

      <AutoInput
        name={"industries"}
        type={"text"}
        title={language['Resume.offer.industory']}
        value={values.industries}
        handleChange={handleChange}
        errors={errors.industries}
        isRequired={false}
        setFieldValue={setFieldValue}
        isSubmitted={isSubmitted}
      />

      <AutoInput
        name={"experiences"}
        type={"text"}
        title={language['Resume.forms.skills']}
        value={values.experiences}
        handleChange={handleChange}
        errors={errors.experiences}
        isRequired={true}
        setFieldValue={setFieldValue}
        isSubmitted={isSubmitted}
      />

      <AutoInput
        name={"languages"}
        type={"text"}
        title={language['Resume.forms.languages']}
        value={values.languages}
        handleChange={handleChange}
        errors={errors.languages}
        isRequired={false}
        setFieldValue={setFieldValue}
        isSubmitted={isSubmitted}
      />

      <AutoInput
        name={"certifications"}
        type={"text"}
        title={language['Resume.forms.certifications']}
        value={values.certifications}
        handleChange={handleChange}
        errors={errors.certifications}
        isRequired={false}
        setFieldValue={setFieldValue}
        isSubmitted={isSubmitted}
      />

      <AutoInput
        name={"awards"}
        type={"text"}
        title={language['Resume.forms.awards']}
        value={values.awards}
        handleChange={handleChange}
        errors={errors.awards}
        isRequired={false}
        setFieldValue={setFieldValue}
        isSubmitted={isSubmitted}
      />

      <AutoInput
        name={"schools"}
        type={"text"}
        title={language['Resume.forms.schools']}
        value={values.schools}
        handleChange={handleChange}
        errors={errors.schools}
        isRequired={false}
        setFieldValue={setFieldValue}
        isSubmitted={isSubmitted}
      />

      <Textarea
        name={"selfPromotion"}
        title={language['Resume.forms.selfPromotions']}
        placeholder={language['Resume.forms.selfPromotionsFor']}
        value={values.selfPromotion}
        handleChange={handleChange}
        errors={errors.selfPromotion}
      />

      <AutoInput
        name={"skills"}
        type={"text"}
        title={language['Resume.forms.interestingSkills']}
        value={values.skills}
        handleChange={handleChange}
        errors={errors.skills}
        isRequired={false}
        setFieldValue={setFieldValue}
        isSubmitted={isSubmitted}
      />

      <AutoInput
        name={"interests"}
        type={"text"}
        title={language['Resume.forms.interestingIndustory']}
        value={values.interests}
        handleChange={handleChange}
        errors={errors.interests}
        isRequired={false}
        setFieldValue={setFieldValue}
        isSubmitted={isSubmitted}
      />

      <UploadFile
        files={values.files}
        serverFiles={values.uploadFiles}
        setFieldValue={setFieldValue}
        language={language}
      />

      <TextInput
        type={TextInputType.DATE}
        dateFormat={language['dateFormat']}
        name={"whenStart"}
        text={language['Resume.forms.whenStart']}
        isRequired={true}
        value={values.whenStart}
        onChange={(__value) => {
          setFieldValue('whenStart', __value);
        }}
        errors={errors.whenStart}
        isSubmitted={isSubmitted}
      />
    </div>
  );
}
