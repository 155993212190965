import * as React from "react";
import {Component, HTMLProps} from "react";

interface Props extends HTMLProps<any> {
    color?: string;
    width?: string;
}

interface State {
    
}

export default class SvgIcon_Download extends Component<Props, State> {
    
    static defaultProps = {
        color: '#29CC8F',
        width: '21px',
    };

    constructor(props: Props) {
        super(props);
    }

    render() {
        let fillColor = this.props.color || '';
        if (fillColor.length == 0) {
            fillColor = '#29CC8F';
        }

        return (
            <svg width={this.props.width} height={this.props.width} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.181824" y="17.5" width="20.606" height="2.5" rx="0.5" fill={fillColor}/>
                <path fillRule="evenodd" clipRule="evenodd" d="M9.697 0C9.42086 0 9.197 0.223858 9.197 0.5V10.5H5.68452C5.21322 10.5 5.00365 11.0924 5.37009 11.3888L10.1386 15.2457C10.322 15.394 10.5841 15.394 10.7675 15.2457L15.5361 11.3888C15.9025 11.0924 15.6929 10.5 15.2216 10.5H11.7728V0.5C11.7728 0.223858 11.5489 0 11.2728 0H9.697Z" fill={fillColor}/>
            </svg>
        );
    }

}