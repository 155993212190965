import React from "react";

import { Box } from "@mui/material";
import { styles } from "./forgotPassword.styles";

export default function ImageBox({ type }) {
  return (
    <Box sx={styles.imgBox}>
      {type === "first" ? (
        <img
          src="../../svg/forgotPassword.svg"
          style={{ width: "inherit", height: "inherit" }}
          alt=""
        />
      ) : (
        <img
          src="../../svg/hand-out.svg"
          style={{ width: "inherit", height: "inherit" }}
          alt=""
        />
      )}
    </Box>
  );
}
