import React from "react";

import { Box } from "@mui/material";
import { styles } from "./mobileSavingButton.styles";
import ScrollTopButton from "../scrollTopButton/ScrollTopButton";
import { useSelector } from "react-redux";
import { ResumeStatus } from "models/resume";

export default function MobileSavingButton({ saveChanges, publishResume, unpublishResume, resumeStatus }) {
  const language = useSelector((state) => state.footer.language);
  return (
    <Box sx={styles.mobileAction}>
      <button
        style={{ ...styles.btn, ...styles.save }}
        onClick={() => saveChanges()}
      >
        {language['Resume.forms.saveChanges']}
      </button>
      <button
        style={{ ...styles.btn, ...styles.publish }}
        onClick={() => publishResume()}
      >
        {language['Resume.page.publish']}
      </button>
      {(resumeStatus && resumeStatus === ResumeStatus.published) &&
        <button
          style={{ ...styles.btn, ...styles.publish }}
          onClick={() => unpublishResume()}
        >
          {language['Resume.page.unpublish']}
        </button>
      }
      <div style={styles.goTop}>
        <ScrollTopButton />
      </div>
    </Box>
  );
}
