import React, { useState } from "react";
import { main } from "colors";
import { styles } from "components/auth/forgotPassword/forgotPassword.styles";
import ImageBox from "components/auth/forgotPassword/ImageBox";
import AuthInput from "custom/inputs/authInput/AuthInput";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useAuthActions } from "redux/auth/useAuthActions";
import { routes } from "routing/Routes";

import { Grid, Box, Typography, Button } from "@mui/material";
import SeparatorLine from "../../custom/separatorLine/SeparatorLine";
import AuthLayout from "layouts/AuthLayout";

export default function ForgotPassword() {
  const [isSubmit, setIsSubmit] = useState(false);
  const [email, setEmail] = useState("");
  const [errors] = useState(false);
  const { forgotPassword } = useAuthActions();
  const errorMessage = useSelector((state) => state.auth.errorMessage);
  const language = useSelector((state) => state.footer.language);

  const onSubmit = async () => {
    let data = {
      email: email,
    };

    forgotPassword(data);
    setIsSubmit(!isSubmit);
  };

  return (
    <AuthLayout>
      <>
        <Grid container sx={styles.container}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "60px",
              paddingTop: "60px",
            }}
          >
            <Box sx={{ height: "37px" }}>
              <img
                src="../../logo/logo.svg"
                alt="logo"
                style={{ height: "inherit" }}
              />
            </Box>

            <Box
              sx={{
                marginLeft: "10px",
                fontWeight: "500",
                fontSize: "18px",

                color: `${main}`,
              }}
            >
              <Typography sx={{ lineHeight: "27px" }}>WelcomeHR</Typography>
              <Typography>{language['Resume.title']}</Typography>
            </Box>
          </Box>

          <Grid sx={styles.content}>
            {!isSubmit && errorMessage.length === 0 ? (
              <Grid sx={{ width: "inherit" }}>
                <ImageBox type={"first"} />
                <Box>
                  <Box sx={styles.textBox}>
                    <Typography sx={styles.title}>
                      {language['Authen.forgotPassword']}
                    </Typography>
                    <Typography sx={styles.subtitle}>
                    {language['Authen.enterEmail']}
                    </Typography>
                  </Box>

                  <Box sx={{ position: "relative", marginBottom: "25px" }}>
                    <AuthInput
                      name={"email"}
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      placeholder={language['Resume.contact.email']}
                      errors={errors}
                    />
                  </Box>
                  <Button sx={styles.button} onClick={onSubmit}>
                  {language['Resume.contact.submit']}
                  </Button>
                </Box>
                <Box>
                  <SeparatorLine />
                  <Link to={routes().signup} style={styles.link}>
                    {language['Authen.loginPage.createResumeApp']}
                  </Link>
                </Box>
              </Grid>
            ) : (
              <Grid sx={{ width: "inherit" }}>
                <ImageBox type={"second"} />
                <Typography sx={styles.text}>
                  {language['Authen.resetPassword.sendNewPassword']}
                </Typography>
                <Typography sx={styles.text}>
                {language['Authen.resetPassword.sendNewPassword']}
                </Typography>
                <Link to={routes().signin} style={styles.link}>
                {language['Authen.Login']}
                </Link>
              </Grid>
            )}
          </Grid>
        </Grid>
      </>
    </AuthLayout>
  );
}
