import React, { useState, useEffect } from "react";
import { useRef } from "react";
import { danger, grey } from "colors";
import DoubleOutput from "custom/outputs/doubleOutput/DoubleOutput";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as moment from 'moment';
import { DateRangePicker } from 'react-date-range';

import { Typography } from "@mui/material";
import { Box } from "@mui/system";

import "./doubleInput.styles.css";

export default function DoubleInput({
  type,
  value,
  name,
  setFieldValue,
  isSubmitted,
  errors,
  title,
  isRequired
}) {
  const [place, setPlace] = useState("");
  const [placeSubmitted, setPlaceSubmitted] = useState(false);
  const languageCode = useSelector((state) => state.footer.languageCode);
  const language = useSelector((state) => state.footer.language);

  const inputDateRef = useRef();
  const dateOPickerRef = useRef();
  let [formattedValue, setFormattedValue] = useState('');
  let [startDate, setStartDate] = useState();
  let [endDate, setEndDate] = useState();
  let [isOpenDatePicker, setIsOpenDatePicker] = useState(false);
  let [datePickerValueChangedCount, setDatePickerValueChangedCount] = useState(0);

  useEffect(() => {
    const dateFormat = language['dateFormat'];
    let __formattedValue = '';

    if (startDate) {
      __formattedValue = moment(startDate).format(dateFormat || 'MM-DD-YYYY');
    }

    if (endDate) {
      if (__formattedValue != '') {
        __formattedValue = __formattedValue + ' ~ ';
      }
      __formattedValue = __formattedValue + moment(endDate).format(dateFormat || 'MM-DD-YYYY');
    }

    setFormattedValue(__formattedValue);
  }, [startDate, endDate, languageCode]);

  useEffect(() => {
    if (isOpenDatePicker) {
      setTimeout(() => registerDocumentClickListener(), 200);
    } else {
      removeDocumentClickListener();
    }

    // 👇️ remove the event listener when the component unmounts
    return () => {
      removeDocumentClickListener();
    };
  }, [isOpenDatePicker]);

  const registerDocumentClickListener = () => {
    document.addEventListener("click", handleOnDocumentClick);
    document.addEventListener("keydown", handleOnDocumentKeyDown);
  };

  const removeDocumentClickListener = () => {
    document.removeEventListener("click", handleOnDocumentClick);
    document.removeEventListener("keydown", handleOnDocumentKeyDown);
  };

  const handleOnDocumentClick = (e) => {
    if (isOpenDatePicker &&
      dateOPickerRef.current && !dateOPickerRef.current.contains(e.target)
    ) {
      setIsOpenDatePicker(false);
      document.body.focus();
    }
  };

  const handleOnDocumentKeyDown = (e) => {
    if (isOpenDatePicker &&
      (e.key === 'Escape' || e.key === 'Esc') && e.keyCode == 27
    ) {
      setIsOpenDatePicker(false);
      document.body.focus();
    }
  };

  const handleKeyPressInputPlace = (event) => {
    if (place === "" && formattedValue == "") return;
    if (event.key === "Enter" && event.keyCode == 13) {
      setPlaceSubmitted(true);

      setTimeout(() => {
        inputDateRef.current.focus();
      }, 100);
    }
  };

  const secondKeyPress = (event, _startDate, _endDate) => {
    if (formattedValue === "") return;
    // if (
    //   (event.key === "Enter" && event.keyCode == 13) ||
    //   (event.key === "Next" && event.keyCode == 9)
    // ) {
      let obj = {
        id: uuidv4(),
        title: place,
        startDate: _startDate,
        endDate: _endDate
      };

      setFieldValue(name, [...(value || []), obj], false);
      setPlace("");
      setStartDate('');
      setEndDate('');
      setPlaceSubmitted(false);
    // }
  };

  const handleRemoveItem = (item) => {
    let filteredArray;
    if (item._id) {
      filteredArray = (value || []).filter((el) => el._id !== item._id);
    } else {
      filteredArray = (value || []).filter((el) => el.id !== item.id);
    }

    setFieldValue(name, filteredArray);
  };

  let isError =
    isSubmitted && errors ? `1px solid ${danger}` : `1px solid ${grey}`;

  return (
    <div className="double-input">
      <Typography
        sx={{
          marginBottom: "7px",
          fontSize: "12px",
          textTransform: "uppercase",
        }}
      >
        {isRequired && <span style={{ color: `${danger}` }}>*</span>}
        {title}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
        <div className="input-wrapper-1" style={{border: isError}}>
          <div
            className={"input-wrapper-2" + (window.innerWidth <= 767 ? ' relative ' : '')}
          >
            <input
              type={type}
              id="place"
              value={place}
              onChange={(e) => setPlace(e.target.value)}
              className="input"
              style={{
                paddingLeft: "3px",
                width: !place ? (languageCode === 'ja' ? '86px' : '152px') : `${(place.length + 5) * 5}` + "px",
              }}
              onKeyUp={(event) => handleKeyPressInputPlace(event)}
              enterKeyHint="enter"
              maxLength="35"
              placeholder={language['Resume.forms.workhistory.where']}
            />
            {placeSubmitted && <div className="line"></div>}
            <div className={window.innerWidth <= 767 ? '' : 'relative'} 
              style={{display: (placeSubmitted ? 'block' : 'none')}}
            >
              <input ref={inputDateRef}
                type={type}
                name={name}
                value={formattedValue}
                id="duration"
                // onChange={(e) => setDuration(e.target.value)}
                // onKeyUp={(event) => secondKeyPress(event)}
                enterKeyHint="enter"
                maxLength="20"
                placeholder={language['Resume.forms.workhistory.howLong']}
                autoComplete="off"
                className="input date-input"
                onFocus={() => {
                  setIsOpenDatePicker(true);
                }}
              />
              {(isOpenDatePicker) &&
                <div className="range-picker" ref={dateOPickerRef}>
                  <DateRangePicker
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={window.innerWidth <= 767 ? 1 : 2}
                    direction="horizontal"
                    staticRanges={[]}
                    inputRanges={[]}
                    ranges={[{
                      startDate: startDate || new Date(),
                      endDate: endDate || new Date(),
                      key: 'selection',
                    }]}
                    onChange={(ranges) => {
                      let __datePickerValueChangedCount = datePickerValueChangedCount + 1;
                      setDatePickerValueChangedCount(__datePickerValueChangedCount);
                      setStartDate(ranges.selection.startDate);
                      setEndDate(ranges.selection.endDate);
                      if (__datePickerValueChangedCount >= 2) {
                        setDatePickerValueChangedCount(0);
                        setIsOpenDatePicker(false);
                        secondKeyPress(null, ranges.selection.startDate, ranges.selection.endDate);
                      }
                    }}
                  />
                </div>
              }
            </div>
          </div>
        </div>
        <div styles={{ display: "flex" }}>
          {value?.map((item) => {
            const dateFormat = language['dateFormat'];
            let __formattedValue = '';
            if (item.startDate) {
              __formattedValue = moment(item.startDate).format(dateFormat || 'MM-DD-YYYY');
            }
            if (item.endDate) {
              if (__formattedValue != '') {
                __formattedValue = __formattedValue + ' ~ ';
              }
              __formattedValue = __formattedValue + moment(item.endDate).format(dateFormat || 'MM-DD-YYYY');
            }
            return (
              <DoubleOutput
                key={item.id || item._id}
                item={Object.assign({}, item, {duration: __formattedValue})}
                handleRemoveItem={handleRemoveItem}
              />
            );
          })}
        </div>
      </Box>
    </div>
  );
}
