import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import "./uploadedFile.styles.css";
import ImageViewer from "components/image-viewer/ImageViewer";
import { Utils } from "utils/utils";
import VideoViewer from "components/image-viewer/VideoViewer";
import PDFModal from "components/pdf-viewer/PdfModal";

export default function UploadedFile({ file, remove, fromServer, view }) {
  const [readFile, setReadFile] = useState(null);
  const user = useSelector((state) => state.profile.accessToken);
  const language = useSelector((state) => state.footer.language);
  const [isOpenImageViewer, setIsOpenImageViewer] = useState(false);
  const [isOpenVideoViewer, setIsOpenVideoViewer] = useState(false);
  const [isOpenPDFViewer, setIsOpenPDFViewer] = useState(false);

  const preview = (selectedFile) => {
    if (fromServer) {
      const url = `${process.env.REACT_APP_MEDIA_STORAGE}/${file?.name}`;
      setReadFile(url);
      if (Utils.isImageFile(url)) {
        setIsOpenImageViewer(true);
      } else if (Utils.isVideoFile(url)) {
        setIsOpenVideoViewer(true);
      } else if (Utils.isPDFFile(url)) {
        setIsOpenPDFViewer(true);
      } else {
        window.open(url, '_blank');
      }
    } else {
      setReadFile(URL.createObjectURL(selectedFile));
      if (selectedFile.type.indexOf('image/') == 0) {
        setIsOpenImageViewer(true);
      } else if (selectedFile.type.indexOf('video/') == 0) {
        setIsOpenVideoViewer(true);
      } else {
        // TODO:
      }
    }
  };

  return (
    <>
      <Box className="fileBox">
        <p className="fileName">
          {file?.name?.length > 25
            ? file?.name?.substr(0, 25) + "..."
            : file?.name}
        </p>
        <img className="image" src="../../book.png" alt="" />
        <div className="action">
          <div className="inner">
            <button className="button" onClick={() => preview(file)}>
              {language['Resume.file.view']}
            </button>
            {user && !view && (
              <button
                className="button"
                onClick={() => remove(fromServer ? file._id : file.id)}
              >
                {language['Resume.file.delete']}
              </button>
            )}
          </div>
        </div>
      </Box>

      {isOpenImageViewer &&
        <ImageViewer 
          urls={[readFile]}
          onClose={() => setIsOpenImageViewer(false)}
        />
      }

      {isOpenVideoViewer &&
        <VideoViewer
          urls={[readFile]}
          onClose={() => setIsOpenVideoViewer(false)}
        />
      }

      {isOpenPDFViewer &&
        <PDFModal 
          file={readFile}
          onClose={() => setIsOpenPDFViewer(false)}
        />
      }
    </>
  );
}
