import React from "react";

import TextExtraction from './lib/TextExtraction';

const PATTERNS = {
    url: /(https?:\/\/|www.)[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/i,
    phone: /[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,7}/,
    email: /\S+@\S+.\S+/,
    url_markdown: /(\[[^\]]+\])(\((https?:\/\/|www.)[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)\))/i,
};

export default function ParsedText({ text, parse, useWrapperInlineElement }) {

    const getPatterns = () => {
        const __parse = parse || [];
        return __parse.map((option) => {
            const {type} = option;
            if (type) {
                if (!PATTERNS[type]) {
                    throw new Error(`${option.type} is not a supported type`);
                }
                option.pattern = PATTERNS[type];
            }

            return option;
        });
    };

    const getParsedText = () => {
        const textExtraction = new TextExtraction(text, getPatterns());

        return textExtraction.parse().map((props, index) => {
            const matchesProps = props.props || {};
            return (
                <span key={'parsedText-' + index}
                    {...matchesProps}
                >
                    {props.children}
                </span>
            );
        });
    };

    if (useWrapperInlineElement) {
        return (
            <span>
                {getParsedText()}
            </span>
        );
    } else {
        return (
            <div>
                {getPatterns()}
            </div>
        );
    }

}