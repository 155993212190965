import React from "react";

import AddIcon from "@mui/icons-material/Add";
import { styles } from "./autoOutput.styles";

export default function AutoOtput({ item, handleRemoveItem }) {
  return (
    <span style={styles.block}>
      <span style={styles.title}>{item.title}</span>
      <span style={styles.separator}></span>
      <button
        style={styles.removeButton}
        onClick={() => handleRemoveItem(item)}
      >
        <AddIcon sx={styles.removeIcon} />
      </button>
    </span>
  );
}