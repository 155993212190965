export const styles = {
    container: {
      position: "absolute",
      top: "0px",
      zIndex: "-1000",
    },
    image: {
      width: "inherit",
      height: "400px",
      objectFit: "cover",
      marginBottom: "20px",
    },
    text: {
      marginBottom: "16px",
    },
    textarea: {
      border: "1px solid #29CC8F",
      borderRadius: "4px",
      resize: "none",
      width: "calc(100% - 60px)",
      // height: "85px",
      padding: "10px",
      lineHeight: "20px",
      marginBottom: "24px",
      background: "white",
    },
    qrbox: {
      width: "150px",
      height: "150px",
      display: "block",
      margin: "80px auto",
    },
  };