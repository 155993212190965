import React from "react";
import { danger, grey } from "colors";
import { Field } from "formik";

import { Typography } from "@mui/material";

export default function Selector({ text, options, name, errors, isSubmitted }) {
  let isError =
    isSubmitted && errors ? `1px solid ${danger}` : `1px solid ${grey}`;
  return (
    <>
      <Typography sx={{ fontSize: "12px" }}>
        <span style={{ color: `${danger}` }}>*</span>
        {text}
      </Typography>
      <Field
        name={name}
        as="select"
        style={{
          border: isError,
          padding: "10px",
          marginBottom: "23px",
          width: "100%",
          borderRadius: "4px",
          cursor: "pointer",
        }}
      >
        {options.map((item, index) => {
          return (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          );
        })}
      </Field>
    </>
  );
}
