import { white, text } from "colors";

export const styles = {
    box : {
        padding: "32px",
        backgroundColor: `${white}`,
        color: `${text}`,
        maxWidth: '450px',
        borderRadius: '6px',

        "@media (max-width: 767px)": {
            padding: '20px'
        }
    },
    
    title : {
        fontSize: "18px",
    },
    
    message : {
        fontSize: "14px",
    },
    
    messageMarginTop : {
        marginTop: '20px'
    },

    buttonsWrapper: {
        display: "flex",
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: "end",
        alignItems: 'center',
        marginTop: "30px",
        gap: '16px'
    },
};