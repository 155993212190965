export const REMOVE_SELECTED_PUBLISHED_RESUME =
  "REMOVE_SELECTED_PUBLISHED_RESUME";
export const REMOVE_SELECTED_PUBLISHED_RESUME_SUCCESS =
  "REMOVE_SELECTED_PUBLISHED_RESUME_SUCCESS";
export const REMOVE_SELECTED_PUBLISHED_RESUME_ERROR =
  "REMOVE_SELECTED_PUBLISHED_RESUME_ERROR";

export const REMOVE_SELECTED_SAVED_RESUME = "REMOVE_SELECTED_SAVED_RESUME";
export const REMOVE_SELECTED_SAVED_RESUME_SUCCESS =
  "REMOVE_SELECTED_SAVED_RESUME_SUCCESS";
export const REMOVE_SELECTED_SAVED_RESUME_ERROR =
  "REMOVE_SELECTED_SAVED_RESUME_ERROR";

export const REMOVE_SELECTED_OTHER_RESUME = "REMOVE_SELECTED_OTHER_RESUME";
export const REMOVE_SELECTED_OTHER_RESUME_SUCCESS =
  "REMOVE_SELECTED_OTHER_RESUME_SUCCESS";
export const REMOVE_SELECTED_OTHER_RESUME_ERROR =
  "REMOVE_SELECTED_OTHER_RESUME_ERROR";

export const REMOVE_SELECTED_EDITED_RESUME = "REMOVE_SELECTED_EDITED_RESUME";
export const REMOVE_SELECTED_EDITED_RESUME_SUCCESS =
  "REMOVE_SELECTED_EDITED_RESUME_SUCCESS";
export const REMOVE_SELECTED_EDITED_RESUME_ERROR =
  "REMOVE_SELECTED_EDITED_RESUME_ERROR";
