import React from "react";
import { useToastActions } from "redux/toast/useToastActions";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import AddIcon from "@mui/icons-material/Add";
import { Box, Typography } from "@mui/material";
import { styles } from "./uploadButton.styles";
import UploadedFile from "../../outputs/uploadedFile/UploadedFile";

export default function UploadFile({
  files,
  serverFiles,
  setFieldValue,
  view,
}) {
  const { showToast } = useToastActions();
  const language = useSelector((state) => state.footer.language);

  const upload = (event) => {
    const data = [];
    for (let i = 0; i < event.currentTarget.files.length; i++) {
      let file = event.currentTarget.files[i];

      if (file.size > (10 * 1024 * 1024)) { // 10 MB
        return showToast(language['Resume.showToast.FileTooLarge']);
      }

      file.id = uuidv4();

      data.push(file);
    }

    const saveData = [...files, ...data];
    setFieldValue("files", saveData);
  };

  const remove = (indexFile) => {
    const filteredFiles = files.filter((el) => el.id !== indexFile);
    setFieldValue("files", filteredFiles);
  };

  const removeServerFiles = (indexFile) => {
    const filteredFiles = serverFiles.filter((el) => el._id !== indexFile);
    setFieldValue("uploadFiles", filteredFiles);
  };

  return (
    <Box sx={{ marginBottom: "23px" }}>
      <Typography sx={styles.title}>{language['Resume.forms.files']}</Typography>
      <Typography sx={styles.subtitle}>
      {language['Resume.forms.files.description']}
      </Typography>

      <Box sx={styles.files}>
        {!view && (
          <label style={styles.uploadLabelFile}>
            <input
              type="file"
              accept="image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              multiple
              onChange={(event) => {
                upload(event);
              }}
              style={styles.uploadInput}
            />
            <Box>
              <AddIcon sx={{ color: "#C4C4C4" }} />
              <Typography sx={{ color: "#323232" }}>
              {language['Resume.forms.uploadFiles']}
              </Typography>
            </Box>
            <img style={styles.fileIcon} src="../../book.png" alt="" />
          </label>
        )}

        {serverFiles?.map((file) => {
          return (
            <UploadedFile
              key={file._id}
              file={file}
              remove={removeServerFiles}
              fromServer={true}
              view={view}
            />
          );
        })}
        {files?.map((file) => {
          return (
            <UploadedFile
              key={file.id}
              file={file}
              remove={remove}
              view={view}
              language={language}
            />
          );
        })}
      </Box>
    </Box>
  );
}
