import { white, main, text } from "colors";

export const styles = {
  navigation: {
    display: "flex",
  },

  mobile: {
    display: "none",
    "@media (max-width: 949px)": {
      display: "block",
      position: "absolute",
      background: `${white}`,
      width: "calc(100% - 100px)",
      zIndex: 4,
      boxShadow: "0px 0px 8px  rgba(0, 0, 0, 0.16)",
      borderRadius: "2px",
      padding: "20px 50px",
    },
  },

  mobileMenuItemText: {
    fontSize: "14px",
    color: `${text}`,
    padding: "10px 0",
  },

  mobileMenuItemTextActive: {
    fontSize: "14px",
    color: `${main}`,
    fontWeight: "700",
    padding: "10px 0",
  },

  desktop: {
    display: "flex",
    "@media (max-width: 1330px)": {
      marginLeft: "60px",
    },
    "@media (max-width: 949px)": {
      display: "none",
    },
  },

  desktopLink: {
    borderBottom: "8px solid transparent",
    fontSize: "14px",
  },

  desktopLinkActive: {
    paddingBottom: "8px",
    borderBottom: `1px solid ${main}`,
    color: `${main}`,
    fontSize: "14px",
  }

};
