import React from "react";
import { text } from "colors";

import { Box, Typography } from "@mui/material";

const styles = {
  title: {
    marginBottom: "12px",
    textTransform: "uppercase",
    fontSize: "12px",
    lineHeight: "14px",
    color: `${text}`,
  },
  output: {
    position: "relative",
    display: "inline-flex",
    alignItems: "center",
    justifyItems: "start",
    border: "1px solid #29CC8F",
    borderRadius: "31px",
    height: "40px",
    marginRight: "10px",
    marginBottom: "30px",
    padding: "0 8px",
    fontSize: "14px",
    fontWeight: "400",
  },
};

export default function Experience({ title, data, double }) {
  return (
    <Box>
      {!double ? (
        <>
          {data?.length > 0 && (
            <>
              <Typography sx={styles.title}>{title}</Typography>
              {data?.map((item) => (
                <div key={item._id} style={styles.output}>
                  {item.title}
                </div>
              ))}
            </>
          )}
        </>
      ) : (
        <>
          {data?.length > 0 && (
            <>
              <Typography sx={styles.title}>{title}</Typography>
              {data?.map((item) => (
                <Typography key={item._id} sx={styles.output}>
                  <span
                    style={{
                      borderRight: "1px solid grey",
                      paddingRight: "7px",
                      marginRight: "7px",
                    }}
                  >
                    {item.title}
                  </span>
                  <span>{item.duration}</span>
                </Typography>
              ))}
            </>
          )}
        </>
      )}
    </Box>
  );
}
