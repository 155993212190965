import * as React from "react";
import {Component, HTMLProps} from "react";

interface Props extends HTMLProps<any> {
    color?: string;
    width?: string;
}

interface State {
    
}

export default class SvgIcon_AddList extends Component<Props, State> {
    
    static defaultProps = {
        color: '#29CC8F',
        width: '32px',
    };

    constructor(props: Props) {
        super(props);
    }

    render() {
        let fillColor = this.props.color || '';
        if (fillColor.length == 0) {
            fillColor = '#29CC8F';
        }

        return (
            <svg width={this.props.width} height={this.props.width} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="28.4443" width="32" height="3.55556" rx="1.77778" fill={fillColor}/>
                <circle cx="24" cy="8" r="8" fill={fillColor}/>
                <path fillRule="evenodd" clipRule="evenodd" d="M22.9003 12.4C22.9003 13.0075 23.3928 13.5 24.0003 13.5C24.6078 13.5 25.1003 13.0075 25.1003 12.4V9.10088H28.4C29.0075 9.10088 29.5 8.60839 29.5 8.00088C29.5 7.39337 29.0075 6.90088 28.4 6.90088H25.1003V3.6C25.1003 2.99249 24.6078 2.5 24.0003 2.5C23.3928 2.5 22.9003 2.99249 22.9003 3.6V6.90088H19.6C18.9925 6.90088 18.5 7.39337 18.5 8.00088C18.5 8.60839 18.9925 9.10088 19.6 9.10088H22.9003V12.4Z" fill="white"/>
                <rect y="7.11133" width="14.2222" height="3.55556" rx="1.77778" fill={fillColor}/>
                <rect y="17.7778" width="32" height="3.55556" rx="1.77778" fill={fillColor}/>
            </svg>
        );
    }

}