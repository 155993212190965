export const routes = (params) => ({
  // AUTH
  signin: "/login",
  signup: "/signup",
  acceptLine: "/acceptline",
  forgotPassword: "/forgot-password",

  // list resumes
  resumes: "/",
  resumesList: "/resumes",
  // create resume
  createResume: "/resumes/create",
  // edit resume
  editResumeById: `/resumes/edit/${params?.id || ":id"}`,
  // view resume detail
  resumeById: `/resumes/${params?.id || ":id"}`,

  //
  settings: "/settings",
  help: "/help",

  pageNotFound: "*",
});
