import React from "react";
import { main } from "colors";
import { useWindowSize } from "helpers/detectScreenSize";
import { useSelector } from "react-redux";

import "../dekstop/schedule.styles.css";
import "../mobile/mobileSchedule.styles.css";
import "./displaySchedule.styles.css";

export default function DisplaySchedule({ schedules }) {
  const language = useSelector((state) => state.footer.language);
  const times = [...Array(48).keys()];
  let dekstopTime = 0;
  let mobileTime = 0;

  const styleForDekstopLine = (hours, hourIndex) => {
    if (hourIndex - 1 < 0) {
      return "pickedBlock-first";
    }
    if (hourIndex + 1 > 47) {
      return "pickedBlock-last";
    }
    if (hours[hourIndex - 1] === false && hours[hourIndex + 1] === true) {
      return "pickedBlock-first";
    }
    if (hours[hourIndex - 1] === true && hours[hourIndex + 1] === true) {
      return "pickedBlock";
    }
    if (hours[hourIndex - 1] === true && hours[hourIndex + 1] === false) {
      return "pickedBlock-last";
    }
  };

  const styleForMobileLine = (hours, hourIndex) => {
    if (hourIndex - 1 < 0) {
      return "mobBlock-first";
    }
    if (hourIndex + 1 > 47) {
      return "mobBlock-last";
    }
    if (hours[hourIndex - 1] === false && hours[hourIndex + 1] === true) {
      return "mobBlock-first";
    }
    if (hours[hourIndex - 1] === true && hours[hourIndex + 1] === true) {
      return "mobBlock";
    }
    if (hours[hourIndex - 1] === true && hours[hourIndex + 1] === false) {
      return "mobBlock-last";
    }
  };

  const [width] = useWindowSize();

  return (
    <>
      {width > 1100 ? (
        <div className="workshift-container">
          <div className="workshift-block">
            <div className="times">
              {times.map((t, timeIndex) => {
                if (timeIndex % 2) {
                  if (timeIndex > 1) {
                    dekstopTime++;
                  }
                  return (
                    <p className="time" key={timeIndex}>
                      {dekstopTime.toString().padStart(2, "0")}
                    </p>
                  );
                }
              })}
            </div>
          </div>
          <div className="content">
            {schedules?.map((schedule, scheduleIndex) => {
              return (
                <div key={scheduleIndex} className="schedule">
                  <div className="scheduleDayBlock">
                    <p className="scheduleDay">{language[`Resume.forms.workShift.${(schedule.day + '').toLowerCase()}`]}</p>
                  </div>

                  <div className="hoursBlock">
                    {schedule?.hours.map((hour, hourIndex) => {
                      return (
                        <div
                          key={hourIndex}
                          className="hourItem"
                          value={hourIndex}
                        >
                          <div
                            className={
                              schedule.day &&
                              styleForDekstopLine(schedule.hours, hourIndex)
                            }
                            style={{
                              background: hour ? `${main}` : "transparent",
                            }}
                          ></div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div className="times-block">
              {times.map((t, timeIndex) => {
                if (timeIndex % 2) {
                  if (timeIndex > 1) {
                    mobileTime++;
                  }
                  return (
                    <div key={timeIndex} className="mobtime">
                      {mobileTime.toString().padStart(2, "0")}
                    </div>
                  );
                }
              })}
            </div>
            {/*  */}
            <div className="main-block">
              {schedules &&
                schedules?.map((schedule, scheduleIndex) => {
                  let scheduleDay = schedule.day.substr(0, 3);
                  return (
                    <div key={scheduleIndex} className="schedule-day">
                      <p>{language[`Resume.forms.workShift.${scheduleDay}`]}</p>

                      <div>
                        {schedule?.hours.map((hour, hourIndex) => {
                          return (
                            <div
                              key={hourIndex}
                              className="schedule-hour"
                              value={hourIndex}
                            >
                              <div
                                className={
                                  schedule.day &&
                                  styleForMobileLine(schedule.hours, hourIndex)
                                }
                                style={{
                                  background: hour ? `${main}` : "transparent",
                                  width: "70%",
                                  margin: "-1px auto",
                                  padding: "8px 0",
                                }}
                              ></div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
