import ApiAuthService from "services/ApiAuthService";
import { logout } from "../auth/auth.actions";

import {
  GET_ME,
  GET_ME_SUCCESS,
  REMOVE_TOKEN,
  SAVE_TOKEN,
  HIDE_LOADING
} from "./profile.types";

export const getMe = () => {
  return async (dispatch) => {
    try {
      dispatch(getMeInit());
      const response = await ApiAuthService.getUser();

      dispatch(getMeSuccess(response.data));
      // dispatch(getOwnResumeList());
      // dispatch(getOtherResumeList());
    } catch (error) {
      dispatch(hideLoading());
      dispatch(logout());
    }
  };
};

export const getMeInit = () => {
  return { type: GET_ME };
};

export const getMeSuccess = (payload) => {
  return { type: GET_ME_SUCCESS, payload };
};

export const hideLoading = () => {
  return { type: HIDE_LOADING };
};

export const saveToken = (payload) => {
  return { type: SAVE_TOKEN, payload };
};

export const removeToken = () => {
  return { type: REMOVE_TOKEN };
};
