import React, { useState } from "react";
import ModalComponent from "components/modal/ModalComponent";
import Sharing from "components/sharing/Sharing";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { routes } from "routing/Routes";
import { styles } from "./infoBlock.styles";

import { Box, Typography } from "@mui/material";
import { ActionType } from "components/resume/actionHeader/ActionHeader";

export default function InfoBlock({ resume, type, handlePrint, saveToMyList }) {
  const history = useHistory();
  const { userId, accessToken } = useSelector((state) => state.profile);
  const [isOpenModalSharing, setIsOpenModalSharing] = useState(false);

  return (
    <>
      <Box sx={{ ...styles.infoBlock, padding: "10px 40px" }}>
        <Box>
          <Typography sx={styles.person}>
            {resume?.surname} {resume?.name}
          </Typography>
          <Typography sx={styles.post}>{resume.position}</Typography>
        </Box>

        {(() => {
          switch (type) {
            case ActionType.view:
              return (
                <Box sx={styles.action}>
                  {(resume && accessToken && resume.userId === userId && (!resume.originalResume || resume.originalResume === '')) &&
                    <button
                      style={{ width: "28px", height: "28px" }}
                      onClick={() =>
                        history.push(routes({ id: resume._id }).editResumeById)
                      }
                    >
                      <img style={styles.icon} src="../../svg/edit.svg" alt="" />
                    </button>
                  }
                  {(resume && (!accessToken || (resume.userId !== userId && !resume.isExistsInMyList))) &&
                    <button
                    style={{ width: "28px", height: "28px" }}
                    onClick={() => {
                      if (!accessToken) {
                        history.push(routes().signin + '?resume_id=' + resume._id);
                      } else {
                        saveToMyList && saveToMyList();
                      }
                    }}
                  >
                    <img
                      style={styles.icon}
                      src="../../svg/addList.svg"
                      alt=""
                    />
                  </button>
                  }
                  <button style={styles.button} onClick={() => handlePrint && handlePrint()}>
                    <img
                      style={styles.icon}
                      src="../../svg/download.svg"
                      alt=""
                    />
                  </button>
                  {(accessToken && accessToken !== '') &&
                    <button
                      style={styles.button}
                      onClick={() => setIsOpenModalSharing(!isOpenModalSharing)}
                    >
                      <img style={styles.icon} src="../../svg/send.svg" alt="" />
                    </button>
                  }
                </Box>
              );
            default:
              return null;
          }
        })()}
      </Box>

      <ModalComponent isOpen={isOpenModalSharing}>
        <Sharing setIsOpen={setIsOpenModalSharing} />
      </ModalComponent>
    </>
  );
}
