import { white } from "colors";

export const styles = {
  editButton: {
    position: "relative",
    width: "150px",
  },
  button: {
    width: "36px",
    height: "36px",
    background: "rgba(50, 50, 50, 0.5)",
    borderRadius: "50%",
    display: "block",
    marginLeft: "auto",
  },
  actions: {
    position: "absolute",
    display: "block",
    background: `${white}`,
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
    zIndex: "4",
    width: "150px",
  },
  actionButton: {
    display: "block",
    width: "100%",
    textAlign: "left",
    padding: "14px 16px",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "16px",
  },
};
