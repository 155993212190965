import React from "react";

import AddIcon from "@mui/icons-material/Add";
import { styles } from "./doubleOutput.styles";

export default function DoubleOutput({ item, handleRemoveItem }) {
  return (
    <span style={{ ...styles.block }}>
      <span style={styles.title}>{item.title}</span>
      <span style={styles.separator1}></span>
      <span style={styles.duration}>{item.duration}</span>
      <span style={styles.separator2}></span>
      <button
        style={styles.removeButton}
        onClick={() => handleRemoveItem(item)}
      >
        <AddIcon sx={styles.removeIcon} />
      </button>
    </span>
  );
}
