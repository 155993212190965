//import { axiosInstance } from "api/axios";
import { showToast } from "redux/toast/toast.actions";
import ApiResumeService from "services/ApiResumeService";
import { getOwnResumeDetailSuccess } from "../resumeDetail/resumeDetail.actions";

import {
  UPDATE_PUBLISHED_RESUME,
  UPDATE_PUBLISHED_RESUME_ERROR,
  UPDATE_PUBLISHED_RESUME_SUCCESS,
  UPDATE_RESUME,
  UPDATE_RESUME_ERROR,
  UPDATE_RESUME_SUCCESS,
} from "./updateResume.types";
import { Utils } from "utils/utils";

export const updatePublishedResume = (
  resumeId,
  formikRef,
  resumeTitle,
  workshift,
  expression
) => {
  return async (dispatch) => {
    try {
      let formData = new FormData();

      formData.append("resumeTitle", resumeTitle);
      formData.append("surname", formikRef.current.values.surname);
      formData.append("name", formikRef.current.values.name);
      formData.append("kanaSurname", Utils.isExisty(formikRef.current.values.kanaSurname) ? formikRef.current.values.kanaSurname : '');
      formData.append("kanaName", Utils.isExisty(formikRef.current.values.kanaName) ? formikRef.current.values.kanaName : '');
      formData.append("position", formikRef.current.values.position);
      formData.append("nationality", formikRef.current.values.nationality);
      formData.append("gender", formikRef.current.values.gender);
      formData.append("birthday", formikRef.current.values.birthday);
      formData.append("phone", formikRef.current.values.phone);
      formData.append("eMail", formikRef.current.values.eMail);
      formData.append("address", formikRef.current.values.address);
      formData.append("busStation", formikRef.current.values.busStation);
      formData.append("transport", formikRef.current.values.transport);

      formData.append("motivation", formikRef.current.values.motivation);
      formData.append(
        "workHistory",
        JSON.stringify(formikRef.current.values.workHistory)
      );
      formData.append(
        "industries",
        JSON.stringify(formikRef.current.values.industries)
      );
      formData.append(
        "experiences",
        JSON.stringify(formikRef.current.values.experiences)
      );
      formData.append(
        "languages",
        JSON.stringify(formikRef.current.values.languages)
      );
      formData.append(
        "certifications",
        JSON.stringify(formikRef.current.values.certifications)
      );
      formData.append(
        "awards",
        JSON.stringify(formikRef.current.values.awards)
      );
      formData.append(
        "schools",
        JSON.stringify(formikRef.current.values.schools)
      );
      formData.append("selfPromotion", formikRef.current.values.selfPromotion);
      formData.append(
        "skills",
        JSON.stringify(formikRef.current.values.skills)
      );
      formData.append(
        "interests",
        JSON.stringify(formikRef.current.values.interests)
      );
      formData.append("whenStart", formikRef.current.values.whenStart);
      formData.append("workshift", JSON.stringify(workshift));
      formData.append("status", expression);

      if (formikRef.current.values.mainImage.length) {
        formData.append("mainImage", formikRef.current.values.mainImage[0]);
      } else {
        formData.append("mainImage", true);
      }

      for (let i = 0; i < formikRef.current.values.files.length; i++) {
        formData.append("uploadFiles", formikRef.current.values.files[i]);
      }

      formData.append(
        "uploadFilesLeft",
        JSON.stringify(formikRef.current.values.uploadFiles)
      );

      // const savedData = { ...formikRef.current.values, resumeTitle };
      dispatch(updateResumeInit());
      
      const result = await ApiResumeService.updatePublishedResume(resumeId, formData);

      let __resume = result.data;
      let __originalWorkshift = __resume.workshift;
      let arr = [];
      if (__originalWorkshift) {
        for (const key in __originalWorkshift) {
          for (let i = 0; i < __originalWorkshift[key].length; i++) {
            arr.push(__originalWorkshift[key][i]);
          }
        }
      }
      __resume.workshift = arr;

      dispatch(updateResumeSuccess({ data: __resume, resumeId }));
      dispatch(getOwnResumeDetailSuccess(__resume));
    } catch (error) {
      dispatch(updateResumeError(error));
      if (error.response && error.response.data && error.response.data.message) {
				dispatch(showToast(error.response.data.message));
			}
    }
  };
};

export const unpublishResume = (resumeId) => {
  return async (dispatch) => {
    try {
      dispatch(updateResumeInit());
      const result = await ApiResumeService.unpublishResume(resumeId);

      let __resume = result.data;
      let __originalWorkshift = __resume.workshift;
      let arr = [];
      if (__originalWorkshift) {
        for (const key in __originalWorkshift) {
          for (let i = 0; i < __originalWorkshift[key].length; i++) {
            arr.push(__originalWorkshift[key][i]);
          }
        }
      }
      __resume.workshift = arr;

      dispatch(updateResumeSuccess({ data: __resume, resumeId }));
      dispatch(getOwnResumeDetailSuccess(__resume));
      return true;
    } catch (error) {
      dispatch(updateResumeError(error));
      if (error.response && error.response.data && error.response.data.message) {
				dispatch(showToast(error.response.data.message));
			}
    }
    return false;
  };
};

export const updateResumeInit = () => {
  return { type: UPDATE_PUBLISHED_RESUME };
};

export const updateResumeSuccess = (payload) => {
  return { type: UPDATE_PUBLISHED_RESUME_SUCCESS, payload };
};

export const updateResumeError = (payload) => {
  return { type: UPDATE_PUBLISHED_RESUME_ERROR, payload };
};

//

export const saveChangesResume = (
  resumeId,
  formikRef,
  resumeTitle,
  workshift,
  expression
) => {
  return async (dispatch) => {
    try {
      let formData = new FormData();

      formData.append("resumeTitle", resumeTitle);
      formData.append("surname", formikRef.current.values.surname);
      formData.append("name", formikRef.current.values.name);
      formData.append("kanaSurname", formikRef.current.values.kanaSurname);
      formData.append("kanaName", formikRef.current.values.kanaName);
      formData.append("position", formikRef.current.values.position);
      formData.append("nationality", formikRef.current.values.nationality);
      formData.append("gender", formikRef.current.values.gender);
      formData.append("birthday", formikRef.current.values.birthday);
      formData.append("phone", formikRef.current.values.phone);
      formData.append("eMail", formikRef.current.values.eMail);
      formData.append("address", formikRef.current.values.address);
      formData.append("busStation", formikRef.current.values.busStation);
      formData.append("transport", formikRef.current.values.transport);

      formData.append("motivation", formikRef.current.values.motivation);
      formData.append(
        "workHistory",
        JSON.stringify(formikRef.current.values.workHistory)
      );
      formData.append(
        "industries",
        JSON.stringify(formikRef.current.values.industries)
      );
      formData.append(
        "experiences",
        JSON.stringify(formikRef.current.values.experiences)
      );
      formData.append(
        "languages",
        JSON.stringify(formikRef.current.values.languages)
      );
      formData.append(
        "certifications",
        JSON.stringify(formikRef.current.values.certifications)
      );
      formData.append(
        "awards",
        JSON.stringify(formikRef.current.values.awards)
      );
      formData.append(
        "schools",
        JSON.stringify(formikRef.current.values.schools)
      );
      formData.append("selfPromotion", formikRef.current.values.selfPromotion);
      formData.append(
        "skills",
        JSON.stringify(formikRef.current.values.skills)
      );
      formData.append(
        "interests",
        JSON.stringify(formikRef.current.values.interests)
      );
      formData.append("whenStart", formikRef.current.values.whenStart);
      formData.append("workshift", JSON.stringify(workshift));
      formData.append("status", expression);

      if (formikRef.current.values.mainImage === null) {
        formData.append("mainImage", false);
      } else if (formikRef.current.values.mainImage.length) {
        formData.append("mainImage", formikRef.current.values.mainImage[0]);
      } else {
        formData.append("mainImage", true);
      }

      for (let i = 0; i < formikRef.current.values.files.length; i++) {
        formData.append("uploadFiles", formikRef.current.values.files[i]);
      }

      formData.append(
        "uploadFilesLeft",
        JSON.stringify(formikRef.current.values.uploadFiles)
      );

      const savedData = { ...formikRef.current.values, resumeTitle };
      dispatch(saveChangesInit());

      //const response = await axiosInstance
      //  .put(`api/resumes/edit/${resumeId}`, formData)
      //  .catch((error) => {
      //    console.log(error);
      //  });

      const response = await ApiResumeService.updateResume(resumeId, formData);

      switch (expression) {
        case "saved":
          dispatch(saveChangesSuccess({ data: savedData, resumeId }));
          break;
        default:
          dispatch(saveChangesSuccess({ data: response.data, resumeId }));
          break;
      }
    } catch (error) {
      dispatch(saveChangesError(error));
      dispatch(showToast(error));
    }
  };
};

export const saveChangesInit = () => {
  return { type: UPDATE_RESUME };
};

export const saveChangesSuccess = (payload) => {
  return { type: UPDATE_RESUME_SUCCESS, payload };
};

export const saveChangesError = (payload) => {
  return { type: UPDATE_RESUME_ERROR, payload };
};
