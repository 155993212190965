import moment from "moment";
import { showToast } from "redux/toast/toast.actions";
import ApiResumeService from "services/ApiResumeService";

import {
  CREATE_RESUME,
  CREATE_RESUME_ERROR,
  CREATE_RESUME_SUCCESS,
} from "./createResume.types";
import { Utils } from "utils/utils";

export const createResume = (formikRef, resumeTitle, workshift, expression) => {
  return async (dispatch) => {
    try {
      let formData = new FormData();
      formData.append("resumeTitle", resumeTitle);
      formData.append("surname", formikRef.current.values.surname);
      formData.append("name", formikRef.current.values.name);
      formData.append("kanaSurname", Utils.isExisty(formikRef.current.values.kanaSurname) ? formikRef.current.values.kanaSurname : '');
      formData.append("kanaName", Utils.isExisty(formikRef.current.values.kanaName) ? formikRef.current.values.kanaName : '');
      formData.append("position", formikRef.current.values.position);
      formData.append("nationality", formikRef.current.values.nationality);
      formData.append("gender", formikRef.current.values.gender);
      formData.append(
        "birthday",
        moment(formikRef.current.values.birthday).format("YYYY/DD/MM")
      );
      formData.append("phone", formikRef.current.values.phone);
      formData.append("eMail", formikRef.current.values.eMail);
      formData.append("address", formikRef.current.values.address);
      formData.append("busStation", formikRef.current.values.busStation);
      formData.append("transport", formikRef.current.values.transport);

      formData.append("motivation", formikRef.current.values.motivation);
      formData.append(
        "workHistory",
        JSON.stringify(formikRef.current.values.workHistory)
      );
      formData.append(
        "industries",
        JSON.stringify(formikRef.current.values.industries)
      );
      formData.append(
        "experiences",
        JSON.stringify(formikRef.current.values.experiences)
      );
      formData.append(
        "languages",
        JSON.stringify(formikRef.current.values.languages)
      );
      formData.append(
        "certifications",
        JSON.stringify(formikRef.current.values.certifications)
      );
      formData.append(
        "awards",
        JSON.stringify(formikRef.current.values.awards)
      );
      formData.append(
        "schools",
        JSON.stringify(formikRef.current.values.schools)
      );
      formData.append("selfPromotion", formikRef.current.values.selfPromotion);
      formData.append(
        "skills",
        JSON.stringify(formikRef.current.values.skills)
      );
      formData.append(
        "interests",
        JSON.stringify(formikRef.current.values.interests)
      );

      formData.append(
        "whenStart",
        moment(formikRef.current.values.whenStart).format("YYYY/DD/MM")
      );
      formData.append("workshift", JSON.stringify(workshift));
      formData.append("status", expression);

      if (formikRef.current.values.mainImage) {
        formData.append("mainImage", formikRef.current.values.mainImage[0]);
      }

      for (let i = 0; i < formikRef.current.values.files.length; i++) {
        formData.append("uploadFiles", formikRef.current.values.files[i]);
      }

      dispatch(createResumeInit());

      const response = await ApiResumeService.createResume(formData).catch(
        (error) => {
          dispatch(showToast(error.response.data.message));
        }
      );

      dispatch(createResumeSuccess(response.data));
    } catch (error) {
      dispatch(createResumeError(error.response.data.message));
      dispatch(showToast(error.response.data.message));
    }
  };
};

export const createResumeInit = () => {
  return { type: CREATE_RESUME };
};

export const createResumeSuccess = (payload) => {
  return { type: CREATE_RESUME_SUCCESS, payload };
};

export const createResumeError = (payload) => {
  return { type: CREATE_RESUME_ERROR, payload };
};
