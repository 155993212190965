import { text, white } from "colors";

export const styles = {
  title: {
    marginRight: "10px",
  },
  menu: {
    position: "absolute",
    right: 0,
    zIndex: 4,
    background: `${white}`,
    width: "180px",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.16)",
    borderRadius: "2px",
    "@media (max-width: 949px)": {
      display: "none",
    },
  },

  link: {
    fontSize: "14px",
    color: `${text}`,
    padding: "10px 20px",
  },
};
