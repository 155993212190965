export const overlay = {
    position: 'fixed',
	width: '100%',
	height: '100%',
	top: '0px',
	bottom: '0px',
	left: '0px',
	right: '0px',
	backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 9999,
};

export const hiddenImage = {
    width: '1px',
    height: '1px',
    opacity: 0,
};

export const imageWrapper = {
    padding: '48px 60px',
};

export const topRightButton = {
    padding: '6px',
    backgroundColor: '#FFF',
    borderRadius: '50%',
    cursor: 'pointer',
    zIndex: 2,
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.3)',
    marginLeft: '10px',
};

export const leftBar = {
    position: 'absolute',
    top: '0px',
    bottom: '0px',
    left: '15px',
    zIndex: '2',
};

export const rightBar = {
    position: 'absolute',
    top: '0px',
    bottom: '0px',
    right: '15px',
    zIndex: '2',
};

export const navButton = {
    padding: '6px',
    backgroundColor: '#FFF',
    borderRadius: '50%',
    cursor: 'pointer',
};