import { main, grey } from "colors";

export const styles = {
  container: {
    padding: "0 16px",
    maxWidth: "1160px",
    margin: "0 auto 40px auto",
    "@media (max-width: 381px)": {
      minHeight: "calc(100vh - 230px)",
      margin: "0 auto 20px auto",
    },
  },
  tab: {
    color: `${grey}`,
    textTransform: "inherit",
    padding: "0px",
    marginRight: "20px",
    "&.Mui-selected": {
      color: `${main}`,
      textTransform: "inherit",
      fontWeight: "400",
    },
    "@media (max-width: 500px)": {
      width: "45%",
    },
  },
  box: {
    minHeight: "500px",
    padding: "20px 40px",
    border: "1px solid #ccc",
    "@media (max-width: 381px)": {
      padding: "0 10px",
      border: "none",
    },
    "@media (min-width:381px) and (max-width:540px)": {
      padding: "0 24px",
      border: "none",
      transition: "transform 3s ease-in-out",
    },
    "@media (max-width:350px)": {
      padding: "0 10px",
    },
  },
  resumeList: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    rowGap: "1rem",
    gap: "21px",
    "@media (max-width: 381px)": {
      justifyContent: "space-between",
    },
    "@media (min-width:381px) and (max-width:540px)": {
      transition: "transform 3s ease-in-out",
    },
    "@media (max-width:350px)": {
      justifyContent: "center",
    },
  },
  noneResume: {
    width: "100%",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
    color: "#9C9C9C",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  // resume in minutes
  rimResumeListBox : {
    marginRight: '8vw',
    "@media (max-width: 767px)": {
      marginRight: '0px',
      minHeight: 'auto'
    }
  },
  rimWrapper: {
    flex: '1',
    "@media (max-width: 767px)": {
      width: '100%',
      maxWidth: '767px',
      flex: '1 1 767px !important',
      marginTop: '30px'
    },
  },
  rimTitle: {
    color: `${main}`,
    fontWeight: 500,
    fontSize: "40px",
    marginBottom: "13px",
    letterSpacing: "1px",
    textTransform: "inherit",
    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    textAlign: "right",
    "@media (max-width: 900px)": {
      fontSize: "32px",
    },
    "@media (max-width: 767px)": {
      fontSize: "24px",
      textAlign: "left",
    },
  },
  rimSubtitle: {
    color: `${main}`,
    fontWeight: 400,
    fontSize: "15px",
    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    textAlign: "right",
    marginTop: '5px',
    "@media (max-width: 767px)": {
      textAlign: "left",
    },
  },
  rimImage: {
    width: "100%",
    marginTop: "30px",
    "@media (max-width: 767px)": {
      marginTop: "20px",
    },
  }
};
