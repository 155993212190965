export const styles = {
    box: {
      marginTop: "12px",
      display: "flex",
      alignItems: "center",
      marginBottom: "-20px",
    },
    label: {
      fontSize: "12px",
      fontWeight: 400,
    },
  };