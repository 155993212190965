import React from "react";
import { danger, text, white } from "colors";
import ModalComponent from "components/modal/ModalComponent";

import { Box, Button, Typography } from "@mui/material";
import { styles } from "./modalConfirmation.styles";

export default function ModalConfirmation({
  resume,
  isOpen,
  setIsOpen,
  onYesButtonClick,
  type,
  language,
  modalTitle,
  modalContent,
  modalYesnButtonTitle
}) {
  return (
    <ModalComponent isOpen={isOpen} setIsOpen={setIsOpen}>
      <Box sx={{ padding: "32px", background: `${white}`, color: `${text}` }}>
        {(modalTitle && modalTitle !== '') ?
          <Typography sx={styles.modalCaption}>
            {modalTitle}
          </Typography>
          :
          <Typography sx={styles.modalCaption}>
            {language['Resume.page.delete']}
          </Typography>
        }

        {(modalContent && modalContent !== '') ?
          <Typography sx={styles.modalInfoText}>
            {modalContent.split('\n').map((__t, __idx) => <div key={__idx}>{__t}</div>)}
          </Typography>
          :
          (type === "other" ? (
              <Typography sx={styles.modalInfoText}>
                &rdquo; {resume?.surname} {resume?.name} &rdquo; {language['Resume.page.confirmDelete']}
              </Typography>
            ) : (
              <Typography sx={styles.modalInfoText}>
                &rdquo; {resume?.resumeTitle} &rdquo; {language['Resume.page.confirmDelete']}
              </Typography>
            )
          )
        }

        <Box sx={styles.modalButtonsBlock}>
          <Button
            sx={{
              border: `1px solid ${danger}`,
              color: `${danger}`,
              marginRight: "16px",
              "&:hover": { background: "none" },
            }}
            onClick={() => setIsOpen(false)}
          >
            {language['Resume.actions.no']}
          </Button>
          <Button
            sx={{
              background: `${danger}`,
              color: "#fff",
              "&:hover": { background: `${danger}` },
            }}
            onClick={() => onYesButtonClick(resume)}
          >
            {(modalYesnButtonTitle && modalYesnButtonTitle !== '') ?
              modalYesnButtonTitle
              :
              language['Resume.actions.yes']
            }
          </Button>
        </Box>
      </Box>
    </ModalComponent>
  );
}
