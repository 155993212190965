import { showToast } from "redux/toast/toast.actions";
import ApiResumeService from "services/ApiResumeService";

import {
  GET_NATIONALITY,
  GET_OWN_RESUME,
  GET_OWN_RESUME_ERROR,
  GET_OWN_RESUME_SUCCESS,
  SAVE_TO_MY_LIST,
  SAVE_TO_MY_LIST_ERROR,
  SAVE_TO_MY_LIST_SUCCESS,
} from "./resumeDetail.types";

export const getOwnResumeDetail = (resumeId) => {
  return async (dispatch) => {
    try {
      dispatch(getOwnResumeDetailInit());
      const response = await ApiResumeService.getResumeDetail(resumeId);

      let arr = [];
      for (const key in response.data.workshift) {
        for (let i = 0; i < response.data.workshift[key].length; i++) {
          arr.push(response.data.workshift[key][i]);
        }
      }

      response.data.workshift = arr;

      dispatch(getOwnResumeDetailSuccess(response.data));
    } catch (error) {
      dispatch(getOwnResumeDetailError(error));
    }
  };
};

export const getOwnResumeDetailInit = () => {
  return { type: GET_OWN_RESUME };
};

export const getOwnResumeDetailSuccess = (payload) => {
  return { type: GET_OWN_RESUME_SUCCESS, payload };
};

export const getOwnResumeDetailError = (payload) => {
  return { type: GET_OWN_RESUME_ERROR, payload };
};

export const getNationality = () => {
  return async (dispatch) => {
    try {
      const response = await ApiResumeService.getNationality();

      dispatch({
        type: GET_NATIONALITY,
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const saveToMyList = (resumeId) => {
  return async (dispatch) => {
    try {
      dispatch(saveToMyListInit());
      const response = await ApiResumeService.saveToList(resumeId);
      dispatch(saveToMyListSuccess(response.data));
    } catch (error) {
      dispatch(saveToMyListError(error));
      dispatch(showToast(error));
    }
  };
};

export const saveToMyListInit = () => {
  return { type: SAVE_TO_MY_LIST };
};

export const saveToMyListSuccess = (payload) => {
  return { type: SAVE_TO_MY_LIST_SUCCESS, payload };
};

export const saveToMyListError = (payload) => {
  return { type: SAVE_TO_MY_LIST_ERROR, payload };
};
