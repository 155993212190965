import { routes } from "routing/Routes";

export const desktop = [
  {
    link: "Resume List",
    path: routes().resumes,
    i18n: "Resume.nav.resumeList",
  },
  {
    link: "Settings",
    path: routes().settings,
    i18n: "Resume.nav.settings",
  },
  {
    link: "Help",
    path: routes().help,
    i18n: "Resume.nav.help",
  },
];

export const desktopUnauthor = [
  {
    link: "Resume List",
    path: routes().resumes,
    i18n: "Resume.nav.resumeList",
  },
  {
    link: "Help",
    path: routes().help,
    i18n: "Resume.nav.help",
  },
];

export const mobile = [
  {
    link: "Create New Resume",
    path: routes().createResume,
    i18n: "Resume.nav.createNew",
  },
  {
    link: "Resume List",
    path: routes().resumes,
    i18n: "Resume.nav.resumeList",
  },
  {
    link: "Settings",
    path: routes().settings,
    i18n: "Resume.nav.settings",
  },
  {
    link: "Help",
    path: routes().help,
    i18n: "Resume.nav.help",
  },
  {
    link: "Logout",
    path: routes().signin,
    i18n: "Resume.nav.logout",
  },
];

export const mobileUnauthor = [
  {
    link: "Create New Resume",
    path: routes().createResume,
    i18n: "Resume.nav.createNew",
  },
  {
    link: "Login",
    path: routes().signin,
    i18n: "Authen.Login",
  },
  {
    link: "Sign Up",
    path: routes().signup,
    i18n: "Authen.Signup",
  },
  {
    link: "Help",
    path: routes().help,
    i18n: "Resume.nav.help",
  },
];
