import {
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_SUCCESS,
  LOGOUT,
  SIGN_IN,
  SIGN_IN_ERROR,
  SIGN_UP,
  SIGN_UP_ERROR,
  UPDATE_EMAIL,
  UPDATE_EMAIL_ERROR,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_ERROR,
} from "./auth.types";

const initialState = {
  loading: true,
  token: null,
  errorMessage: "",
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN:
      return { ...state, loading: true };
    case SIGN_IN_ERROR:
      return { ...state, loading: false };

    case SIGN_UP:
      return { ...state, loading: true };
    case SIGN_UP_ERROR:
      return { ...state, loading: false };

    case FORGOT_PASSWORD:
      return { ...state, loading: true };
    case FORGOT_PASSWORD_SUCCESS:
      return { ...state, loading: false };
    case FORGOT_PASSWORD_ERROR:
      return { ...state, loading: false, errorMessage: action.payload };

    case UPDATE_EMAIL:
      return { ...state, loading: true };
    case UPDATE_EMAIL_ERROR:
      return { ...state, loading: false, errorMessage: action.payload };

    case UPDATE_PASSWORD:
      return { ...state, loading: true };
    case UPDATE_PASSWORD_ERROR:
      return { ...state, loading: false, errorMessage: action.payload };

    case LOGOUT:
      return { ...state, token: null };

    default:
      return state;
  }
};
