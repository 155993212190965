import { showToast } from "redux/toast/toast.actions";
import ApiAuthService from "services/ApiAuthService";

import { getMe, removeToken, saveToken } from "../profile/profile.actions";

import {
  SIGN_UP,
  SIGN_UP_ERROR,
  SIGN_IN,
  SIGN_IN_ERROR,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_ERROR,
  SEND_QUESTION_EMAIL_SUCCESS,
  SEND_QUESTION_EMAIL_ERROR,
  SEND_QUESTION_EMAIL,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  UPDATE_EMAIL,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_ERROR,
} from "./auth.types";

export const signup = (email, password) => {
  return async (dispatch) => {
    try {
      dispatch(signUpInit());

      const response = await ApiAuthService.signup(email, password);
      localStorage.setItem("accessToken", response.data.accessToken);

      dispatch(saveToken(response.data.accessToken));
      dispatch(getMe());
    } catch (error) {
      dispatch(signUpError(error.response.data.message));
      dispatch(showToast(error.response.data.message));
    }
  };
};

export const signUpInit = () => {
  return { type: SIGN_UP };
};

export const signUpError = (payload) => {
  return { type: SIGN_UP_ERROR, payload };
};

export const signin = (email, password) => {
  return async (dispatch) => {
    try {
      dispatch(signInInit());
      const response = await ApiAuthService.signin(email, password);
      localStorage.setItem("accessToken", response.data.accessToken);

      dispatch(saveToken(response.data.accessToken));
      dispatch(getMe());
    } catch (error) {
      dispatch(signInError(error.response.data.message));
      dispatch(showToast(error.response.data.message));
    }
  };
};

export const signInInit = () => {
  return { type: SIGN_IN };
};

export const signInError = (payload) => {
  return { type: SIGN_IN_ERROR, payload };
};

export const signInLine = (data) => {
  return async (dispatch) => {
    try {
      dispatch(signInInit());
      const response = await ApiAuthService.authLine(data);
      localStorage.setItem("accessToken", response.data.accessToken);
      dispatch(saveToken(response.data.accessToken));
      dispatch(getMe());
    } catch (error) {
      dispatch(signInError(error.response.data.message));
      dispatch(showToast(error.response.data.message));
    }
  };
};

export const signInWithTwitter = (data) => {
  return async (dispatch) => {
    try {
      dispatch(signInInit());
      const response = await ApiAuthService.authTwitter(data);
      localStorage.setItem("accessToken", response.data.accessToken);

      dispatch(saveToken(response.data.accessToken));
      dispatch(getMe());
    } catch (error) {
      console.log(error);
    }
  };
};

export const forgotPassword = (data) => {
  return async (dispatch) => {
    try {
      let sendEmail;
      dispatch(forgotPasswordInit());
      const response = await ApiAuthService.forgotPassword(data);
      if (response) {
        sendEmail = await ApiAuthService.sendNewPasswordToEmail(data);
      }

      dispatch(forgotPasswordSuccess(true));

      return { response, sendEmail };
    } catch (error) {
      dispatch(forgotPasswordError(error.response.data.message));
      dispatch(showToast(error.response.data.message));
    }
  };
};

export const forgotPasswordInit = () => {
  return { type: FORGOT_PASSWORD };
};

export const forgotPasswordSuccess = (payload) => {
  return { type: FORGOT_PASSWORD_SUCCESS, payload };
};

export const forgotPasswordError = (payload) => {
  return { type: FORGOT_PASSWORD_ERROR, payload };
};

export const logout = () => {
  return async (dispatch) => {
    dispatch(removeToken());
    localStorage.removeItem("accessToken");
  };
};

export const sendQuestionEmail = (data) => {
  return async (dispatch) => {
    try {
      dispatch(sendQuestionEmailInit());
      const response = await ApiAuthService.sendQuestionEmail(data);
      dispatch(showToast("Message has been sending"));
      return response;
    } catch (error) {
      dispatch(forgotPasswordError(error.response.data.message));
      dispatch(showToast(error.response.data.message));
    }
  };
};

export const sendQuestionEmailInit = () => {
  return { type: SEND_QUESTION_EMAIL };
};

export const sendQuestionEmailSuccess = (payload) => {
  return { type: SEND_QUESTION_EMAIL_SUCCESS, payload };
};

export const sendQuestionEmailError = (payload) => {
  return { type: SEND_QUESTION_EMAIL_ERROR, payload };
};

export const updateEmail = (userId, data) => {
  return async (dispatch) => {
    try {
      dispatch(updateEmailInit());
      const response = await ApiAuthService.changeEmail(userId, data);
      dispatch(updateEmailSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(updateEmailError(error.response.data.message));
      dispatch(showToast(error.response.data.message));
    }
  };
};

export const updateEmailInit = () => {
  return { type: UPDATE_EMAIL };
};

export const updateEmailSuccess = (payload) => {
  return { type: UPDATE_EMAIL_SUCCESS, payload };
};

export const updateEmailError = (payload) => {
  return { type: UPDATE_EMAIL_ERROR, payload };
};

export const updatePassword = (userId, data) => {
  return async (dispatch) => {
    try {
      dispatch(updatePasswordInit());
      const response = await ApiAuthService.changePassword(userId, data);
      return response;
    } catch (error) {
      dispatch(updatePasswordError(error.response.data.message));
      dispatch(showToast(error.response.data.message));
    }
  };
};

export const updatePasswordInit = () => {
  return { type: UPDATE_PASSWORD };
};

export const updatePasswordSuccess = (payload) => {
  return { type: UPDATE_PASSWORD_SUCCESS, payload };
};

export const updatePasswordError = (payload) => {
  return { type: UPDATE_PASSWORD_ERROR, payload };
};
