import React, { useState, useRef, useEffect } from "react";
import ActionHeader, { ActionType } from "components/resume/actionHeader/ActionHeader";
import ExperienceIntroduction from "components/resume/ExperienceIntroduction";
import SelfIntroduction from "components/resume/SelfIntroduction";
import Schedule from "components/schedule/dekstop/Schedule";
import MobileSchedule from "components/schedule/mobile/MobileSchedule";
import MobileSavingButton from "custom/buttons/mobileSavingButton/MobileSavingButton";
import ResumeTitleInput from "custom/inputs/resumeTitleInput/ResumeTitleInput";
import { Formik, useFormikContext } from "formik";
import { useWindowSize } from "helpers/detectScreenSize";
import { getFormValidation } from "helpers/initialValues";
import { useHistory } from "react-router-dom";
import { useToastActions } from "redux/toast/useToastActions";
import { useSelector } from "react-redux";

import { Box } from "@mui/material";
import { styles } from "./createEditResume.styles";
import MainLayout from "layouts/MainLayout";
import { routes } from "routing/Routes";
import ApiResumeService from "services/ApiResumeService";
import { ResumeStatus } from "models/resume";
import Loader from "custom/loader/Loader";
import ModalConfirmation from "components/modal/ModalConfirmation";
import { Utils } from "utils/utils";

export const KEY_LOCAL_STORAGE_RESUME = 'KEY_LOCAL_STORAGE_RESUME';
const initial = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
].map((day) => ({ day, hours: Array(48).fill(false) }));

export default function CreateResume() {
  const { showToast } = useToastActions();
  const history = useHistory();
  const user = useSelector((state) => state.profile.accessToken);
  const language = useSelector((state) => state.footer.language);
  const languageCode = useSelector((state) => state.footer.languageCode);
  const formikRef = useRef();
  const [isLoadingResume, setIsLoadingResume] = useState(true);
  const [resume, setResume] = useState(null);
  const [resumeTitle, setResumeTitle] = useState("Untitled Resume");
  const [schedules, setSchedules] = useState(initial);
  const [width] = useWindowSize();
  const [isDataSaving, setIsDataSaving] = useState(false); // show loading in ActionHeader
  const [isSubmitted, setSubmitted] = useState(false); // highlight text input error
  const [isConfirmAccountVisible, setIsConfirmAccountVisible] = useState(false);
  const [validation, setValidation] = React.useState(getFormValidation(languageCode));

  useEffect(() => {
    let resumeData;
    const resumeStr = localStorage.getItem(KEY_LOCAL_STORAGE_RESUME);
    if (resumeStr && resumeStr !== '') {
      try {
        resumeData = JSON.parse(resumeStr);
        resumeData.mainImage = Utils.createResume_mainImage;
        resumeData.files = Utils.createResume_files;
      } catch (e) {
        resumeData = null;
      }
    }
    
    if (!resumeData || Object.keys(resumeData) == 0) {
      resumeData = {
        resumeTitle,
        mainImage: null,
        surname: "",
        name: "",
        kanaSurname: "",
        kanaName: "",
        position: "",
        nationality: "JP",
        gender: "male",
        birthday: "",
        phone: "",
        eMail: "",
        address: "",
        busStation: "",
        transport: "",
        // ///////////
        motivation: "",
        workHistory: [],
        industries: [],
        experiences: [],
        languages: [],
        certifications: [],
        awards: [],
        schools: [],
        selfPromotion: "",
        skills: [],
        interests: [],
        files: [],
        whenStart: "",
        workshift: schedules
      };
    }
    
    setResume(resumeData);
    setSchedules(resumeData.workshift);
    setResumeTitle(resumeData.resumeTitle);
    setIsLoadingResume(false);
  }, []);

  useEffect(() => {
    saveResumeToLocalStorage();
 }, [resumeTitle, schedules]);

  const saveResumeToLocalStorage = async () => {
    if (!user) {
      const resumeData = formikRef && formikRef.current ? formikRef.current.values : resume;
      const savedData = Object.assign({}, resumeData, {resumeTitle, workshift: schedules, mainImage: null, files: []});
      localStorage.setItem(KEY_LOCAL_STORAGE_RESUME, JSON.stringify(savedData));
      Utils.createResume_mainImage = resumeData ? resumeData.mainImage : null;
      Utils.createResume_files = resumeData ? resumeData.files : [];
    }
  }

  const validateForm = (submitAction) => {
    if (formikRef?.current) {
      const jsonData = Object.assign({}, formikRef.current.values, {
        resumeTitle,
        workshift: schedules
      });
      console.log(jsonData);
      if (submitAction === 'create') {
        if (!user) {
          setIsConfirmAccountVisible(true);
        } else {
          createResume(jsonData);
        }
        return;
      }

      formikRef.current
        .validateForm()
        .then((errors) => {
          if (Object.keys(errors).length !== 0) {
            setSubmitted(true);
            if (languageCode === 'ja' &&
              (!formikRef.current.values.kanaName ||
              !formikRef.current.values.kanaSurname)
            ) {
              showToast(errors.kanaName);
            }
            if (!formikRef.current.values.mainImage) {
              showToast(errors.mainImage);
            }

            if (JSON.stringify(schedules) == JSON.stringify(initial)) {
              showToast(language['Resume.showToast.fillWorkshift']);
            }
            showToast(language['Resume.showToast.fillHighlightedField']);
          } else {
            setSubmitted(false);
            if (!user) {
              setIsConfirmAccountVisible(true);
            } else if (submitAction === 'publish') {
              publishResume(jsonData);
            }
          }
        })
        .catch((error) => {
          showToast(error);
        });
    }
  };

  const createResume = async(jsonData) => {
    setIsDataSaving(true);
    const result = await ApiResumeService.createResume(jsonData);
    if (result && result.success) {
      localStorage.setItem(KEY_LOCAL_STORAGE_RESUME, '');
      Utils.createResume_mainImage = null;
      Utils.createResume_files = [];
      history.push(routes().resumes);
    } else {
      setIsDataSaving(false);
      if (result && result.message) {
        showToast(result.message);
      }
    }
  };

  const publishResume = async(jsonData) => {
    jsonData.status = ResumeStatus.published;
    setIsDataSaving(true);
    const result = await ApiResumeService.publishResume(null, jsonData);
    if (result && result.success) {
      showToast(language['Resume.publish.successfull']);
      localStorage.setItem(KEY_LOCAL_STORAGE_RESUME, '');
      Utils.createResume_mainImage = null;
      Utils.createResume_files = [];
      history.push(routes().resumes);
    } else {
      setIsDataSaving(false);
      if (result && result.message) {
        showToast(result.message);
      }
    }
  };

  const goToLogin = () => {
    let redirectURL = routes().signin;
    const currentPath = window.location.pathname.split('?')[0];
    if (currentPath.indexOf('/resumes/') == 0) {
      const resumeId = currentPath.substring('/resumes/'.length);
      redirectURL = redirectURL + '?resume_id=' + resumeId;
    }
    history.push(redirectURL);
  };

  const goToSignup = () => {
    let redirectURL = routes().signup;
    const currentPath = window.location.pathname.split('?')[0];
    if (currentPath.indexOf('/resumes/') == 0) {
      const resumeId = currentPath.substring('/resumes/'.length);
      redirectURL = redirectURL + '?resume_id=' + resumeId;
    }
    history.push(redirectURL);
  };

  return (
    <MainLayout>
      <>
      {isLoadingResume ? (
          <Box sx={{ minHeight: "1000px" }} className="relative">
            <Loader />
          </Box>
        ) : resume && (
          <Box sx={styles.section}>
            <Box sx={styles.block} className="flexrow ver-center space-between">
              <ResumeTitleInput
                resumeTitle={resumeTitle}
                setResumeTitle={setResumeTitle}
              />

              <ActionHeader
                type={ActionType.create}
                isShowLoading={isDataSaving}
                publishResume={() => validateForm('publish')}
                saveChanges={() => validateForm('create')}
              />
            </Box>

            <Formik
              innerRef={formikRef}
              initialValues={resume}
              validationSchema={validation}
            >
              {({ values, handleChange, errors, setFieldValue }) => (
                <Box sx={styles.container}>
                  <Box sx={{ ...styles.selfIntroduction }}>
                    <SelfIntroduction
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      typeAction={"create"}
                      isSubmitted={isSubmitted}
                    />
                  </Box>
                  <Box sx={{ ...styles.fields }}>
                    <ExperienceIntroduction
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      isSubmitted={isSubmitted}
                      language={language}
                    />

                    {width > 1100 ? (
                      <Schedule schedules={schedules} 
                        setSchedules={setSchedules}
                      />
                    ) : (
                      <MobileSchedule
                        schedules={schedules}
                        setSchedules={setSchedules}
                      />
                    )}

                    <MobileSavingButton
                      publishResume={() => validateForm('publish')}
                      saveChanges={() => validateForm('create')}
                    />

                    <FormikValueChanges onChange={() => saveResumeToLocalStorage()} />
                  </Box>
                </Box>
              )}
            </Formik>

            <ModalConfirmation
              isOpen={isConfirmAccountVisible}
              message={'Login or Sign up to Save Resume'}
              noButtonOnClick={() => setIsConfirmAccountVisible(false)}
              yesButtonTitle={language['Authen.Login']}
              yesButtonOnClick={() => {
                setIsConfirmAccountVisible(false);
                goToLogin();
              }}
              extraButtonTitle={language['Authen.Signup']}
              extraButtonOnClick={() => {
                setIsConfirmAccountVisible(false);
                goToSignup();
              }}
            />
          </Box>
        )}
      </>
    </MainLayout>
  );
}

const FormikValueChanges = ({ onChange }) => {
  const { values } = useFormikContext();
  React.useEffect(() => {
    onChange && onChange();
  }, [values]);
  return null;
}