import React from "react";
import SignupForm from "components/auth/authForm/SignupForm";
import AuthSidebar from "components/auth/authSidebar/AuthSidebar";
import Footer from "components/footer/Footer";
import AuthLayout from "layouts/AuthLayout";

import { Box, Grid } from "@mui/material";
import { styles } from "./index.styles";

const Signup = () => {
  return (
    <AuthLayout>
      <Box sx={styles.section}>
        <Grid container sx={styles.container}>
          <CornerImage />
          <AuthSidebar />
          <SignupForm />
        </Grid>
        <div className="hidden-md">
          <Footer />
        </div>
      </Box>
    </AuthLayout>
  );
};

export default Signup;

const CornerImage = () => {
  return <img src="../../auth/animal.png" alt="" style={styles.image} />;
};
