import { grey, main, text } from "colors";

export const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0 20px",
    width: "410px",
    margin: "0 auto",
    "@media (max-width: 600px)": {
      width: "auto",
    },
  },
  text: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: `${text}`,
  },
  imgBox: {
    width: "283px",
    height: "244px",
    margin: "0 auto",
    marginBottom: "20px",
  },
  textBox: {
    display: "block",
    textAlign: "center",
    color: `${text}`,
    marginBottom: "20px",
  },
  title: {
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "19px",
    marginBottom: "10px",
  },
  subtitle: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.01em",
  },
  button: {
    width: "100%",
    marginTop: "-13px",
    borderRadius: "4px",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1px",
    padding: "11px 0",
    textTransform: "inherit",
    color: "#fff",
    background: "#29CC8F",
    "&:hover": {
      background: "#29CC8F",
    },
  },
  link: {
    display: "block",
    marginTop: "25px",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.01em",
    color: "#29CC8F",
    textAlign: "center",
  },
  logo: {
    display: "flex",
  },
  caption: {
    color: `${main}`,
  },
  input: {
    paddingLeft: "10px",
    width: "calc(100% - 16px)",
    borderRadius: "3px",
    padding: "10px 8px",
    outlineColor: `${main}`,
    marginBottom: "20px",
    border: `1px solid ${grey}`,
  },
};
