import {
  REMOVE_SELECTED_EDITED_RESUME,
  REMOVE_SELECTED_EDITED_RESUME_ERROR,
  REMOVE_SELECTED_OTHER_RESUME,
  REMOVE_SELECTED_OTHER_RESUME_ERROR,
  REMOVE_SELECTED_PUBLISHED_RESUME,
  REMOVE_SELECTED_PUBLISHED_RESUME_ERROR,
  REMOVE_SELECTED_SAVED_RESUME,
  REMOVE_SELECTED_SAVED_RESUME_ERROR,
} from "./removeResume.types";

const initialState = {
  loading: false,
};

export const removeResumeReducer = (state = initialState, { type }) => {
  switch (type) {
    case REMOVE_SELECTED_PUBLISHED_RESUME:
      return { ...state, loading: true };
    case REMOVE_SELECTED_PUBLISHED_RESUME_ERROR:
      return { ...state, loading: false };

    case REMOVE_SELECTED_SAVED_RESUME:
      return { ...state, loading: true };
    case REMOVE_SELECTED_SAVED_RESUME_ERROR:
      return { ...state, loading: false };

    case REMOVE_SELECTED_OTHER_RESUME:
      return { ...state, loading: true };
    case REMOVE_SELECTED_OTHER_RESUME_ERROR:
      return { ...state, loading: false };

    case REMOVE_SELECTED_EDITED_RESUME:
      return { ...state, loading: true };
    case REMOVE_SELECTED_EDITED_RESUME_ERROR:
      return { ...state, loading: false };

    default:
      return state;
  }
};
