import React from "react";

import { styles } from "./actionButton.styles";
import "./actionButton.styles.css";

export default function ActionButton({ text, icon, func }) {
  return (
    <button type="button" onClick={() => func()}
      style={styles.button}
      className="resume-action-button"
    >
      <span style={styles.inner}>
        {(icon && (typeof icon) === 'string') && <img src={icon} alt="icon" style={styles.icon} />}
        {(icon && (typeof icon) !== 'string') && <span style={styles.icon}>{icon}</span>}
        {text}
      </span>
    </button>
  );
}
