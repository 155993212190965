import React from "react";
import { danger, grey, white, main } from "colors";
import ModalComponent from "./ModalComponent";
import { Box, Button, Typography } from "@mui/material";
import { styles } from "./styles";
import { useSelector } from "react-redux";

export const ButtonColors = {
    main: 'main',
    danger: 'danger',
    grey: 'grey'
};

export default function ModalConfirmation({
  title,
  message,
  noButtonTitle,
  noButtonColor, // main | danger | grey
  noButtonOnClick,
  yesButtonTitle,
  yesButtonColor, // main | danger | grey
  yesButtonOnClick,
  extraButtonTitle,
  extraButtonColor, // main | danger | grey
  extraButtonOnClick,
  isOpen,
}) {
  
    const language = useSelector((state) => state.footer.language);
    
    const renderButton = (title, colorStyle, onClick) => {
        let backgroundColor = white;
        let borderColor = danger;
        let textColor = danger;
        if (colorStyle === ButtonColors.main) {
            backgroundColor = main;
            borderColor = main;
            textColor = white;
        } else if (colorStyle === ButtonColors.grey) {
            // outline
            backgroundColor = white;
            borderColor = grey;
            textColor = grey;
        }
        return (
            <Button
                sx={{
                    backgroundColor: `${backgroundColor}`,
                    border: `1px solid ${borderColor}`,
                    color: `${textColor}`,
                    "&:hover": { 
                        backgroundColor: `${backgroundColor}`,
                        opacity: 0.8
                    },
                }}
                onClick={() => onClick ? onClick() : null}
            >
                {(title && title !== '') ? title : language['Resume.actions.no']}
            </Button>
        );
    }; 

    return (
        <ModalComponent isOpen={isOpen}>
            <Box sx={styles.box}>
                {/* title */}
                {(title && title !== '') &&
                    <Typography sx={styles.title}>
                        {title}
                    </Typography>
                }

                {/* message */}
                {(message && message !== '') &&
                    <Typography sx={Object.assign({}, styles.message, (title && title !== '') ? styles.messageMarginTop : {})}>
                        {message.split('\n').map((__t, __idx) => <div key={__idx}>{__t}</div>)}
                    </Typography>
                }

                {/* button */}
                <Box sx={styles.buttonsWrapper}>
                    {noButtonOnClick && renderButton(
                        (noButtonTitle && noButtonTitle !== '') ? noButtonTitle : language['Resume.actions.no'],
                        (noButtonColor && noButtonColor !== '') ? noButtonColor : ButtonColors.grey,
                        () => noButtonOnClick()
                    )}
                    {yesButtonOnClick && renderButton(
                        (yesButtonTitle && yesButtonTitle !== '') ? yesButtonTitle : language['Resume.actions.yes'],
                        (yesButtonColor && yesButtonColor !== '') ? yesButtonColor : ButtonColors.main,
                        () => yesButtonOnClick()
                    )}
                    {extraButtonOnClick && renderButton(
                        (extraButtonTitle && extraButtonTitle !== '') ? extraButtonTitle : language['Resume.actions.yes'],
                        (extraButtonColor && extraButtonColor !== '') ? extraButtonColor : ButtonColors.main,
                        () => extraButtonOnClick()
                    )}
                </Box>
            </Box>
        </ModalComponent>
    );
}
