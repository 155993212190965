import { dark_warning } from "colors";

export const styles = {
  goTop: {
    display: "block",
    width: "48px",
    height: "48px",
    borderRadius: "50%",
    background: `${dark_warning}`,
  },
};
