import React from "react";
import { main } from "colors";
import DisplaySchedule from "components/schedule/displaySchedule/DisplaySchedule";
import UploadFile from "custom/buttons/uploadButton/UploadFile";
import { getAge, getDay } from "helpers/dateCalculation";
import moment from "moment";
import { useSelector } from "react-redux";
import { styles } from "./pdfResume.styles";

import Experience from "./components/Experience";
import { Box, Typography } from "@mui/material";

const SelfContent = ({ resume, code }) => {
  const language = useSelector((state) => state.footer.language);
  const languageCode = useSelector((state) => state.footer.languageCode);

  return (
    <div style={{ width: "300px" }}>
      <img
        style={styles.image}
        src={`${process.env.REACT_APP_MEDIA_STORAGE}/${resume.mainImage?.name}`}
        alt=""
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "28px",
          alignItems: "end",
        }}
      >
        <Typography
          sx={{
            color: `${main}`,
            fontSize: "28px",
            fontWeight: "400",
            width: "200px",
          }}
        >
          {resume.surname} {resume.name}
        </Typography>
        <Typography sx={{ paddingLeft: "10px" }}>{resume.position}</Typography>
      </Box>
      
      {languageCode === 'ja' ?
        <Typography sx={styles.text}>
          {resume.kanaSurname} {resume.kanaName}
        </Typography>
        : null
      }

      <Typography sx={styles.text}>{resume.nationality}</Typography>
      <Typography sx={styles.text}>{resume.gender}</Typography>

      <Typography sx={styles.text}>
        {moment(resume.birthday).format("YYYY/DD/MM")} (
        {getAge(resume.birthday)})
      </Typography>

      <Typography sx={styles.text}>{resume.phone}</Typography>
      <Typography sx={styles.text}>{resume.eMail}</Typography>
      <Typography sx={styles.text}>{resume.address}</Typography>
      <Typography sx={styles.text}>
        {language['Resume.forms.nearestStation.view'].replace('{{busStation}}', resume.busStation)}
      </Typography>
      <Typography sx={styles.text}>
        {language['Resume.forms.transports.view'].replace('{{transportation}}', resume.transport)}
      </Typography>
      <Box sx={styles.qrbox}>
        <img src={code} alt="" style={styles.qrCode} />
      </Box>
    </div>
  );
};

const ExperienceContent = ({ resume, schedules }) => {
  const language = useSelector((state) => state.footer.language);
  return (
    <div>
      <div
        style={{
          width: "740px",
          paddingLeft: "28px",
          position: "relative",
        }}
      >
        <Box sx={styles.fields}>
          {resume.motivation ? (
            <>
              <Typography sx={styles.title}>{language['Resume.offer.motivationFor']}</Typography>
              <Typography sx={styles.textarea}>
                {resume.motivation.split('\n').map((__t, __idx) => <span key={__idx}>{__idx > 0 ? <br/> : ''}{__t}</span>)}
              </Typography>
            </>
          ) : null}

          <Experience
            data={resume.workHistory}
            title={language['Resume.forms.workhistory']}
            double={true}
          />

          <Experience data={resume.industries} title={language['Resume.offer.industory']} />

          <Experience data={resume.experiences} title={language['Resume.forms.skills']} />

          <Experience data={resume.languages} title={language['Resume.forms.languages']} />

          <Experience data={resume.certifications} title={language['Resume.forms.certifications']} />

          <Experience data={resume.awards} title={language['Resume.forms.awards']} />

          <Experience data={resume.schools} title={language['Resume.forms.schools']} />

          {resume.selfPromotion ? (
            <>
              <Typography sx={styles.title}>
                {language['Resume.forms.selfPromotions']}
              </Typography>
              <Typography sx={styles.textarea}>
                {resume.selfPromotion.split('\n').map((__t, __idx) => <span key={__idx}>{__idx > 0 ? <br/> : ''}{__t}</span>)}
              </Typography>
            </>
          ) : null}

          <Experience data={resume.skills} title={language['Resume.forms.interestingSkills']} />
          <Experience
            data={resume.interests}
            title={language['Resume.forms.interestingIndustory']}
          />
          {resume.uploadFiles && resume.uploadFiles?.length > 0 && (
            <>
              <UploadFile serverFiles={resume.uploadFiles} view={true} />
            </>
          )}
          <div className="page-break"> </div>
          <Typography sx={styles.title}>{language['Resume.forms.whenStart']}</Typography>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ fontWeight: 700 }}>
              {moment(resume.whenStart).format("YYYY/DD/MM")}
            </Typography>
            <Typography sx={{ marginLeft: "10px" }}>
              ({getDay(resume.whenStart)})
            </Typography>
          </Box>

          <Typography sx={{ ...styles.title, marginTop: "20px" }}>
          {language['Resume.forms.workShift']}
          </Typography>

          <DisplaySchedule schedules={schedules} />
        </Box>
      </div>
    </div>
  );
};

const PdfResume = React.forwardRef(({ resume, schedules, code }, ref) => {
  return (
    <>
      <table ref={ref} className="bodyClass" style={styles.container}>
        <thead>
          <tr>
            <td>
              <div className="header-space"></div>
            </td>
          </tr>
        </thead>
        <tbody style={{ width: "800px" }}>
          <tr
            style={{
              display: "flex",
              alignItems: "start",
              padding: "15px ",
            }}
          >
            <td>
              <SelfContent resume={resume} code={code} />
            </td>
            <td>
              <ExperienceContent resume={resume} schedules={schedules} />
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              <div className="footer-space"></div>
            </td>
          </tr>
        </tfoot>
      </table>
      <div className="header"></div>
      <div className="footer"></div>
    </>
  );
});

export default PdfResume;
