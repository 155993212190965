import axios from "axios";

// const baseURL = `http://localhost:4040/`;
const baseURL = `${process.env.REACT_APP_BASE_URL}`;

export const accessToken = localStorage.getItem("accessToken");

export const axiosInstance = axios.create({
  baseURL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    Authorization: `Bearer ${accessToken}`,
  },
});

axiosInstance.interceptors.request.use((request) => {
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken != null && request.headers) {
    request.headers.Authorization = `Bearer ${accessToken}`;
    return request;
  }
  return request;
});

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    return Promise.reject(error);
  }
);
