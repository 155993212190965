import React from "react";

import { Box, Typography } from "@mui/material";
import { styles } from "./textarea.styles";

export default function Textarea({
  name,
  value,
  placeholder,
  title,
  handleChange,
}) {
  return (
    <Box sx={{ marginBottom: "14px" }}>
      <Typography sx={styles.title}>{title}</Typography>
      <textarea
        style={styles.textarea}
        name={name}
        maxLength="150"
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </Box>
  );
}
