export const SIGN_IN = "SIGN_IN";
export const SIGN_IN_ERROR = "SIGN_IN_ERROR";

export const SIGN_UP = "SIGN_UP";
export const SIGN_UP_ERROR = "SIGN_UP_ERROR";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

export const LOGOUT = "LOGOUT";

export const SEND_QUESTION_EMAIL = "SEND_QUESTION_EMAIL";
export const SEND_QUESTION_EMAIL_SUCCESS = "SEND_QUESTION_EMAIL_SUCCESS";
export const SEND_QUESTION_EMAIL_ERROR = "SEND_QUESTION_EMAIL_ERROR";

export const UPDATE_EMAIL = "UPDATE_EMAIL";
export const UPDATE_EMAIL_SUCCESS = "UPDATE_EMAIL_SUCCESS";
export const UPDATE_EMAIL_ERROR = "UPDATE_EMAIL_ERROR";

export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR";
