import * as Yup from "yup";

const emailRegExp =
  "^[A-Za-z0-9][A-Za-z0-9.-_]*[A-Za-z0-9]*@([A-Za-z0-9]+([A-Za-z0-9-]*[A-Za-z0-9]+)*.)+[A-Za-z]*$";

const telRegExp = /^([+]?[0-9\s-\\]{8,25})*$/i;
let regex =
  /[\u3000-\u303F]|[\u3040-\u309F]|[\u30A0-\u30FF]|[\uFF00-\uFFEF]|[\u4E00-\u9FAF]|[\u2605-\u2606]|[\u2190-\u2195]|\u203B/g;

export function getFormValidation(languageCode) {
  let validation = {
    mainImage: Yup.mixed().required("Please upload your image"),
    surname: Yup.string()
      .min(2, "Too Short!")
      .max(30, "Too Long!")
      .required("Please fill the highlighted fields"),
    name: Yup.string()
      .min(2, "Too Short!")
      .max(30, "Too Long!")
      .required("Please fill the highlighted fields")
  };
    
  if (languageCode === 'ja') {
    validation = Object.assign(validation, {
      kanaSurname: Yup.string()
      .matches(regex, "Please use Kana typeface")
      .required("Please use Kana typeface"),
    kanaName: Yup.string()
      .matches(regex, "Please use Kana typeface")
      .required("Please use Kana typeface")
    });
  }
      

  validation = Object.assign(validation, {
    nationality: Yup.string().required("Please fill the highlighted fields"),
    gender: Yup.string().required("Please fill the highlighted fields"),
    birthday: Yup.string().required("Please fill the highlighted fields"),
    phone: Yup.string()
      .matches(telRegExp, "Phone number is not valid")
      .required("Please fill the highlighted fields"),
    eMail: Yup.string("Enter your email")
      .matches(emailRegExp, "email must be a valid email")
      .required("Please fill the correct email"),
    address: Yup.string()
      .min(2, "Too Short!")
      .max(40, "Too Long!")
      .required("Please fill the highlighted fields"),
    busStation: Yup.string()
      .min(2, "Too Short!")
      .max(30, "Too Long!")
      .required("Please fill the highlighted fields"),
    transport: Yup.string()
      .min(2, "Too Short!")
      .max(30, "Too Long!")
      .required("Please fill the highlighted fields"),
    workHistory: Yup.array()
      .of(
        Yup.object().shape({
          title: Yup.string(),
        })
      )
      .min(1, "Please fill the highlighted fields")
      .max(10, "Max 10"),
    industries: Yup.array()
      .of(
        Yup.object().shape({
          title: Yup.string(),
        })
      )
      .max(10, "Max 10"),
    experiences: Yup.array()
      .of(
        Yup.object().shape({
          title: Yup.string(),
        })
      )
      .min(1, "Please fill the highlighted fields")
      .max(10, "Max 10"),
    languages: Yup.array()
      .of(
        Yup.object().shape({
          title: Yup.string(),
        })
      )
      .max(10, "Max 10"),
    certifications: Yup.array()
      .of(
        Yup.object().shape({
          title: Yup.string(),
        })
      )
      .max(10, "Max 10"),
    awards: Yup.array()
      .of(
        Yup.object().shape({
          title: Yup.string(),
        })
      )
      .max(10, "Max 10"),
    schools: Yup.array()
      .of(
        Yup.object().shape({
          title: Yup.string(),
        })
      )
      .max(10, "Max 10"),
    skills: Yup.array()
      .of(
        Yup.object().shape({
          title: Yup.string(),
        })
      )
      .max(10, "Max 10"),
    interests: Yup.array()
      .of(
        Yup.object().shape({
          title: Yup.string(),
        })
      )
      .max(10, "Max 10"),
  });

  return Yup.object(validation);
}
