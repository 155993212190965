import React from "react";
import { useState } from "react";
import { main } from "colors";
import { mobile } from "components/navigation/navigationData";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useAuthActions } from "redux/auth/useAuthActions";

import { Box, Typography } from "@mui/material";
import { styles } from "./avatar.styles";
import "./avatar.styles.css";

export default function Avatar() {
  const history = useHistory();
  const { logout } = useAuthActions();
  const { email, accessToken } = useSelector((state) => state.profile);
  const language = useSelector((state) => state.footer.language);
  const getNameFromEmail = email.split("@")[0];
  const [isShow, setIsShow] = useState(false);
  const handleLogout = () => {
    logout();
    history.push("/");
  };

  return (
    <Box
      sx={
        accessToken
          ? { display: "block", position: "relative" }
          : { display: "none" }
      }
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={styles.title}>
          {language['Resume.welcome']} {" "}
          {getNameFromEmail && getNameFromEmail.length > 18
            ? getNameFromEmail.substr(0, 18) + "..."
            : getNameFromEmail}
        </Typography>
        <button onClick={() => setIsShow(!isShow)}>
          <img src="../../svg/avatar.svg" alt="icon" />
        </button>
      </Box>
      {isShow && (
        <Box sx={styles.menu} className="app-nav-user-menu-popup">
          {mobile.map((link) => {
            return (
              <Link key={link.path} to={link.path} className="link">
                <Typography
                  sx={
                    link.link === "Create New Resume"
                      ? { ...styles.link, color: `${main}`, fontWeight: "700" }
                      : { ...styles.link }
                  }
                  onClick={() => {
                    if (link.link !== "Logout") {
                      history.push(link.path);
                      setIsShow(!isShow);
                    } else {
                      handleLogout();
                      setIsShow(!isShow);
                    }
                  }}
                >
                  {language[link.i18n]}
                </Typography>
              </Link>
            );
          })}
        </Box>
      )}
    </Box>
  );
}
