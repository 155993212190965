import React, { useState } from "react";
import { danger, grey } from "colors";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { Box } from "@mui/material";
import { styles } from "./authInputs.styles";

export default function AuthPasswordInput({
  value,
  name,
  placeholder,
  onChange,
  errors,
  minLength,
}) {
  const [changeType, setChangeType] = useState(false);
  return (
    <Box sx={styles.box}>
      <VpnKeyIcon sx={styles.iconAtTheStart} />
      <input
        type={changeType ? "text" : "password"}
        value={value}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        minLength={minLength || null}
        style={
          errors[name]
            ? { ...styles.input, border: `1px solid ${danger}` }
            : { ...styles.input, border: `1px solid ${grey}` }
        }
      />
      <button
        onClick={() => setChangeType(!changeType)}
        style={styles.iconAtTheEnd}
      >
        {changeType ? <VisibilityIcon /> : <VisibilityOffIcon />}
      </button>
    </Box>
  );
}
