import React from "react";
import { main, text, white } from "colors";
import ModalComponent from "components/modal/ModalComponent";
import { useHistory } from "react-router-dom";
import { routes } from "routing/Routes";

import { Box, Typography } from "@mui/material";

const styles = {
  content: {
    background: "#ffffff",
    color: `${text}`,
    padding: "32px",
    margin: "16px",
    "@media(min-width: 600px)": {
      width: "370px",
    },
  },
  popupTitle: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  },
  returnButton: {
    display: "block",
    margin: "40px auto 0 auto",
    background: `${main}`,
    color: `${white}`,
    padding: "10px 16px",
    fontSize: "14px",
    fontWeight: "400",
  },
};
export default function ModalInformation({ isOpen }) {
  const history = useHistory();
  return (
    <ModalComponent isOpen={isOpen}>
      <Box sx={styles.content}>
        <Box sx={styles.popupTitle}>
          <img src="../../svg/done.svg" alt="" />
          <Typography sx={{ marginLeft: "8px" }}>
            Your message has been sent to our team
          </Typography>
        </Box>
        <Box>
          <Typography>
            Thank you for using バイト履歴 by WelcomeHR. We will be contacting
            you as soon as possible.
          </Typography>
          <button
            onClick={() => history.push(routes().resumes)}
            style={styles.returnButton}
          >
            Return to home
          </button>
        </Box>
      </Box>
    </ModalComponent>
  );
}
