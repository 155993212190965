import React from "react";
import { danger, grey } from "colors";

import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Box } from "@mui/material";
import { styles } from "./authInputs.styles";

const AuthInput = ({
  value,
  name,
  placeholder,
  onChange,
  errors,
  minLength,
}) => {
  return (
    <Box sx={styles.box}>
      <MailOutlineIcon sx={styles.iconAtTheStart} />
      <input
        type="email"
        value={value}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        minLength={minLength || null}
        style={
          errors[name]
            ? { ...styles.input, border: `1px solid ${danger}` }
            : { ...styles.input, border: `1px solid ${grey}` }
        }
      />
    </Box>
  );
};

export default AuthInput;
