export const topRightButton = {
    padding: '6px',
    backgroundColor: '#FFF',
    borderRadius: '50%',
    cursor: 'pointer',
    zIndex: 2,
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.3)',
    marginLeft: '10px',
};

export const preparingLabel = {
    fontSize: '14px',
    opacity: '0.7',
};

export const errorText = {
    paddingTop: '30px',
};