import React, { useState, useEffect } from "react";
import { danger, grey } from "colors";
import { Box, Typography } from "@mui/material";
import { styles } from "./textInput.styles";
import "./styles.css";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { jaJP} from '@mui/x-date-pickers/locales';
import ja from 'dayjs/locale/ja';
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import * as moment from 'moment';

export const TextInputType = {
  TEXT: 0,
  DATE: 2,
};

export const ServerDateFormat = 'YYYY-MM-DD';

export default function TextInput({
  name,
  type,
  inputType,
  value,
  placeholder,
  isRequired,
  onChange,
  helperText,
  text,
  half,
  errors,
  isSubmitted,
  dateFormat
}) {

  const languageCode = useSelector((state) => state.footer.languageCode);
  let [formattedValue, setFormattedValue] = useState('');
  let [datePickerValue, setDatePickerValue] = useState(null);
  let [isOpenDatePicker, setIsOpenDatePicker] = useState(false);

  useEffect(() => {
    let __formattedValue = value;
    let __datePickerValue = null;
    if (type && type === TextInputType.DATE && value && (value + '').length > 0) {
      const regex = /\d{4}[-/]\d{1,2}[-/]\d{1,2}/g; // ServerDateFormat : YYYY-MM-DD
      if (value && regex.test(value)) {
        const separatorCharacter = (value + '').indexOf('-') > 0 ? '-' : '/';
        __datePickerValue = new Date(value.split(separatorCharacter)[0], parseInt(value.split(separatorCharacter)[1]) - 1, value.split(separatorCharacter)[2]);
        __formattedValue = moment(__datePickerValue).format(dateFormat || 'MM-DD-YYYY');
      }
    }
    setFormattedValue(__formattedValue);
    setDatePickerValue(__datePickerValue);
  }, [value, languageCode]);

  const handleOnChangeInputValue = (ev) => {
    if (type && type === TextInputType.DATE) {
      const __value = ev.target.value;
      setFormattedValue(__value);
      onChange(__value);
    } else {
      setFormattedValue(ev.target.value);
      onChange(ev);
    }
  };

  const handleOnBlurInput = (ev) => {
    if (type && type === TextInputType.DATE) {
      const __value = ev.target.value;
      if (__value && __value !== '') {
        const val = moment(__value, dateFormat || 'MM-DD-YYYY');
        let d = new Date();
        if (val.isValid()) {
          d = val.toDate();
        }
        onChange(moment(d).format(ServerDateFormat));
      }
    }
  };

  const handleOnChangeDatePickerValue = (__value) => {
    const serverValue = __value.format(ServerDateFormat);
    onChange(serverValue);
  };

  const borderStyle = (isSubmitted && errors ? `1px solid ${danger}` : `1px solid ${grey}`);
  return (
    <Box sx={Object.assign({}, styles.boxWrapper, {width: (half ? half : '100%')})} 
      className="uq230811-text-input relative"
    >
      <Typography sx={{ fontSize: "12px" }}>
        {isRequired && <span style={styles.require}>*</span>}
        {text}
      </Typography>
      <input
        name={name}
        type={(inputType && inputType !== '') ? inputType : 'text'}
        value={formattedValue}
        onChange={(__ev) => handleOnChangeInputValue(__ev)}
        onBlur={(__ev) => handleOnBlurInput(__ev)}
        placeholder={placeholder || null}
        helpertext={helperText}
        style={{
          ...styles.input,
          width: "calc(100% - 15px)",
          border: `${borderStyle}`,
        }}
      />

      {/* date icon */}
      {(type && type === TextInputType.DATE) &&
        <div style={styles.dateIconWrapper} className="pointer flexrow"
          onClick={() => setIsOpenDatePicker(true)}
        >
          <CalendarMonthIcon style={styles.dateIcon} />
        </div>
      }

      {/* date picker */}
      {(type && type === TextInputType.DATE) &&
        <div style={styles.datePicker} className="date-picker">
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={languageCode === 'ja' ? ja : undefined}
            localeText={jaJP.components.MuiLocalizationProvider.defaultProps.localeText}
          >
            <DatePicker 
              sx={styles.datePicker}
              value={dayjs(datePickerValue)} 
              onChange={(__value) => handleOnChangeDatePickerValue(__value)}
              open={isOpenDatePicker} 
              onClose={() => setIsOpenDatePicker(false)}
              closeOnSelect={true}
            />
          </LocalizationProvider>
        </div>
      }
    </Box>
  );
}
