import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import {
  signin,
  signup,
  signInLine,
  signInWithTwitter,
  forgotPassword,
  logout,
  sendQuestionEmail,
  updateEmail,
  updatePassword,
} from "./auth.actions";

export const useAuthActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(
    {
      signin,
      signup,
      signInWithTwitter,
      signInLine,
      forgotPassword,
      logout,
      sendQuestionEmail,
      updateEmail,
      updatePassword,
    },
    dispatch
  );
};
