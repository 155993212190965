import React, { useEffect } from "react";
import PageNotFound from "pages/404/PageNotFound";
import AcceptLine from "pages/auth/AcceptLine";
import Signin from "pages/auth/Signin";
import Signup from "pages/auth/Signup";
import CreateResume from "pages/createEditResume/CreateResume";
import EditResume from "pages/createEditResume/EditResume";
import ForgotPassword from "pages/forgotPassword/ForgotPassword";
import Help from "pages/help/Help";
import Resumes from "pages/resumes/Resumes";
import Settings from "pages/settings/Settings";
import ViewResume from "pages/viewResume/ViewResume";
import { useSelector } from "react-redux";
import { Switch, BrowserRouter } from "react-router-dom";
import { useProfileActions } from "redux/profile/useProfileActions";
import { useI18nActions } from "redux/footer/useI18nActions";
import { useHistory } from "react-router-dom";

import Toast from "./custom/toast/Toast";

import { AppRoute } from "./routing/AppRoute";
import { routes } from "./routing/Routes";
import Loader from "custom/loader/Loader";

export default function App() {
  const notify = useSelector((state) => state.toast.list);
  return (
    <>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
      <Toast toastlist={notify} />
    </>
  );
}

function AppRoutes() {
  const history = useHistory();
  const { getMe, saveToken, hideLoading } = useProfileActions();
  const { updateI18n } = useI18nActions();
  const { loading } = useSelector((state) => state.profile);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const languageCode = localStorage.getItem("languageCode");
    if (languageCode == null) {
      localStorage.setItem("languageCode", 'ja');
    }
    const fetchData = async () => {
      await updateI18n(languageCode);
    }
    await fetchData();

    const accessToken = localStorage.getItem("accessToken");
    saveToken(accessToken);
    if (accessToken && accessToken !== '') {
      getMe();
    } else {
      const urlPath = window.location.pathname.split('?')[0];
      const unauthorRoutes = [
        routes().signup,
        routes().forgotPassword,
        routes().acceptLine,
      ];
      if (!(urlPath === '' || urlPath === routes().signin) && 
      !unauthorRoutes.some((__r) => urlPath.indexOf(__r) >= 0)
      ) {
        let willLogout = true;

        if (urlPath === '' || urlPath === '/' || 
        (urlPath.indexOf('/resumes') == 0 && urlPath.indexOf('/resumes/edit') < 0) ||
        urlPath.indexOf(routes().help) == 0) {
          willLogout = false;
        }
        
        if (willLogout) {
          hideLoading();
          history.push(routes().signin);
          return;
        }
      }
      hideLoading();
    }
  };

  return loading ?
    <Loader/>
   :
   (
    <Switch>
      <AppRoute
        exact
        path={routes().signin}
        component={Signin}
      />

      <AppRoute
        path={routes().signup}
        component={Signup}
      />

      <AppRoute
        path={routes().acceptLine}
        component={AcceptLine}
      />

      <AppRoute
        path={routes().forgotPassword}
        component={ForgotPassword}
      />

      {/*  */}

      <AppRoute
        exact
        path={routes().resumes}
        component={Resumes}
      />
      <AppRoute
        exact
        path={routes().resumesList}
        component={Resumes}
      />

      <AppRoute
        path={routes().createResume}
        component={CreateResume}
      />

      <AppRoute
        path={routes().editResumeById}
        component={EditResume}
      />

      <AppRoute
        path={routes().resumeById}
        component={ViewResume}
      />

      <AppRoute
        path={routes().settings}
        component={Settings}
      />

      <AppRoute path={routes().help} 
        component={Help} 
      />

      <AppRoute
        path={routes().pageNotFound}
        component={PageNotFound}
      />
    </Switch>
  );
}