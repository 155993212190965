import React, { useEffect } from "react";
import Footer from "components/footer/Footer";
import Header from "components/header/Header";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";
import { routes } from "../routing/Routes";
import Loader from "custom/loader/Loader";

const MainLayout = ({ children, innerRef, headerProps }) => {
  const history = useHistory();
  const { accessToken, loading } = useSelector((state) => state.profile);

  useEffect(() => {
    const urlPath = window.location.pathname.split('?')[0];
    if (!accessToken && !loading && 
      (urlPath.indexOf(routes().settings) == 0)
    ) {
      history.push(routes().signin);
    }
  }, [accessToken, loading]);

  return !loading ? (
    <Box ref={innerRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Header {...headerProps} />
      <Box sx={{ flexGrow: "1", minHeight: "calc(100vh - 229px)" }}>
        {children}
      </Box>
      <Footer />
    </Box>
  ) : 
  (<Loader />)
  ;
};

export default MainLayout;
