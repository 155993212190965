import React from "react";
import { main, text, white } from "colors";
import ModalComponent from "components/modal/ModalComponent";
import { useSelector } from "react-redux";

import { Box, Typography } from "@mui/material";

const styles = {
  content: {
    background: "#ffffff",
    color: `${text}`,
    padding: "36px",
    margin: "16px",
    "@media(min-width: 600px)": {
      width: "370px",
    },
  },
  popupTitle: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  },
  returnButton: {
    display: "block",
    margin: "40px auto 0 auto",
    background: `${main}`,
    color: `${white}`,
    padding: "10px 16px",
    fontSize: "14px",
    fontWeight: "400",
  },
};

export default function InfoModal({ isOpen, onConfirm }) {
  const language = useSelector((state) => state.footer.language);
  return (
    <ModalComponent isOpen={isOpen}>
      <Box sx={styles.content}>
        <Box sx={styles.popupTitle}>
          <img src="../../svg/done.svg" alt="" />
          <Typography sx={{ marginLeft: "8px" }}>
          {language['Resume.forms.saved.text']}
          </Typography>
        </Box>
        <Box>
          <Typography>
            {language['Resume.list.others.canSeeList']}
          </Typography>
          <button
            onClick={() => onConfirm && onConfirm()}
            style={styles.returnButton}
          >
            Confirm
          </button>
        </Box>
      </Box>
    </ModalComponent>
  );
}
