import React, { useState } from "react";
import { danger, main } from "colors";
import { useSelector } from "react-redux";

import { Typography } from "@mui/material";

import "./schedule.styles.css";

const initialProgressShift = { day: "", start: null, end: null };

const Schedule = ({ setSchedules, schedules }) => {
  let startedTime = 0;
  const [progressShift, setProgressShift] = useState(initialProgressShift);
  const [toDelete, setToDelete] = useState(initialProgressShift);
  const [isPaint, setIsPaint] = useState(false);
  const times = [...Array(48).keys()];
  const language = useSelector((state) => state.footer.language);

  const handleMouseDown = (day, hourIndex) => {
    setProgressShift({ day, start: hourIndex, end: hourIndex });
    setIsPaint(true);
  };

  const handleMouseMove = (hourIndex) => {
    if (progressShift.start === null) return;
    setProgressShift((prev) => ({ ...prev, end: hourIndex }));
  };

  const handleMouseUp = () => {
    const clonedSchedules = JSON.parse(JSON.stringify(schedules));
    let findSched = clonedSchedules.find(
      (schedule) => schedule.day === progressShift.day
    ).hours;
    for (
      let i = Math.min(progressShift.start, progressShift.end);
      i <= Math.max(progressShift.end, progressShift.start);
      i++
    ) {
      findSched[i] = true;
    }
    setSchedules(clonedSchedules);
    setProgressShift(initialProgressShift);
    setIsPaint(false);
  };

  const handleClick = (day, hourIndex) => {
    let findSched = day.hours;

    if (findSched[hourIndex]) {
      while (findSched[hourIndex]) {
        hourIndex--;
      }
      hourIndex++;

      const start = hourIndex;

      while (findSched[hourIndex]) {
        hourIndex++;
      }
      hourIndex--;

      const end = hourIndex;
      setToDelete({ day: day.day, start, end });
    }
  };

  const deleteSchedule = (e) => {
    e.stopPropagation();

    const clonedSchedules = JSON.parse(JSON.stringify(schedules));
    let findSched = clonedSchedules.find(
      (schedule) => schedule.day === toDelete.day
    ).hours;

    for (let i = toDelete.start; i <= toDelete.end; i++) {
      findSched[i] = false;
    }

    setSchedules(clonedSchedules);
    setToDelete(initialProgressShift);
  };

  const styleForLine = (hours, hourIndex) => {
    if (hourIndex - 1 < 0) {
      return "pickedBlock-first";
    }
    if (hourIndex + 1 > 47) {
      return "pickedBlock-last";
    }
    if (hours[hourIndex - 1] === false && hours[hourIndex + 1] === true) {
      return "pickedBlock-first";
    }
    if (hours[hourIndex - 1] === true && hours[hourIndex + 1] === true) {
      return "pickedBlock";
    }
    if (hours[hourIndex - 1] === true && hours[hourIndex + 1] === false) {
      return "pickedBlock-last";
    }
  };

  return (
    <section style={{ padding: "0 16px" }}>
      <Typography
        style={{
          textTransform: "uppercase",
          color: "#323232",
          lineHeight: "16px",
          margin: "40px 0",
          fontSize: "12px",
        }}
      >
        <span style={{ color: `${danger}` }}>*</span>
        {language['Resume.forms.workShift']}
      </Typography>
      <div className="workshift-container">
        <div className="workshift-block">
          <div className="times">
            {times.map((t, timeIndex) => {
              if (timeIndex % 2) {
                if (timeIndex > 1) {
                  startedTime++;
                }
                return (
                  <p className="time" key={timeIndex}>
                    {startedTime.toString().padStart(2, "0")}
                  </p>
                );
              }
            })}
          </div>
        </div>
        {/*  */}
        <div className="content">
          {schedules?.map((schedule, scheduleIndex) => {
            return (
              <div key={scheduleIndex} className="schedule">
                <div className="scheduleDayBlock">
                  <p className="scheduleDay">{language[`Resume.forms.workShift.${(schedule.day + '').toLowerCase()}`]}</p>
                </div>

                <div className="hoursBlock">
                  {schedule?.hours.map((hour, hourIndex) => {
                    let activeStyle =
                      hour ||
                      (progressShift.day === schedule.day &&
                        hourIndex <=
                          Math.max(progressShift.end, progressShift.start) &&
                        hourIndex >=
                          Math.min(progressShift.start, progressShift.end))
                        ? `${main}`
                        : "transparent";

                    return (
                      <div
                        key={hourIndex}
                        className="hourItem"
                        onClick={() => handleClick(schedule, hourIndex)}
                        value={hourIndex}
                        onMouseDown={() =>
                          handleMouseDown(schedule.day, hourIndex)
                        }
                        onMouseEnter={() => handleMouseMove(hourIndex)}
                        onMouseUp={() => handleMouseUp()}
                      >
                        <div
                          className={
                            isPaint && progressShift.day === schedule.day
                              ? "pickedBlock"
                              : styleForLine(schedule.hours, hourIndex)
                          }
                          style={{
                            background: activeStyle,
                            opacity:
                              toDelete.day === schedule.day &&
                              hourIndex <= toDelete.end &&
                              hourIndex >= toDelete.start
                                ? "0.5"
                                : "1",
                          }}
                        ></div>

                        {toDelete.day === schedule.day &&
                        hourIndex === toDelete.end ? (
                          <button
                            className="removeButton"
                            onClick={(e) => deleteSchedule(e)}
                          >
                            <img src="../../svg/delete.svg" alt="" />
                          </button>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Schedule;
