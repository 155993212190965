import * as React from "react";
import {Component, HTMLProps} from "react";

interface Props extends HTMLProps<any> {
    color?: string;
    width?: string;
}

interface State {
    
}

export default class SvgIcon_Edit extends Component<Props, State> {
    
    static defaultProps = {
        color: '#29CC8F',
        width: '32px',
    };

    constructor(props: Props) {
        super(props);
    }

    render() {
        let fillColor = this.props.color || '';
        if (fillColor.length == 0) {
            fillColor = '#29CC8F';
        }

        return (
            <svg width={this.props.width} height={this.props.width} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 30.1001C0 29.824 0.223858 29.6001 0.5 29.6001H31.5C31.7761 29.6001 32 29.824 32 30.1001V32.0001H0V30.1001Z" fill={fillColor}/>
                <path d="M11.3226 24.5304L5.61791 25.6389L6.7264 19.9342L25.6 1.06066L30.1962 5.65685L11.3226 24.5304Z" stroke={fillColor} strokeWidth="1.5"/>
            </svg>
        );
    }

}