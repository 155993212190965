export const styles = {
  button: {
    position: "relative",
    minWidth: "120px",

    height: "40px",
    cursor: "pointer",
    background: "transparent",
    border: "1px solid #29CC8F",
    boxShadow: "1px 2px 4px rgba(0, 0, 0, 0.14)",
    outline: "none",
    transition: "0.1s ease-in-out",
    textTransform: "capitalize",
    marginLeft: "12px",
    lineHeight: "16px",
    color: "#29CC8F",
    fontSize: "16px",
    borderRadius: "4px",
    padding: "9px 19px",
  },
  inner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  icon: {
    marginRight: "10px",
    width: "18px",
    height: "18px",
  },
};
