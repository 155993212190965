import { Grid, Typography, Checkbox } from "@mui/material";
import { styles } from "./authCheckbox.styles";
import { useSelector } from "react-redux";

const AuthCheckbox = ({ isRememberMe, setIsRememberMe }) => {
  const language = useSelector((state) => state.footer.language);

  return (
    <Grid sx={styles.box}>
      <Checkbox
        checked={isRememberMe}
        onChange={(e) => setIsRememberMe(e.target.checked)}
        sx={styles.checkbox}
      />
      <Typography sx={styles.label}>{language['Authen.Login.RememberMe']}</Typography>
    </Grid>
  );
};



export default AuthCheckbox;
