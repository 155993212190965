import React, { useState } from "react";
import ModalComponent from "components/modal/ModalComponent";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { routes } from "routing/Routes";

import CircleIcon from "@mui/icons-material/Circle";
import { Box } from "@mui/material";
import { styles } from "./actionHeader.styles";
import ActionButton from "../../../custom/buttons/actionButton/ActionButton";
import Sharing from "../../sharing/Sharing";

import InfoModal from "pages/viewResume/components/InfoModal";
import { ResumeStatus } from "models/resume";
import SvgIcon_Edit from "../../svg/edit";
import SvgIcon_Download from "../../svg/download";
import SvgIcon_Share from "../../svg/share";
import SvgIcon_AddList from "../../svg/addList";
import Loader from "custom/loader/Loader";

export const ActionType = {
  create: 'create',
  edit: 'edit',
  view: 'view',
};

export default function ActionHeader({
  type,
  resume,
  publishResume,
  unpublishResume,
  saveChanges,
  handlePrint,
  saveToMyList,
  isShowLoading
}) {
  const history = useHistory();
  const [isShowMobilePopupMenu, setIsShowMobilePopupMenu] = useState(false);
  const [isOpenModalSharing, setIsOpenModalSharing] = useState(false);
  const [isOpenModalConfirmmSaveToMyList, setIsOpenModalConfirmmSaveToMyList] = useState(false);
  const {userId, accessToken} = useSelector((state) => state.profile);
  const language = useSelector((state) => state.footer.language);

  if (isShowLoading) {
    return (<div><Loader style={styles.loading} /></div>);
  }

  return (
    <section>
      {/* [desktop] */}
      <Box sx={styles.dekstop}>
        {(() => {
          switch (type) {
            case ActionType.create:
            case ActionType.edit:
              return (
                <Box>
                  <ActionButton
                    text={language['Resume.forms.saveChanges']}
                    func={() => saveChanges()}
                  />
                  <ActionButton 
                    text={language['Resume.page.publish']} 
                    func={() => publishResume()} 
                  />
                  {(type === ActionType.edit && resume && resume.status && resume.status === ResumeStatus.published) &&
                    <ActionButton 
                      text={language['Resume.page.unpublish']} 
                      func={() => unpublishResume()} 
                    />
                  }
                </Box>
              );
            case ActionType.view:
              return (
                <>
                  {(resume && accessToken && resume.userId === userId && (!resume.originalResume || resume.originalResume === '')) &&
                    <ActionButton
                      text={language['Resume.forms.edit']}
                      icon={<SvgIcon_Edit color="currentColor" width="18px" />}
                      func={() =>
                        history.push(routes({ id: resume._id }).editResumeById)
                      }
                    />
                  }
                  {(resume && (!accessToken || (resume.userId !== userId && !resume.isExistsInMyList))) &&
                    <ActionButton
                      text={language['Resume.forms.saveToList']}
                      icon={<SvgIcon_AddList color="currentColor" width="18px" />}
                      func={() => {
                        if (!accessToken) {
                          history.push(routes().signin + '?resume_id=' + resume._id);
                        } else {
                          setIsOpenModalConfirmmSaveToMyList(!isOpenModalConfirmmSaveToMyList);
                        }
                      }}
                    />
                  }
                  <ActionButton
                    icon={<SvgIcon_Download color="currentColor" width="18px" />}
                    text={language['Resume.forms.downloadPDF']}
                    func={() => handlePrint && handlePrint()}
                  />
                  {(accessToken && accessToken !== '') &&
                    <ActionButton
                      text={language['Resume.forms.share']}
                      icon={<SvgIcon_Share color="currentColor" width="18px" />}
                      func={() => setIsOpenModalSharing(true)}
                    />
                  }
                </>
              );
            default:
              return null;
          }
        })()}
      </Box>

      {/* [mobile] edit screen */}
      <Box sx={styles.mobile}>
        <button style={styles.combineButton} onClick={() => setIsShowMobilePopupMenu(!isShowMobilePopupMenu)}>
          <CircleIcon sx={styles.dotIcon} />
          <CircleIcon sx={styles.dotIcon} />
          <CircleIcon sx={styles.dotIcon} />
        </button>

        {isShowMobilePopupMenu && (
          <Box sx={styles.blockMenu}>
            {(() => {
              switch (type) {
                case ActionType.create:
                case ActionType.edit:
                  return (
                    <Box sx={styles.menu}>
                      <button
                        style={styles.menuButton}
                        onClick={() => saveChanges()}
                      >
                        {language['Resume.forms.saveChanges']}
                      </button>
                      <button
                        style={styles.menuButton}
                        onClick={() => publishResume()}
                      >
                        {language['Resume.page.publish']}
                      </button>
                      {(type === ActionType.edit && resume && resume.status && resume.status === ResumeStatus.published) &&
                        <button
                          style={styles.menuButton}
                          onClick={() => unpublishResume()}
                        >
                          {language['Resume.page.unpublish']}
                        </button>
                      }
                    </Box>
                  );
                case ActionType.view:
                  return (
                    <Box sx={styles.menu}>
                      {(resume && accessToken && resume.userId === userId && (!resume.originalResume || resume.originalResume === '')) &&
                        <button
                          style={styles.menuButton}
                          onClick={() =>
                            history.push(routes({ id: resume._id }).editResumeById)
                          }
                        >
                          {language['Resume.forms.edit']}
                        </button>
                      }
                      {(resume && (!accessToken || (resume.userId !== userId && !resume.isExistsInMyList))) &&
                        <button
                          style={styles.menuButton}
                          onClick={() => {
                            if (!accessToken) {
                              history.push(routes().signin + '?resume_id=' + resume._id);
                            } else {
                              setIsOpenModalConfirmmSaveToMyList(!isOpenModalConfirmmSaveToMyList);
                            }
                          }}
                        >
                          {language['Resume.forms.saveToList']}
                        </button>
                      }
                      <button
                        style={styles.menuButton}
                        onClick={() => handlePrint && handlePrint()}
                      >
                        {language['Resume.forms.downloadPDF']}
                      </button>
                      {(accessToken && accessToken !== '') &&
                        <button
                            style={styles.menuButton}
                            onClick={() => setIsOpenModalSharing(true)}
                          >
                            {language['Resume.forms.share']}
                        </button>
                      }
                    </Box>
                  );
                default:
                  return null;
              }
            })()}
          </Box>
        )}
      </Box>

      <ModalComponent isOpen={isOpenModalSharing}>
        <Sharing setIsOpen={setIsOpenModalSharing} />
      </ModalComponent>

      <InfoModal
        isOpen={isOpenModalConfirmmSaveToMyList}
        onConfirm={() => saveToMyList && saveToMyList()}
      /> 
    </section>
  );
}
