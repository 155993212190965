import React, { useEffect, useState, useRef } from "react";
import ActionHeader, { ActionType } from "components/resume/actionHeader/ActionHeader";
import ExperienceIntroduction from "components/resume/ExperienceIntroduction";
import SelfIntroduction from "components/resume/SelfIntroduction";
import Schedule from "components/schedule/dekstop/Schedule";
import MobileSchedule from "components/schedule/mobile/MobileSchedule";
import MobileSavingButton from "custom/buttons/mobileSavingButton/MobileSavingButton";
import ResumeTitleInput from "custom/inputs/resumeTitleInput/ResumeTitleInput";
import Loader from "custom/loader/Loader";
import { Formik } from "formik";
import { useWindowSize } from "helpers/detectScreenSize";
import { getFormValidation } from "helpers/initialValues";
import { useLocationId } from "hooks/useLocationId";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useToastActions } from "redux/toast/useToastActions";

import { Box } from "@mui/material";
import { styles } from "./createEditResume.styles";
import ModalConfirmation from "components/resume/resumeCard/modalConfirmation/ModalConfirmation";
import MainLayout from "layouts/MainLayout";
import ApiResumeService from "services/ApiResumeService";
import { routes } from "routing/Routes";
import { ResumeStatus } from "models/resume";

const initial = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
].map((day) => ({ day, hours: Array(48).fill(false) }));

export default function EditResume() {
  let id = useLocationId();
  let containerRef = useRef();
  const formikRef = useRef();
  const history = useHistory();
  const [isLoadingResume, setIsLoadingResume] = useState(true);
  const [resume, setResume] = useState(null);
  const [resumeTitle, setResumeTitle] = useState("");
  const [schedules, setSchedules] = useState([]);
  const [width] = useWindowSize();
  const [isDataSaving, setIsDataSaving] = useState(false); // show loading in ActionHeader
  const [isSubmitted, setSubmitted] = useState(false); // highlight text input error
  const [isOpenUnpublishConfirm, setIsOpenUnpublishConfirm] = useState(false);
  const language = useSelector((state) => state.footer.language);
  const languageCode = useSelector((state) => state.footer.languageCode);
  const [validation, setValidation] = React.useState(getFormValidation(languageCode));

  const { showToast } = useToastActions();

  useEffect(() => {
    const getResumeDetail = async (resumeId) => {
      const result = await ApiResumeService.getResumeDetail(resumeId);
      if (containerRef) {
        if (result && result.success) {
          setResume(result.data);
        } else if (result && result.message) {
          showToast(result.message);
        }
        setIsLoadingResume(false);
      }
    };
    getResumeDetail(id);
  }, []);

  useEffect(() => {
    if (resume) {
      setSchedules(resume.workshift);
      setResumeTitle(resume.resumeTitle);
    }
  }, [resume]);

  useEffect(() => {
    setValidation(getFormValidation(languageCode));
  }, [languageCode]);

  const validateForm = (submitAction) => {
    if (formikRef?.current) {
      const jsonData = Object.assign({}, formikRef.current.values, {
        resumeTitle,
        workshift: schedules
      });
      if (submitAction === 'update') {
        saveResume(id, jsonData);
        return;
      }

      // submitAction = publish
      formikRef.current
        .validateForm()
        .then((errors) => {
          console.log(errors);
          if (Object.keys(errors).length !== 0) {
            setSubmitted(true);
            if (languageCode === 'ja' &&
              (!formikRef.current.values.kanaName ||
              !formikRef.current.values.kanaSurname)
            ) {
              showToast(errors.kanaName);
            } else if (!formikRef.current.values.mainImage) {
              showToast(errors.mainImage);
            } else {
              const keys = Object.keys(errors);
              showToast(errors[keys[0]]);
            }
            showToast(language['Resume.showToast.fillHighlightedField']);
          } else if (JSON.stringify(schedules) == JSON.stringify(initial)) {
            showToast(language['Resume.showToast.fillWorkshift']);
          } else {
            setSubmitted(false);
            publishResume(id, jsonData);
          }
        })
        .catch((error) => {
          showToast(error);
        });
    }
  };

  const saveResume = async(resumeId, jsonData) => {
    setIsDataSaving(true);
    const result = await ApiResumeService.updateResume(resumeId, jsonData);
    if (containerRef) {
      if (result && result.success) {
        showToast(language['Resume.save.successfull']);
        history.push(routes({id: resumeId}).resumeById);
      } else {
        setIsDataSaving(false);
        if (result && result.message) {
          showToast(result.message);
        }
      }
    }
  };

  const publishResume = async(resumeId, jsonData) => {
    jsonData.status = ResumeStatus.published;
    setIsDataSaving(true);
    const result = await ApiResumeService.publishResume(resumeId, jsonData);
    if (containerRef) {
      if (result && result.success) {
        showToast(language['Resume.publish.successfull']);
        history.push(routes().resumes);
      } else {
        setIsDataSaving(false);
        if (result && result.message) {
          showToast(result.message);
        }
      }
    }
  };

  const showConfirmUnpublishResume = () => {
    setIsOpenUnpublishConfirm(true);
  };

  const processUnpublishResume = async () => {
    setIsOpenUnpublishConfirm(false);
    setIsDataSaving(true);
    const result = await ApiResumeService.unpublishResume(id);
    if (containerRef) {
      if (result && result.success) {
        setResume(Object.assign(resume, {status: ResumeStatus.editing}));
        showToast(language['Resume.unpublish.successfull']);
      } else {
        if (result && result.message) {
          showToast(result.message);
        }
      }
      setIsDataSaving(false);
    }
  };

  return (
    <MainLayout innerRef={containerRef}>
      <>
        {isLoadingResume ? (
          <Box sx={{ minHeight: "1000px" }} className="relative">
            <Loader />
          </Box>
        ) : resume && (
          <Box style={styles.section}>
            <Box sx={styles.block} className="flexrow ver-center space-between">
              <ResumeTitleInput
                resumeTitle={resumeTitle}
                setResumeTitle={setResumeTitle}
              />
              <ActionHeader
                type={ActionType.edit}
                resume={resume}
                isShowLoading={isDataSaving}
                publishResume={() => validateForm('publish')}
                saveChanges={() => validateForm('update')}
                unpublishResume={showConfirmUnpublishResume}
              />
            </Box>

            <Formik
              innerRef={formikRef}
              initialValues={{
                ...resume,
                files: [], // TODO: why ?
              }}
              validationSchema={validation}
            >
              {({ values, handleChange, errors, setFieldValue }) => (
                <Box sx={styles.container}>
                  <Box sx={{ ...styles.selfIntroduction }}>
                    <SelfIntroduction
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      typeAction={"create"}
                      isSubmitted={isSubmitted}
                    />
                  </Box>
                  <Box sx={{ ...styles.fields }}>
                    <ExperienceIntroduction
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      typeAction={"create"}
                      isSubmitted={isSubmitted}
                      language={language}
                    />
                    {width > 1100 ? (
                      <Schedule
                        setSchedules={setSchedules}
                        schedules={schedules}
                      />
                    ) : (
                      <MobileSchedule
                        setSchedules={setSchedules}
                        schedules={schedules}
                      />
                    )}

                    <MobileSavingButton
                      resumeStatus={resume ? resume.status : ''}
                      publishResume={() => validateForm('publish')}
                      saveChanges={() => validateForm('update')}
                      unpublishResume={showConfirmUnpublishResume}
                    />
                  </Box>
                </Box>
              )}
            </Formik>
          </Box>
        )}

        <ModalConfirmation
          resume={resume}
          isOpen={isOpenUnpublishConfirm}
          language={language}
          modalTitle={language['Resume.page.unpublish']}
          modalContent={language['Resume.unpublish.confirmMessage']}
          modalYesnButtonTitle={language['Resume.page.unpublish']}
          setIsOpen={setIsOpenUnpublishConfirm}
          onYesButtonClick={() => processUnpublishResume()}
        />
      </>
    </MainLayout>
  );
}
