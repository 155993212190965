import { Menu, MenuItem } from "@mui/material";

const LanguageDropDown = ({isLanguageDropdownVisible, anchorEL, setVisibleLanguageDropDown, updateI18n}) => {
    const setLanguageCode = (languageCode) => {
        setVisibleLanguageDropDown({isLanguageDropdownVisible: !isLanguageDropdownVisible, anchorEL: null});
        localStorage.setItem("languageCode", languageCode);
        updateI18n(languageCode);
    };
    
    return (
        <Menu 
            open={isLanguageDropdownVisible}
            anchorEl={anchorEL}
            onClose={() => setVisibleLanguageDropDown({isLanguageDropdownVisible: !isLanguageDropdownVisible, anchorEL: null})}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <MenuItem onClick={() => setLanguageCode('ja')}>日本語</MenuItem>
            <MenuItem onClick={() => setLanguageCode('en')}>English</MenuItem>
        </Menu>
    )
  }
  
  export default LanguageDropDown;