import { grey, main } from "colors";

export const styles = {
  cardsItem: {
    position: "relative",
    display: "flex",
    padding: "0.50rem 0rem",
    height: "164px",
    "@media (max-width: 450px)": {
      margin: "0 auto",
    },
  },
  card: {
    position: "relative",
    width: "132px",
    height: "164px",
    backgroundColor: "#ffffff",
    borderRadius: "0.25rem",
    justifyContent: "space-between",
    border: "2px solid #29CC8F",
  },
  text: {
    fontSize: "10px",
  },
  captionBox: {
    padding: "0 8px",
    flexDirection: "column",
    height: "73px",
  },
  otherCaption: {
    marginTop: "5px",
    color: `${main}`,
    fontSize: "14px",
    fontWeight: 300,
    maxHeight: "48px",
    overflow: "hidden",
  },
  flexBox: {
    display: "flex",
    justifyContent: "space-between",
  },

  mainImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    background: `${grey}`,
  },
};
