import { showToast } from "redux/toast/toast.actions";
import ApiResumeService from "services/ApiResumeService";

import {
  REMOVE_SELECTED_EDITED_RESUME,
  REMOVE_SELECTED_EDITED_RESUME_ERROR,
  REMOVE_SELECTED_EDITED_RESUME_SUCCESS,
  REMOVE_SELECTED_OTHER_RESUME,
  REMOVE_SELECTED_OTHER_RESUME_ERROR,
  REMOVE_SELECTED_OTHER_RESUME_SUCCESS,
  REMOVE_SELECTED_PUBLISHED_RESUME,
  REMOVE_SELECTED_PUBLISHED_RESUME_ERROR,
  REMOVE_SELECTED_PUBLISHED_RESUME_SUCCESS,
  REMOVE_SELECTED_SAVED_RESUME,
  REMOVE_SELECTED_SAVED_RESUME_ERROR,
  REMOVE_SELECTED_SAVED_RESUME_SUCCESS,
} from "./removeResume.types";

export const removeSelectedPublishedResume = (resume) => {
  return async (dispatch) => {
    try {
      dispatch(removeSelectedPublishedResumeInit());
      await ApiResumeService.removePublishedResume(resume._id);
      dispatch(removeSelectedPublishedResumeSuccess(resume._id));
      dispatch(showToast(`"${resume.resumeTitle}" delete`));
    } catch (error) {
      dispatch(removeSelectedPublishedResumeError(error));
    }
  };
};

export const removeSelectedPublishedResumeInit = () => {
  return { type: REMOVE_SELECTED_PUBLISHED_RESUME };
};

export const removeSelectedPublishedResumeSuccess = (payload) => {
  return { type: REMOVE_SELECTED_PUBLISHED_RESUME_SUCCESS, payload };
};

export const removeSelectedPublishedResumeError = (payload) => {
  return { type: REMOVE_SELECTED_PUBLISHED_RESUME_ERROR, payload };
};

//

export const removeSelectedSavedResume = (resumeId) => {
  return async (dispatch) => {
    try {
      dispatch(removeSelectedSavedResumeInit());
      const response = await ApiResumeService.removeSavedResume(resumeId);
      dispatch(removeSelectedSavedResumeSuccess(response.data._id));
      dispatch(showToast(`"${response.data.resumeTitle}" delete`));
    } catch (error) {
      dispatch(removeSelectedSavedResumeError(error));
    }
  };
};

export const removeSelectedSavedResumeInit = () => {
  return { type: REMOVE_SELECTED_SAVED_RESUME };
};

export const removeSelectedSavedResumeSuccess = (payload) => {
  return { type: REMOVE_SELECTED_SAVED_RESUME_SUCCESS, payload };
};

export const removeSelectedSavedResumeError = (payload) => {
  return { type: REMOVE_SELECTED_SAVED_RESUME_ERROR, payload };
};

//

export const removeSelectedOtherResume = (resume) => {
  return async (dispatch) => {
    try {
      dispatch(removeSelectedOtherResumeInit());
      await ApiResumeService.removeOtherResume(resume._id);
      dispatch(removeSelectedOtherResumeSuccess(resume._id));
      dispatch(showToast(`"${resume.resumeTitle}" delete`));
    } catch (error) {
      dispatch(removeSelectedOtherResumeError(error));
    }
  };
};

export const removeSelectedOtherResumeInit = () => {
  return { type: REMOVE_SELECTED_OTHER_RESUME };
};

export const removeSelectedOtherResumeSuccess = (payload) => {
  return { type: REMOVE_SELECTED_OTHER_RESUME_SUCCESS, payload };
};

export const removeSelectedOtherResumeError = (payload) => {
  return { type: REMOVE_SELECTED_OTHER_RESUME_ERROR, payload };
};

export const removeSelectedEditedResume = (resumeId) => {
  return async (dispatch) => {
    try {
      dispatch(removeSelectedEditedResumeInit());
      const response = await ApiResumeService.removeEditedResume(resumeId);
      dispatch(removeSelectedEditedResumeSuccess(response.data._id));
      dispatch(showToast(`"${response.data.resumeTitle}" delete`));
    } catch (error) {
      dispatch(removeSelectedEditedResumeError(error));
    }
  };
};

export const removeSelectedEditedResumeInit = () => {
  return { type: REMOVE_SELECTED_EDITED_RESUME };
};

export const removeSelectedEditedResumeSuccess = (payload) => {
  return { type: REMOVE_SELECTED_EDITED_RESUME_SUCCESS, payload };
};

export const removeSelectedEditedResumeError = (payload) => {
  return { type: REMOVE_SELECTED_EDITED_RESUME_ERROR, payload };
};
