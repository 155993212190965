import { Box, Typography } from "@mui/material";
import { styles } from "./footer.styles";
import { useState } from "react";
import LanguageDropDown from "./LanguageDropDown";
import { useI18nActions } from "redux/footer/useI18nActions";
import { useSelector } from "react-redux";

const Footer = () => {
  const [{isLanguageDropdownVisible, anchorEL}, setVisibleLanguageDropDown] = useState({isLanguageDropdownVisible: false, anchorEL: null});
  const { updateI18n } = useI18nActions();
  const language = useSelector((state) => state.footer.language);
  const languageCode = useSelector((state) => state.footer.languageCode);

  const showLanguageDropDown = (e) => {
    setVisibleLanguageDropDown({isLanguageDropdownVisible: !isLanguageDropdownVisible, anchorEL: e.currentTarget});
  }

  const selectedLanguage = (languageCode) => {
    switch (languageCode) {
      case 'en':
        return language['Resume.footer.english'];
      default:
        return language['Resume.footer.japanese'];
    }
  }
  
  return (
    <Box sx={styles.container}>
      <Box sx={styles.content}>
        <Box sx={styles.languageWrapper} onClick={(e) => showLanguageDropDown(e)}>
          <img src="../../Bitmap.png" alt="" />
          <Typography sx={styles.languageCountryName}>{selectedLanguage(languageCode)}</Typography>
          <img src="../../Shape.png" alt="" />
        </Box>
        <Typography sx={styles.item}>
          <a href={"https://www.workstyletech.com/"}>
            {language['Resume.footer.company']}
          </a>
          </Typography>
        <Typography sx={styles.item}><a href={"https://www.welcomehr.net/"}>WelcomeHR</a></Typography>
        <Typography sx={styles.item}><a href={"https://mkt.welcomehr.jp/terms/"}>{language['Resume.footer.termsOfService']}</a></Typography>
        <Typography sx={styles.item}><a href={"https://www.welcomehr.net/%E3%83%97%E3%83%A9%E3%82%A4%E3%83%90%E3%82%B7%E3%83%BC%E3%83%9D%E3%83%AA%E3%82%B7%E3%83%BC"}>{language['Resume.footer.privacyPolicy']}</a></Typography>
        <Typography sx={{ ...styles.copyright }}>
        {`Copyright © 2020 ${language['Resume.footer.company']}, All rights reserved.`}
          v1.5.0916.1
        </Typography>
      </Box>
      <LanguageDropDown isLanguageDropdownVisible={isLanguageDropdownVisible} anchorEL={anchorEL} setVisibleLanguageDropDown={setVisibleLanguageDropDown} updateI18n={updateI18n} />
    </Box>
  );
};


export default Footer;
 
