export const ResumeStatus = {
    editing: 'editing',
    published: 'published'
};

export interface Resume {
    _id?: string;
    status?: string;
    userId?: string;
    originalResume?: string;
    resumeTitle?: string;
    surname?: string;
    name?: string;
    kanaSurname?: string;
    kanaName?: string;
    position?: string;
    nationality?: string;
    gender?: string;
    birthday?: string;
    phone?: string;
    eMail?: string;
    address?: string;
    busStation?: string;
    transport?: string;
    motivation?: string;
    workHistory?: string[];
    industries?: string[];
    experiences?: string[];
    languages?: string[];
    certifications?: string[];
    awards?: string[];
    schools?: string[];
    selfPromotion?: string;
    skills?: string[];
    interests?: string[];
    files?: {name: string; mimetype: string}[];
    whenStart?: Date | string;
    workshift?: any;
    mainImage?: {name: string; mimetype: string};
    uploadFiles?: {name: string; mimetype: string}[];
}