import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { useToastActions } from "redux/toast/useToastActions";

import { Box, Typography } from "@mui/material";
import { styles } from "./uploadButton.styles";
import EditPhotoButton from "../editPhotoButton/EditPhotoButton";
import ImageViewer from "components/image-viewer/ImageViewer";
import { Utils } from "utils/utils";
import VideoViewer from "components/image-viewer/VideoViewer";

export default function UploadImage({
  setFieldValue,
  uploadImage, // object returned from backend
  typeAction = "",
  language,
}) {
  const [img, setImg] = useState(null);
  const [fileType, setFileType] = useState(null);
  const { showToast } = useToastActions();
  const [isOpenImageViewer, setIsOpenImageViewer] = useState(false);
  const [isOpenVideoViewer, setIsOpenVideoViewer] = useState(false);
  let inputFileRef = useRef();

  const handleChange = (event) => {
    const tmpFile = event.currentTarget.files && event.target.files;
    if (tmpFile[0].size > (10 * 1024 * 1024)) { // 10 MB
      let warningMsg = language['Resume.showToast.maxImageSize'];
      warningMsg = warningMsg.replace('{{number}}', '10');
      showToast(warningMsg);
      return;
    }

    if (tmpFile[0].type && !['image/', 'video/'].some((__mime) => tmpFile[0].type.indexOf(__mime) == 0)) {
      showToast(language['Upload.fileTypeNotAllowed']);
      return;
    }

    setFieldValue("mainImage", tmpFile);
    setImg(URL.createObjectURL(tmpFile[0]));
    setFileType(tmpFile[0].type.split("/")[0]);
  };

  useEffect(() => {
    if (uploadImage) {
      if (uploadImage.mimetype) {
        setFileType(uploadImage.mimetype.split("/")[0]);
      } else {
        setFieldValue("mainImage", uploadImage);
        setImg(URL.createObjectURL(uploadImage[0]));
        setFileType(uploadImage[0].type.split("/")[0]);
      }
    }
  }, []);

  const handleOnClickView = () => {
    if (uploadImage && uploadImage.name && uploadImage.name !== '') {
      const url = `${process.env.REACT_APP_MEDIA_STORAGE}/${uploadImage.name}`;
      if (Utils.isImageFile(url)) {
        setIsOpenImageViewer(true);
      } else if (fileType === 'video' || Utils.isVideoFile(url)) {
        setIsOpenVideoViewer(true);
      } else {
        window.open(url, '_blank');
      }
    }
  };

  const handleOnClickChange = () => {
    inputFileRef.current.click();
  };

  return (
    <Box sx={styles.uploadImage}>
      <label style={styles.uploadLabelImage} htmlFor="image-input">
        <input ref={inputFileRef}
          type="file"
          name="image-input"
          id="image-input"
          onChange={(event) => handleChange(event)}
          style={styles.uploadInput}
          accept="image/*, video/*"
        />
        <Typography sx={styles.text}>
        {language['Resume.forms.postMovie']}
        </Typography>
        {(() => {
          switch (fileType) {
            case "video":
              return (
                <video
                  style={styles.image}
                  src={
                    typeAction === "create" && img !== null
                      ? img
                      : `${process.env.REACT_APP_MEDIA_STORAGE}/${uploadImage?.name}`
                  }
                  controls={false}
                >
                  <track
                    src="captions_en.vtt"
                    kind="captions"
                    srcLang="en"
                    label="english_captions"
                  ></track>
                </video>
              );
            case "image":
              return (
                <img
                  src={
                    typeAction === "create" && img !== null
                      ? img
                      : `${process.env.REACT_APP_MEDIA_STORAGE}/${uploadImage?.name}`
                  }
                  alt=""
                  style={styles.image}
                />
              );
            default:
              return null;
          }
        })()}
      </label>

      {(fileType && (img || (uploadImage && uploadImage.name && uploadImage.name !== ''))) &&
        <Box sx={{ position: "absolute", bottom: "10px", right: "10px" }}>
          <EditPhotoButton
            isViewEnabled={(uploadImage && uploadImage.name && uploadImage.name !== '')}
            onView={() => handleOnClickView()}
            onChange={() => handleOnClickChange()}
            onDelete={() => {
              setImg(null);
              setFieldValue("mainImage", null);
              setFileType(null);
            }}
          />
        </Box>
      }

      {isOpenImageViewer &&
        <ImageViewer 
          urls={[`${process.env.REACT_APP_MEDIA_STORAGE}/${uploadImage.name}`]}
          onClose={() => setIsOpenImageViewer(false)}
        />
      }

      {isOpenVideoViewer &&
        <VideoViewer 
          urls={[`${process.env.REACT_APP_MEDIA_STORAGE}/${uploadImage.name}`]}
          onClose={() => setIsOpenVideoViewer(false)}
        />
      }
    </Box>
  );
}
