export const GET_OWN_RESUMES_LIST = "GET_OWN_RESUMES_LIST";
export const GET_OWN_RESUMES_LIST_SUCCESS = "GET_OWN_RESUMES_LIST_SUCCESS";
export const GET_OWN_RESUMES_LIST_ERROR = "GET_OWN_RESUMES_LIST_ERROR";

export const GET_OTHER_RESUME_LIST = "GET_OTHER_RESUME_LIST";
export const GET_OTHER_RESUME_LIST_SUCCESS = "GET_OTHER_RESUME_LIST_SUCCESS";
export const GET_OTHER_RESUME_LIST_ERROR = "GET_OTHER_RESUME_LIST_ERROR";

export const CLONE_SELECTED_RESUME = "CLONE_SELECTED_RESUME";
export const CLONE_SELECTED_RESUME_SUCCESS = "CLONE_SELECTED_RESUME_SUCCESS";
export const CLONE_SELECTED_RESUME_ERROR = "CLONE_SELECTED_RESUME_ERROR";
