import React from "react";
import { useSelector } from "react-redux";

import { Box, Grid, Typography } from "@mui/material";
import { styles } from "./authSidebar.styles";

const AuthSidebar = () => {
  const language = useSelector((state) => state.footer.language);

  return (
    <Grid item xs={12} sm={12} md={6} sx={styles.section}>
      <Box sx={styles.box}>
        <Box sx={styles.desktop}>
          <img src="../../logo/white-logo.svg" alt="logo" style={styles.logo} />
        </Box>

        <Box sx={styles.mobileBox}>
          <img src="../../logo/logo.svg" alt="logo" style={styles.mobileLogo} />
        </Box>

        <Box sx={styles.text}>
          <Typography>WelcomeHR</Typography>
          <Typography>{language['Resume.title']}</Typography>
        </Box>
      </Box>

      <Box sx={styles.block}>
        <Typography sx={styles.title}>{language['Resume.resumeInMinutes']}</Typography>
        <Typography sx={styles.subtitle}>
          {language['Resume.title.subDescription']}
        </Typography>
      </Box>
      <Box sx={styles.image}>
        <img src="../../auth/image.png" alt="" style={{ width: "95%" }} />
      </Box>
    </Grid>
  );
};

export default AuthSidebar;
