import React, { useEffect, useState, useRef } from "react";
import { main } from "colors";
import ActionHeader, { ActionType } from "components/resume/actionHeader/ActionHeader";
import DisplaySchedule from "components/schedule/displaySchedule/DisplaySchedule";
import ScrollTopButton from "custom/buttons/scrollTopButton/ScrollTopButton";
import UploadFile from "custom/buttons/uploadButton/UploadFile";
import Loader from "custom/loader/Loader";
import { getAge, getDay } from "helpers/dateCalculation";
import { useLocationId } from "hooks/useLocationId";
import moment from "moment";
import QRcode from "qrcode";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { useHistory } from "react-router-dom";
import { useToastActions } from "redux/toast/useToastActions";

import Experience from "./components/Experience";
import InfoBlock from "./components/InfoBlock";
import OpenQRCode from "./components/OpenQRCode";
import { Box, Typography } from "@mui/material";
import PdfResume from "./PdfResume";
import { styles } from "./viewResume.styles";
import ResumeLayout from "layouts/ResumeLayout";
import { routes } from "../../routing/Routes";
import { ResumesTabID } from "pages/resumes/Resumes";
import ApiResumeService from "services/ApiResumeService";
import { KEY_LOCAL_STORAGE_RESUME } from "pages/createEditResume/CreateResume";
import { useResumeActions } from "redux/resume/useResumeActions";

export default function ViewResume() {
  let id = useLocationId();
  const { userId, accessToken } = useSelector((state) => state.profile);
  const [isLoadingResume, setIsLoadingResume] = useState(true);
  const [resume, setResume] = useState(null);
  const [code, setCode] = useState("");
  const [schedules, setSchedules] = useState([]);
  const [fileType, setFileType] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const language = useSelector((state) => state.footer.language);
  const languageCode = useSelector((state) => state.footer.languageCode);
  const history = useHistory();
  const [isDataSaving, setIsDataSaving] = useState(false); // show loading in ActionHeader
  const { showToast } = useToastActions();

  const { getNationality } = useResumeActions();
  const nationality = useSelector((state) => state.resumeDetail.nationality);
  const [ countryName, setCountryName ] = useState('');

  useEffect(() => {
    const getResumeDetail = async (resumeId) => {
      const result = await ApiResumeService.getResumeDetail(resumeId);
      if (result && result.success) {
        setResume(result.data);
      } else if (result && result.message) {
        showToast(result.message);
      }
      setIsLoadingResume(false);
  };
    getResumeDetail(id);

    if (!nationality || nationality.length == 0) {
      getNationality();
    }

    QRcode.toDataURL(document.location.href).then(setCode);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    resume && setSchedules(resume.workshift);
  }, [resume]);

  useEffect(() => {
    if (!resume || !resume.mainImage) return;
    setFileType(resume.mainImage.mimetype.split("/")[0]);
  }, [resume]);

  useEffect(() => {
    let countryList = [];
    if (nationality && nationality.length) {
      for (let i = 0; i < nationality.length; i++) {
        if (nationality[i].language && nationality[i].language === languageCode) {
          countryList = nationality[i].countries;
          break;
        }
      }
      // default: en
      if (countryList.length == 0) {
        for (let i = 0; i < nationality.length; i++) {
          if (nationality[i].language && nationality[i].language === 'en') {
            countryList = nationality[i].countries;
            break;
          }
        }
      }
    }
    
    let name = '';
    if (resume && resume.nationality && resume.nationality !== '' && countryList.length > 0) {
      for (let i = 0; i < countryList.length; i++) {
        if (countryList[i].value === resume.nationality) {
          name = countryList[i].label;
          break;
        }
      }
    }
    setCountryName(name);
  }, [nationality, languageCode, resume]);

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    copyStyles: true,
  });

  const saveToMyList = async() => {
    setIsDataSaving(true);
    const result = await ApiResumeService.saveToList(id);
    if (result && result.success) {
      history.push(routes().resumes + '?tab=' + ResumesTabID.otherResumes);
    } else {
      setIsDataSaving(false);
      if (result && result.message) {
        showToast(result.message);
      }
    }
  };

  return (
    <ResumeLayout
      headerProps={!accessToken ? {
        rightButtonTitle: language['Resume.page.createNew'],
        rightButtonClick: () => {
          localStorage.setItem(KEY_LOCAL_STORAGE_RESUME, '');
          history.push(routes().createResume);
        }
      } : null}
    >
      <section style={styles.section}>
        {isLoadingResume ? (
          <Box sx={{ minHeight: "1000px" }}>
            <Loader />
          </Box>
        ) : resume && (
          <>
            <Box
              sx={
                userId === resume.userId
                  ? { ...styles.block, justifyContent: "space-between" }
                  : { ...styles.block, justifyContent: "flex-end" }
              }
            >
              {userId === resume.userId && (
                <Typography>{resume.resumeTitle}</Typography>
              )}
              <ActionHeader
                type={ActionType.view}
                resume={resume}
                isShowLoading={isDataSaving}
                refToPrint={printRef}
                handlePrint={() => handlePrint()}
                saveToMyList={() => saveToMyList()}
              />
            </Box>

            <Box sx={styles.container}>
              <Box sx={styles.selfIntroduction}>
                {accessToken && (
                  <Typography sx={styles.resumeTitle}>
                    {resume.resumeTitle}
                  </Typography>
                )}
                <Box sx={styles.imageBox}>
                  <>
                    {(() => {
                      switch (fileType) {
                        case "video":
                          return (
                            <video
                              style={{
                                width: "inherit",
                                height: "100%",
                                objectFit: "cover",
                              }}
                              src={`${process.env.REACT_APP_MEDIA_STORAGE}/${resume.mainImage?.name}`}
                              controls
                            >
                              <track
                                src="captions_en.vtt"
                                kind="captions"
                                srcLang="en"
                                label="english_captions"
                              ></track>
                            </video>
                          );
                        case "image":
                          return (
                            <img
                              style={{
                                width: "inherit",
                                height: "inherit",
                                objectFit: "cover",
                              }}
                              src={`${process.env.REACT_APP_MEDIA_STORAGE}/${resume.mainImage?.name}`}
                              alt=""
                            />
                          );
                        default:
                          return null;
                      }
                    })()}
                  </>

                  <InfoBlock
                    type={ActionType.view}
                    resume={resume}
                    handlePrint={() => handlePrint()}
                    saveToMyList={() => saveToMyList()}
                  />
                </Box>

                {/*  */}
                <Box sx={styles.ownInfo}>
                  <Typography
                    sx={{
                      fontSize: "28px",
                      color: `${main}`,
                      width: "200px",
                    }}
                  >
                    {resume.surname} {resume.name}
                  </Typography>
                  <Typography>{resume.position}</Typography>
                </Box>
                {/*  */}
                <Box sx={styles.detailInfo}>
                  {languageCode === 'ja' ?
                    <Typography sx={{ ...styles.text, display: "flex" }}>
                      {resume.kanaSurname} {resume.kanaName}
                    </Typography>
                    : null 
                  }
                  {(countryName && countryName !== '') &&
                    <Typography sx={styles.text}>
                      {countryName}
                    </Typography>
                  }
                  <Typography sx={styles.text}>
                    {resume.gender === 'male' ? language['Resume.forms.gender.male'] : ''}
                    {resume.gender === 'female' ? language['Resume.forms.gender.female'] : ''}
                  </Typography>
                  {(resume.birthday && resume.birthday !== '') &&
                    <Box sx={{ display: "flex" }}>
                      <Typography sx={styles.text}>
                        {moment(resume.birthday).format("YYYY/DD/MM")}
                      </Typography>
                      <Typography sx={{ ...styles.text, marginLeft: "10px" }}>
                        ({getAge(resume.birthday)})
                      </Typography>
                    </Box>
                  }

                  <Typography sx={styles.text}>{resume.phone}</Typography>
                  <Typography sx={styles.text}>{resume.eMail}</Typography>
                  <Typography sx={styles.text}>{resume.address}</Typography>
                  {(resume.busStation && resume.busStation !== '') &&
                    <Typography sx={styles.text}>
                      {language['Resume.forms.nearestStation.view'].replace('{{busStation}}', resume.busStation)}
                    </Typography>
                  }
                  {(resume.transport && resume.transport !== '') &&
                    <Typography sx={styles.text}>
                      {language['Resume.forms.transports.view'].replace('{{transportation}}', resume.transport)}
                    </Typography>
                  }
                </Box>

                <Box sx={styles.qrBox} onClick={() => setIsOpen(true)}>
                  <img src={code} alt="" style={styles.qrCode} />
                </Box>
                <OpenQRCode
                  isOpen={isOpen}
                  code={code}
                  resume={resume}
                  setIsOpen={setIsOpen}
                />
              </Box>

              <Box sx={styles.fields}>
                {resume.motivation ? (
                  <>
                    <Typography sx={styles.title}>
                    {language['Resume.offer.motivationFor']}
                    </Typography>
                    <Typography sx={styles.textarea}>
                      {resume.motivation.split('\n').map((__t, __idx) => <span key={__idx}>{__idx > 0 ? <br/> : ''}{__t}</span>)}
                    </Typography>
                  </>
                ) : null}

                <Experience
                  data={resume.workHistory}
                  title={language['Resume.forms.workhistory']}
                  double={true}
                />

                <Experience data={resume.industries} title={language['Resume.offer.industory']} />

                <Experience
                  data={resume.experiences}
                  title={language['Resume.forms.skills']}
                />

                <Experience data={resume.languages} title={language['Resume.forms.languages']} />

                <Experience
                  data={resume.certifications}
                  title={language['Resume.forms.certifications']}
                />

                <Experience data={resume.awards} title={language['Resume.forms.awards']} />

                <Experience data={resume.schools} title={language['Resume.forms.schools']} />

                {resume.selfPromotion ? (
                  <>
                    <Typography sx={styles.title}>
                    {language['Resume.forms.selfPromotions']}
                    </Typography>
                    <textarea
                      value={resume.selfPromotion}
                      disabled
                      style={styles.textarea}
                    />
                  </>
                ) : null}

                <Experience
                  data={resume.skills}
                  title={language['Resume.forms.interestingSkills']}
                />
                <Experience
                  data={resume.interests}
                  title={language['Resume.forms.interestingIndustory']}
                />
                {(resume.uploadFiles && resume.uploadFiles?.length > 0) && (
                  <>
                    <UploadFile serverFiles={resume.uploadFiles} view={true} />
                  </>
                )}
                {(resume.whenStart && resume.whenStart !== '') &&
                  <Box>
                    <Typography sx={styles.title}>{language['Resume.forms.whenStart']}</Typography>
                    <Box sx={{ display: "flex" }}>
                      <Typography sx={{ fontWeight: 700 }}>
                        {moment(resume.whenStart).format("YYYY/DD/MM")}
                      </Typography>
                      <Typography sx={{ marginLeft: "10px" }}>
                        ({language[`Resume.forms.workShift.${getDay(resume.whenStart)}`]})
                      </Typography>
                    </Box>
                  </Box>
                }

                <Typography sx={{ ...styles.title, marginTop: "20px" }}>
                  {language['Resume.forms.workShift']}
                </Typography>
                <DisplaySchedule schedules={schedules} />
                
                <Box sx={styles.scrollButton}>
                  <ScrollTopButton />
                </Box>
              </Box>
            </Box>
          </>
        )}

        {resume && (
          <PdfResume
            resume={resume}
            ref={printRef}
            schedules={schedules}
            code={code}
          />
        )}
      </section>
    </ResumeLayout>
  );
}
