import { 
    UPDATE_I18N
} from "./fotter.types";

const initialState = {
    languageCode: 'ja',
    language: {},
};

export const footerReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_I18N:
            return {...state, language: payload.language, languageCode: payload.languageCode};
        default:
            return state;
    }
}
