import { main, text, white } from "colors";

export const styles = {
  section: {
    maxWidth: "480px",
    margin: "0 auto 60px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px 60px 60px",
    gap: "80px",
    background: "#FFFFFF",
    border: "1px solid #CCCCCC",
    "@media (max-width: 650px)": {
      maxWidth: "100%",
      margin: "0 16px 53px",
      padding: "40px",
    },
    "@media (max-width: 500px)": {
      maxWidth: "100%",
      margin: "0 16px 53px",
      padding: "32px 16px",
    },
  },
  title: {
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "23px",
    color: `${text}`,
    marginBottom: "32px",
  },
  caption: {
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "18px",
    color: `${text}`,
    fontFamily: "Hiragino Sans",
  },
  button: {
    width: "100%",
    background: `${main}`,
    color: `${white}`,
    borderRadius: "4px",
    fontFamily: "Hiragino Sans",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "21px",
    height: "36px",
  },
  input: {
    width: "calc(100% - 22px)",
    padding: "10px",
    background: "#FFFFFF",
    borderRadius: "2px",
    outlineColor: `${main}`,
  },
  box: {
    marginBottom: "32px",
  },
};
