export const styles = {
  cardsItem: {
    position: "relative",
    display: "flex",
    padding: "0.50rem 0rem",
    "@media (max-width: 425px)": {
      margin: "0px",
    },
  },
  columnFlexbox: {
    display: "flex",
    flexDirection: "column",
  },
  card: {
    position: "relative",
    width: "134px",
    height: "137px",
    backgroundColor: "#ffffff",
    borderRadius: "0.25rem",
    overflow: "hidden",
    justifyContent: "space-between",
    paddingTop: "26px",
    border: "1px solid #29CC8F",
    cursor: "pointer",
  },
  status: {
    position: "absolute",
    left: "0px",
    top: "6px",
    color: "#fff",
    fontSize: "9px",
    fontWeight: "700",
    padding: "3px 7px",
    textTransform: "uppercase",
    borderRadius: "0px 2px 2px 0px",
  },
  deleteButton: {
    cursor: "pointer",
    position: "absolute",
    right: "15px",
    top: "16px",
    zIndex: "3",
    width: "12px",
    height: "16px",
    color: "#F68C8D",
  },
  detail: {
    width: "100%",
    alignItems: "end",
  },
  resumeTitle: {
    fontSize: "14px",
    padding: "0 15px",
    fontWeight: "400",
    lineHeight: "14px",
  },
  actionBox: {
    padding: "8px 0",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "end",
    width: "100%",
  },
  actionButton: {
    width: "20px",
    height: "20px",
  },
  text: {
    fontSize: "9px",
    marginRight: "6px",
  },
};
