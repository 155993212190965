import { grey, main, text, white } from "colors";

export const styles = {
  section: {
    maxWidth: "1000px",
    margin: "0 auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    background: "#FFFFFF",
    border: "1px solid #CCCCCC",
    padding: "40px 80px",
    "@media (max-width: 1190px)": {
      maxWidth: "100%",
      margin: "0 30px",
      padding: "40px 50px",
    },
    "@media (max-width: 1090px)": {
      maxWidth: "100%",
      margin: "0 30px",
      padding: "40px 30px",
    },
    "@media (max-width: 1010px)": {
      flexDirection: "column",
      padding: "0px",
      border: "none",
      marginBottom: "53px",
    },
    "@media (max-width: 500px)": {
      marginBottom: "53px",
    },
  },
  container: {
    width: "50%",
    "@media (max-width: 1010px)": {
      width: "calc(100% - 64px)",
      marginBottom: "20px",
      border: "1px solid #ccc",
      padding: "32px",
    },
    "@media (max-width: 500px)": {
      width: "calc(100% - 32px)",
      padding: "32px 16px",
    },
  },
  leftContainer: {
    borderRight: "1px solid #ccc",
  },
  box: {
    width: "420px",
    "@media (max-width: 1010px)": {
      width: "100%",
      margin: "0",
    },
  },
  block: {
    position: "relative",
    marginBottom: "22px",
  },
  flexbox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "32px",
  },
  title: {
    fontFamily: "Roboto",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "23px",
    color: `${text}`,
    "@media (max-width: 500px)": {
      fontSize: "16px",
      lineHeight: "19px",
    },
  },
  caption: {
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#323232",
    fontFamily: "Hiragino Sans",
  },
  input: {
    width: "calc(100% - 22px)",
    padding: "10px",
    background: "#FFFFFF",
    border: `1px solid ${grey}`,
    borderRadius: "2px",
    outlineColor: `${main}`,
  },
  button: {
    borderRadius: "4px",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "14px",
    padding: "10px 16px",
    fontFamily: "Roboto",
  },
  cancelButton: {
    border: `1px solid ${main}`,
    color: `${main}`,
    marginRight: "12px",
  },
  saveButton: {
    background: `${main}`,
    color: `${white}`,
  },
  editButton: {
    width: "20px",
  },
};
