import React, { useState, useEffect } from "react";
import { main } from "colors";
import OtherResumeCard from "components/resume/resumeCard/otherCardResume/OtherResumeCard";
import OwnResumeCard from "components/resume/resumeCard/ownCardResume/OwnResumeCard";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Tabs, Tab, Box, Typography } from "@mui/material";
import { styles } from "./resumes.styles";
import CreateResumeButton from "../../custom/buttons/createResume/CreateResumeButton";
import { Utils } from "utils/utils";
import { routes } from "../../routing/Routes";
import MainLayout from "layouts/MainLayout";
import ApiResumeService from "services/ApiResumeService";
import { useToastActions } from "redux/toast/useToastActions";
import { KEY_LOCAL_STORAGE_RESUME } from "pages/createEditResume/CreateResume";

export const ResumesTabID = {
  myResumes: 'my_resumes',
  otherResumes: 'other_resumes'
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ marginTop: "27px" }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Resumes() {
  const [tabIndex, setTabIndex] = useState(Utils.gup('tab') === ResumesTabID.otherResumes ? 1 : 0);
  const [myResumes, setMyResumes] = useState([]);
  const [otherResumes, setOtherResumes] = useState([]);
  const language = useSelector((state) => state.footer.language);
  const user = useSelector((state) => state.profile.accessToken);
  const history = useHistory();
  const { showToast } = useToastActions();

  useEffect(() => {
    if (user) {
      handleOnChangeTab(null, tabIndex);
    }
  }, []);

  const handleOnChangeTab = (event, __tabIndex) => {
    if (__tabIndex != tabIndex) {
      setTabIndex(__tabIndex);
      if (__tabIndex == 0) {
        history.push(routes().resumes + '?tab=' + ResumesTabID.myResumes);
      } else {
        history.push(routes().resumes + '?tab=' + ResumesTabID.otherResumes);
      }
    }
    
    if (user) {
      if (__tabIndex == 0) {
        getMyResumeList();
      } else {
        getOtherResumeList();
      }
    }
  };

  const getMyResumeList = async () => {
    const result = await ApiResumeService.getAllOwn();
    if (result && result.success) {
      setMyResumes(result.data);
    } else if (result && result.message) {
      showToast(result.message);
    }
  };

  const getOtherResumeList = async () => {
    const result = await ApiResumeService.getAllOthers();
    if (result && result.success) {
      setOtherResumes(result.data);
    } else if (result && result.message) {
      showToast(result.message);
    }
  };

  return (
    <MainLayout 
      headerProps={{
        rightButtonTitle: language['Resume.page.createNew'],
        rightButtonClick: () => {
          localStorage.setItem(KEY_LOCAL_STORAGE_RESUME, '');
          history.push(routes().createResume);
        }
      }}
    >
      <Box sx={styles.container}>
        <Tabs
          textColor="primary"
          value={tabIndex}
          onChange={handleOnChangeTab}
          TabIndicatorProps={{
            style: { backgroundColor: `${main}` },
          }}
        >
          <Tab sx={styles.tab} label={language['Resume.list.me']} {...a11yProps(0)} />
          <Tab sx={styles.tab} label={language['Resume.list.others']} {...a11yProps(1)} />
        </Tabs>

        {/* tab My resume */}
        <TabPanel value={tabIndex} index={0}>
          <div className={((!myResumes || myResumes.length == 0)) ? 'flexrow wrap' : ''}>
            <div className="flexitem">
              <Box sx={Object.assign({}, styles.box, (!myResumes || myResumes.length == 0) ? styles.rimResumeListBox : {})}>
                <Box sx={styles.resumeList}>
                  <CreateResumeButton />

                  {myResumes &&
                    myResumes
                      .map((item, index) => {
                        return (
                          <OwnResumeCard
                            resume={item}
                            key={index}
                            onReloadResumeList={() => handleOnChangeTab(null, tabIndex)}
                          />
                        );
                  })}
                </Box>
              </Box>
            </div>
            
            {/* resume in minutes */}
            {((!myResumes || myResumes.length == 0)) &&
              <Box sx={styles.rimWrapper}>
                <Typography sx={styles.rimTitle}>
                  {language['Resume.resumeInMinutes']}
                </Typography>
                <Typography sx={styles.rimSubtitle}>
                  {language['Resume.title.subDescription']}
                </Typography>
                <Box sx={styles.rimImage}>
                  <img src="../../auth/image.png" alt="" style={{ width: "100%" }} />
                </Box>
              </Box>
            }
            </div>
        </TabPanel>

        {/* tab Other resume */}
        <TabPanel value={tabIndex} index={1}>
          <Box sx={styles.box}>
            {(!otherResumes || otherResumes.length === 0) ? (
              <Typography sx={styles.noneResume}>
                {language['Resume.list.others.see']}
              </Typography>
            ) : (
              <Box sx={styles.resumeList}>
                {otherResumes &&
                  otherResumes
                    .map((item, index) => {
                      return (
                        <OtherResumeCard
                          resume={item}
                          key={index}
                          onReloadResumeList={() => handleOnChangeTab(null, tabIndex)}
                        />
                      );
                    })}
              </Box>
            )}
          </Box>
        </TabPanel>
      </Box>
    </MainLayout>
  );
}
