import { combineReducers } from "redux";

import { authReducer } from "./auth/auth.reducer";
import { profileReducer } from "./profile/profile.reducer";
import { createResumeReducer } from "./resume/createResume/createResume.reducer";
import { removeResumeReducer } from "./resume/removeResume/removeResume.reducer";
import { resumeDetailReducer } from "./resume/resumeDetail/resumeDetail.reducer";
import { resumeListReducer } from "./resume/resumeList/resumeList.reducer";
import { updateResumeReducer } from "./resume/updateResume/updateResume.reducer";
import { toastReducer } from "./toast/toast.reducer";
import { footerReducer } from "./footer/fotter.reducer";

const rootReducer = combineReducers({
  toast: toastReducer,
  auth: authReducer,
  profile: profileReducer,
  resumeList: resumeListReducer,
  resumeDetail: resumeDetailReducer,
  createResume: createResumeReducer,
  updateResume: updateResumeReducer,
  removeResume: removeResumeReducer,
  footer: footerReducer,
});

export default rootReducer;
