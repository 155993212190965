import React, { useEffect, useState, useRef } from "react";
import * as styles from './styles';
import './styles.css';
import { useSelector } from "react-redux";
import { useToastActions } from "redux/toast/useToastActions";
import { Utils } from "utils/utils";
import MaterialIconClose from '@mui/icons-material/Close';
import MaterialIconFileDownload from '@mui/icons-material/FileDownload';
import MaterialIconNavLeft from '@mui/icons-material/ChevronLeft';
import MaterialIconNavRight from '@mui/icons-material/ChevronRight';
import MaterialIconRotateRight from '@mui/icons-material/RotateRight';
import MaterialIconZoomIn from '@mui/icons-material/ZoomIn';
import MaterialIconZoomout from '@mui/icons-material/ZoomOut';
import Loader from "custom/loader/Loader";
var EXIF = require('exif-js');

const ImageRotation = {
    r0: 0,
    r90: 6,
    r180: 3,
    r270: 8,
};

export default function ImageViewer({ urls, fileName, initialIndex, onClose }) {
    let _keyPressListener;
    let _windowResizeListener;
    let _proxyImage;
    let _isDragging = false;
    let _previousPosX = 0;
    let _previousPosY = 0;
    let _timerGetMetadata;

    let containerRef = useRef();
    let imgRef = useRef();
    let imgWrapperRef = useRef();
    
    const language = useSelector((state) => state.footer.language);
    const { showToast } = useToastActions();

    let [selectedIndex, setSelectedIndex] = useState(0);
    let [imageRotateDeg, setImageRotateDeg] = useState(0);
    let [imageOpacity, setImageOpacity] = useState(0);
    let [initialWidth, setInitialWidth] = useState(0);
    let [initialHeight, setInitialHeight] = useState(0);

    let [imgVisibleWidth, setImgVisibleWidth] = useState(window.innerWidth);
    let [imgVisibleHeight, setImgVisibleHeight] = useState(window.innerWidth);
    let [imgVisibleTop, setImgVisibleTop] = useState(0);
    let [imgVisibleLeft, setImgVisibleLeft] = useState(0);

	useEffect(() => {
        _proxyImage = new Image();
		_proxyImage.onload = (ev) => handleOnImageLoaded(ev);

        let selectedIndex = 0;
        if (!isNaN(initialIndex) && initialIndex >= 0) {
            selectedIndex = Math.min(initialIndex, (urls || []).length);
        }
        
        setSelectedIndex(selectedIndex);

        // add events
        registerEventListeners();

        show();

        // 👇️ remove the event listener when the component unmounts
        return () => {
            removeTimerGetMetadata();
            removeEventListeners();
            hide();
        };
    }, []);

    const registerEventListeners = () => {
        _keyPressListener = (e) => handleOnKeyPress(e);
        document.addEventListener("keydown", _keyPressListener);
        _windowResizeListener = () => calculateInitialSize();
        window.addEventListener("resize", _windowResizeListener);

        document.addEventListener('mousedown', (e) => handleOnImageMouseDown(e));
        document.addEventListener('touchstart', (e) => handleOnImageMouseDown(e));
        document.addEventListener('mouseup', () => handleOnImageMouseUp());
        document.addEventListener('mouseleave', () => handleOnImageMouseUp());
        document.addEventListener('touchend', () => handleOnImageMouseUp());
        document.addEventListener('mousemove', (e) => handleOnImageMouseMove(e));
        document.addEventListener('touchmove', (e) => handleOnImageMouseMove(e));
    };

    const removeEventListeners = () => {
        if (_keyPressListener) {
            document.removeEventListener("keydown", _keyPressListener);
            _keyPressListener = null;
        }
        if (_windowResizeListener) {
            window.removeEventListener("resize", _windowResizeListener);
            _windowResizeListener = null;
        }

        document.removeEventListener('mousedown', (e) => handleOnImageMouseDown(e));
            document.removeEventListener('touchstart', (e) => handleOnImageMouseDown(e));
            document.removeEventListener('mouseup', () => handleOnImageMouseUp());
            document.removeEventListener('mouseleave', () => handleOnImageMouseUp());
            document.removeEventListener('touchend', () => handleOnImageMouseUp());
            document.removeEventListener('mousemove', (e) => handleOnImageMouseMove(e));
            document.removeEventListener('touchmove', (e) => handleOnImageMouseMove(e));
    };

    const show = () => {
        const modalContainer = document.getElementById('abc-modal-container');
        if (!Utils.isSamsungBrowser() && modalContainer) {
            modalContainer.style.right = '0px';
            modalContainer.style.bottom = '0px';
            modalContainer.appendChild(containerRef);
        }

        calculateInitialSize();
        if (urls && urls.length == 1) {
            _proxyImage.src = urls[0];
        }
    };

    const hide = () => {
        const modalContainer = document.getElementById('abc-modal-container');
        if (!Utils.isSamsungBrowser() && modalContainer) {
            modalContainer.removeChild(containerRef);
            modalContainer.style.right = 'auto';
            modalContainer.style.bottom = 'auto';
        }
    };

    const removeTimerGetMetadata = () => {
        if (_timerGetMetadata) {
            clearTimeout(_timerGetMetadata);
            _timerGetMetadata = null;
        }
    };

    const fixOrientation = () => {
        removeTimerGetMetadata();
        if (imgRef) {
            _timerGetMetadata = setTimeout(() => {
                if (imgRef) {
                    if (imageOpacity == 0) {
                        setImageOpacity(1);
                    }
                }
            }, 5000); // 5 seconds

            try {
                EXIF.getData(imgRef.current, () => {
                    removeTimerGetMetadata();
                    if (imgRef) {
                        const imageOrientation = EXIF.getTag(imgRef.current, 'Orientation') || 0;
                        if (imgRef) {
                            let imageRotateDeg = 0;
                            if (imageOrientation == ImageRotation.r90) {
                                imageRotateDeg = 90;
                            } else if (imageOrientation == ImageRotation.r180) {
                                imageRotateDeg = 180;
                            } else if (imageOrientation == ImageRotation.r270) {
                                imageRotateDeg = 270;
                            }
                            setImageRotateDeg(imageRotateDeg);
                            setImageOpacity(1);
                        }
                    }
                });
            } catch (e) {
                removeTimerGetMetadata();
                if (imgRef) {
                    setImageOpacity(1);
                }
            }
        }
    };

    const calculateInitialSize = () => {
        const ele = imgWrapperRef?.current;
        if (ele) {
            setInitialWidth(ele.getBoundingClientRect().width);
            setInitialHeight(ele.getBoundingClientRect().height);
            setImgVisibleWidth(initialWidth == 0 ? ele.getBoundingClientRect().width : imgVisibleWidth);
            setImgVisibleHeight(initialWidth == 0 ? ele.getBoundingClientRect().height : imgVisibleHeight);
        }
    };

    const reset = () => {
        setImageRotateDeg(0);
        setImgVisibleWidth(initialWidth);
        setImgVisibleHeight(initialHeight);
    };

    const handleOnImageLoaded = (ev) => {
        if (ev.target && ev.target.naturalWidth && ev.target.naturalHeight) {
            // setImageSize({width: ev.target.naturalWidth, height: ev.target.naturalHeight});
        }
    }

    const handleOnImageError = () => {
        showToast(language['ImageViewer.failedToLoadFile']);
        if (!urls || urls.length <= 1) {
            handleOnCloseClick();
        }
    }

    const handleOnKeyPress = (e) => {
        if (!urls) {
            urls = [];
        }
        if ((e.key === 'Escape' || e.key === 'Esc') && e.keyCode == 27) {
            handleOnCloseClick();
        } else if (e.keyCode == 37) { // left
            if (urls.length == 1) {
                setImgVisibleLeft(imgVisibleLeft - 1);
                return;
            }
            handleOnClickPrevious();
        } else if (e.keyCode == 39) { // right
            if (urls.length == 1) {
                setImgVisibleLeft(imgVisibleLeft + 1);
                return;
            }
            handleOnClickNext();
        } else if (e.keyCode == 38) { // up
            if (urls.length == 1) {
                setImgVisibleTop(imgVisibleTop - 1);
                return;
            }
        } else if (e.keyCode == 40) { // down
            if (urls.length == 1) {
                setImgVisibleTop(imgVisibleTop + 1);
                return;
            }
        }
    };

    const handleOnClickPrevious = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (selectedIndex > 0) {
            setSelectedIndex(selectedIndex - 1);
            setImageOpacity(0);
            reset();
        }
    };

    const handleOnClickNext = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (selectedIndex < urls.length - 1) {
            setSelectedIndex(selectedIndex + 1);
            setImageOpacity(0);
            reset();
        }
    };
    
    const handleOnCloseClick = () => {
        if (onClose) {
            onClose();
        }
    };

    const handleOnDownloadClick = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        const url = urls[selectedIndex];

        if (Utils.isIOS()) {
            window.location.href = url;
            return;
        }

        if (!fileName || fileName === '') {
            for (let i = 0; i < url.split('/').length; i++) {
                const path = (url.split('/')[i] + '').toLowerCase();
                if (path.endsWith('.jpg') || path.endsWith('.png') || path.endsWith('.gif')) {
                    fileName = path;
                    break;
                }
            }
        }

        fetch(url)
        .then(response => response.blob())
        .then(blob => {
            const blobURL = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = blobURL;
            a.style = "display: none";
            a.download = fileName;
            a.target = "_blank";
            document.body.appendChild(a);
            a.click();
        })
        .catch(() => {});
    };

    const handleOnRotateClick = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        setImageRotateDeg(imageRotateDeg + 90);
    };

    const handleOnZoomClick = (e, isZoomIn=true) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        const zoomPercent = 20; // %
        const zoomWidthAmount = Math.round((initialWidth * zoomPercent) / 100) * (isZoomIn ? 1 : -1);
        const zoomHeightAmount = Math.round((initialHeight * zoomPercent) / 100) * (isZoomIn ? 1 : -1);
        let __imgVisibleWidth = imgVisibleWidth + zoomWidthAmount;
        let __imgVisibleHeight = imgVisibleHeight + zoomHeightAmount;
        
        if (__imgVisibleWidth / initialWidth >= 4 || initialWidth / __imgVisibleWidth >= 4) { // max zoom-in 4x
            return;
        }

        setImgVisibleWidth(__imgVisibleWidth);
        setImgVisibleHeight(__imgVisibleHeight);
        setImgVisibleTop(imgVisibleTop - (zoomHeightAmount/2));
        setImgVisibleLeft(imgVisibleLeft - (zoomWidthAmount/2));
    };

    const handleOnImageMouseDown = (e) => {
        _isDragging = true;
        if (e.target && e.touches && e.touches.length > 0) {
            _previousPosX = e.touches[0].clientX;
            _previousPosY = e.touches[0].clientY;
        } else {
            _previousPosX = e.clientX;
            _previousPosY = e.clientY;
        }
    };

    const handleOnImageMouseMove = (e) => {
        if (_isDragging) {
            let deltaX = 0;
            let deltaY = 0;
            
            if (e.touches && e.touches.length > 0) {
                deltaX = e.touches[0].clientX - _previousPosX;
                deltaY = e.touches[0].clientY - _previousPosY;
                _previousPosX = e.touches[0].clientX;
                _previousPosY = e.touches[0].clientY;
            } else {
                deltaX = e.clientX - _previousPosX;
                deltaY = e.clientY - _previousPosY;
                _previousPosX = e.clientX;
                _previousPosY = e.clientY;
            }
            setImgVisibleTop((pre) => pre + deltaY);
            setImgVisibleLeft((pre) => pre + deltaX);
        }
    };

    const handleOnImageMouseUp = () => {
        _isDragging = false;
    };

    // -------------------- render --------------------

    const renderPreviousButton = () => {
        if (selectedIndex > 0) {
            return (
                <div
                    style={styles.leftBar} className="flexrow ver-center hoz-center"
                >
                    <div style={styles.navButton} className="flexrow ver-center"
                        onClick={(e) => handleOnClickPrevious(e)}
                    >
                        <MaterialIconNavLeft 
                            style={{width: '24px', height: '24px', color: '#000'}}
                        />
                    </div>
                </div>
            );
        }
        
    };

    const renderNextButton = () => {
        if (selectedIndex < urls.length - 1) {
            return (
                <div
                    style={styles.rightBar} className="flexrow ver-center hoz-center"
                >
                    <div style={styles.navButton} className="flexrow ver-center"
                        onClick={(e) => handleOnClickNext(e)}
                    >
                        <MaterialIconNavRight 
                            style={{width: '24px', height: '24px', color: '#000'}}
                        />
                    </div>
                </div>
            );
        }
    };

    let imageStyle = {
        backgroundImage: "url('" + urls[selectedIndex] + "')",
        opacity: imageOpacity,
        width: imgVisibleWidth + 'px',
        height: imgVisibleHeight + 'px',
        top: imgVisibleTop + 'px',
        left: imgVisibleLeft+ 'px',
    };
    imageStyle.transform = 'rotate(' + imageRotateDeg +'deg)';
    
    return (
        <div>
            <img ref={imgRef} style={styles.hiddenImage} alt=""
                key={selectedIndex}
                src={urls[selectedIndex]}
                onLoad={() => fixOrientation()}
                onError={() => handleOnImageError()}
            />
            <div ref={containerRef} style={styles.overlay} className="ImageViewer-200226 flexrow hoz-center ver-center">
                <div className="imgview-img-wrapper flexitem self-stretch flexrow hoz-center ver-center">
                    <div ref={imgWrapperRef} className="flexitem self-stretch"
                    >
                        <div id="imgview-img-tag-230814"
                            className="imgview-img-tag" 
                            style={imageStyle}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                        </div>
                    </div>
                </div>
                {renderPreviousButton()}
                {renderNextButton()}
                <div className="imgview-topright-buttons flexrow ver-center">
                    <div
                        style={styles.topRightButton} className="flexrow ver-center"
                        onClick={(e) => handleOnZoomClick(e, true)}
                    >
                        <MaterialIconZoomIn style={{width: '24px', height: '24px', color: '#000'}} />
                    </div>
                    <div
                        style={styles.topRightButton} className="flexrow ver-center"
                        onClick={(e) => handleOnZoomClick(e, false)}
                    >
                        <MaterialIconZoomout style={{width: '24px', height: '24px', color: '#000'}} />
                    </div>
                    <div
                        style={styles.topRightButton} className="flexrow ver-center"
                        onClick={(e) => handleOnRotateClick(e)}
                    >
                        <MaterialIconRotateRight style={{width: '24px', height: '24px', color: '#000'}} />
                    </div>
                    <div
                        style={styles.topRightButton} className="flexrow ver-center"
                        onClick={(e) => handleOnDownloadClick(e)}
                    >
                        <MaterialIconFileDownload style={{width: '24px', height: '24px', color: '#000'}} />
                    </div>
                    <div
                        style={styles.topRightButton} className="flexrow ver-center"
                        onClick={() => handleOnCloseClick()}
                    >
                        <MaterialIconClose style={{width: '24px', height: '24px', color: '#000'}} />
                    </div>
                </div>
                {imageOpacity == 0 && 
                    <div className="imgview-loading flexrow ver-center hoz-center">
                        <Loader />
                    </div>
                }
            </div>
        </div>
    );
}
