import { grey, white } from "colors";

export const styles = {
  actionBox: {},
  fiberIcon: {
    fontSize: "5px",
    color: `${grey}`,
  },
  menu: {
    position: "absolute",
    width: "150px",
    height: "102px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    textAlign: "left",
    background: `${white}`,
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    zIndex: "4",
  },
  itemMenu: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "15px",
  },
  menuImage: {
    width: "20px",
    height: "16px",
    marginRight: "10px",
  },
};
