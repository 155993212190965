import { CREATE_RESUME_SUCCESS } from "../createResume/createResume.types";
import {
  REMOVE_SELECTED_EDITED_RESUME_SUCCESS,
  REMOVE_SELECTED_OTHER_RESUME_SUCCESS,
  REMOVE_SELECTED_PUBLISHED_RESUME_SUCCESS,
  REMOVE_SELECTED_SAVED_RESUME_SUCCESS,
} from "../removeResume/removeResume.types";
import { SAVE_TO_MY_LIST_SUCCESS } from "../resumeDetail/resumeDetail.types";
import {
  UPDATE_PUBLISHED_RESUME_SUCCESS,
  UPDATE_RESUME_SUCCESS,
} from "../updateResume/updateResume.types";
import {
  CLONE_SELECTED_RESUME,
  CLONE_SELECTED_RESUME_ERROR,
  CLONE_SELECTED_RESUME_SUCCESS,
  GET_OTHER_RESUME_LIST,
  GET_OTHER_RESUME_LIST_SUCCESS,
  GET_OWN_RESUMES_LIST,
  GET_OWN_RESUMES_LIST_SUCCESS,
} from "./resumeList.types";

const initialState = {
  resumes: [],
  othersResumes: [],
  loading: false,
};

export const resumeListReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_OWN_RESUMES_LIST:
      return { ...state, loading: true };
    case GET_OWN_RESUMES_LIST_SUCCESS:
      return { ...state, resumes: (payload || []).filter((__r) => __r !== null), loading: false };

    case GET_OTHER_RESUME_LIST:
      return { ...state, loading: true };
    case GET_OTHER_RESUME_LIST_SUCCESS:
      return { ...state, othersResumes: (payload || []).filter((__r) => __r !== null), loading: false };

    case CREATE_RESUME_SUCCESS:
      return {
        ...state,
        resumes: [...state.resumes, payload],
        loading: false,
      };

    case UPDATE_PUBLISHED_RESUME_SUCCESS:
    case UPDATE_RESUME_SUCCESS: {
      const findResume = state.resumes.findIndex(
        (el) => el._id === payload.resumeId
      );
      
      if (findResume < 0) {
        return {
          ...state,
          resumes: [...state.resumes, payload.data],
          loading: false,
        };
      }

      const editedResumes = state.resumes.slice(0);
      editedResumes[findResume] = payload.data;

      return { ...state, resumes: editedResumes, loading: false };
    }
    case SAVE_TO_MY_LIST_SUCCESS:
      return {
        ...state,
        othersResumes: [...state.othersResumes, payload],
        loading: false,
      };

    case REMOVE_SELECTED_PUBLISHED_RESUME_SUCCESS:
      return {
        ...state,
        resumes: [...state.resumes.filter((resume) => resume._id !== payload)],
        loading: false,
      };

    case REMOVE_SELECTED_SAVED_RESUME_SUCCESS:
      return {
        ...state,
        resumes: [...state.resumes.filter((resume) => resume._id !== payload)],
        loading: false,
      };

    case REMOVE_SELECTED_OTHER_RESUME_SUCCESS:
      return {
        ...state,
        othersResumes: [
          ...state.othersResumes.filter((resume) => resume._id !== payload),
        ],
        loading: false,
      };

    case REMOVE_SELECTED_EDITED_RESUME_SUCCESS:
      return {
        ...state,
        resumes: [...state.resumes.filter((resume) => resume._id !== payload)],
        loading: false,
      };

    case CLONE_SELECTED_RESUME:
      return { ...state, loading: true };
    case CLONE_SELECTED_RESUME_SUCCESS:
      return {
        ...state,
        resumes: [...state.resumes, payload],
      };
    case CLONE_SELECTED_RESUME_ERROR:
      return { ...state, loading: false };

    default:
      return state;
  }
};
