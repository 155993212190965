// import axios from "axios";

import {
    UPDATE_I18N,
} from "./fotter.types";

export const updateI18n = (languageCode) => {
    return async (dispatch) => {
        /*
        try {
            const url = `${process.env.REACT_APP_I18N_URL}`;
            const languagefile = `${languageCode}.json`
            const result = await axios(url + languagefile);
            const obj = result.data;
            const object = {
                languageCode: languageCode,
                language: obj,
            }
            dispatch(updateI18nInit(object));
            localStorage.setItem("languageCode", languageCode);
        } catch (error) {
            */
            var obj = ja;
            switch (languageCode) {
                case 'en':
                    obj = en;
                    break;
                default:
                    obj = ja;
                    break;
            }
            const object = {
                languageCode: languageCode,
                language: obj
            }
            dispatch(updateI18nInit(object));
        // }
    }
}

export const updateI18nInit = (payload) => {
    return { type: UPDATE_I18N, payload};
}

const ja = {
    "dateFormat": "YYYY年MM月DD日",
    "Resume.title": "バイト履歴書",
    "Resume.title.subDescription": "履歴書の作成、PDFをダウンロードしたり、リンクを送信することができます。",
    "Resume.resumeInMinutes": "ネット上の履歴書",
    "Resume.welcome": "ようこそ",
    "Resume.welcome.Back": "おかえりなさい",
    "Resume.welcome.letsAccept": "始めましょう",
    "Resume.settings.privacy.requirement": "このプライバシーポリシーの最終更新日：YYYY/MM/DD<br/>以下の文書を確認してください。同意する場合は、「同意して次へ」ボタンを押して続けてください。<br/><br/>同意しない場合は、このサービスを使用できません。",
    "Resume.settings.privacy.accept": "同意する進む",
    "Settings.agreement.disagree": "同意しない",
    "Resume.actions.yes": "はい",
    "Resume.actions.no": "いいえ",
    "Resume.nav.resumeList": "履歴書",
    "Resume.nav.settings": "設定",
    "Resume.nav.help": "ヘルプ",
    "Resume.contact.title": "連絡する",
    "Resume.contact.name": "お名前",
    "Resume.contact.subject": "件名",
    "Resume.contact.email": "メールアドレス",
    "Resume.contact.message": "内容",
    "Resume.contact.sendMessage": "メールの送信",
    "Resume.contact.submit": "送信",
    "Resume.nav.createNew": "履歴書の作成",
    "Resume.nav.logout": "ログアウト",
    "Resume.footer.japanese": "日本語",
    "Resume.footer.english": "English",
    "Resume.footer.company": "ワークスタイルテック株式会社",
    "Resume.footer.termsOfService" : "利用規約",
    "Resume.footer.privacyPolicy" : "プライバシーポリシー",
    "Authen.password": "パスワード",
    "Authen.currentPassword": "現在のパスワード",
    "Authen.newPassword": "新しいパスワード",
    "Authen.confirmNewPassword": "新しいパスワードの確認",
    "Authen.updatePassword": "パスワードの更新",
    "Authen.forgotPassword": "パスワードをお忘れですか？",
    "Authen.enterEmail": "メールを入力してください。新しいパスワードをお送りします。",
    "Authen.isSignuped.need": "アカウント持っていませんか？",
    "Authen.Login": "ログイン",
    "Authen.LoginFor": "ログインする",
    "Authen.Signup": "登録する",
    "Authen.Login.Twitter": "Twitterでログイン",
    "Authen.Login.loadingByTwitter": "Twitterでログイン中",
    "Authen.Login.LINE": "LINEでログイン",
    "Authen.Login.loadingByLINE": "LINEでログイン中",
    "Authen.Login.RememberMe": "ログイン情報を保存する",
    "Authen.isSignuped.already": "アカウント持っている方はログインする",
    "Authen.loginPage.createResumeApp": "新しいアカウントを作成",
    "Authen.resetPassword.sendNewPassword": "メールを確認してログインを確認してください。",
    "HR.initialError.hayamaru": "おっと、ハヤマルくんが混乱しています。",
    "HR.initialError.lostPage": "お探しのページが見つかりません。",
    "Resume.list.me": "自分の履歴書",
    "Resume.list.others": "他の人の履歴書",
    "Resume.page.createNew": "新しい履歴書を作成",
    "Resume.page.lastEditDate": "最終編集日：",
    "Resume.page.createDate": "作成日：",
    "Resume.page.lastSaveDate": "保存日：YYYY/MM/DD",
    "Resume.page.published": "公開されました",
    "Resume.page.editing": "編集中",
    "Resume.page.publish": "公開する",
    "Resume.page.unpublish": "非公開",
    "Resume.list.others.see": "保存した他の人の履歴書はここで見ることができます。",
    "Resume.page.coping": "履歴書ABCDをコピー中…",
    "Resume.page.copedTitle": "作成された「履歴書ABCDのコピー」",
    "Resume.page.delete": "履歴書を削除",
    "Resume.page.confirmDelete": "を削除すると復元できませんが、削除しますか？",
    "Resume.page.deleted": "「履歴書ABCDのコピー」が削除されました",
    "Resume.forms.lastName": "姓",
    "Resume.forms.firstName": "名",
    "Resume.forms.lastNameKana": "姓（カナ）",
    "Resume.forms.firstNameKana": "名（カナ）",
    "Resume.forms.lastNameKanaEx": "Ex : タナカ",
    "Resume.forms.firstNameKanaEx": "Ex : タロウ",
    "Resume.forms.positions": "職場でのポジション",
    "Resume.forms.positionsEx": "例 : フロワースタッフ",
    "Resume.forms.nationality": "国籍",
    "Resume.forms.gender": "性別",
    "Resume.forms.gender.male": "男性",
    "Resume.forms.gender.female": "女性",
    "Resume.forms.birthday": "生年月日",
    "Resume.forms.phoneNumber": "携帯電話番号",
    "Resume.forms.phoneNumberEX": "Ex : 080-0000-0000",  
    "Resume.forms.email": "メールアドレス",
    "Resume.forms.emailEx": "Ex : name@example.com",
    "Resume.forms.address": "住所",
    "Resume.forms.nearestStation": "最寄りの駅/バス停",
    "Resume.forms.nearestStation.view": "最寄り {{busStation}} / バス停",
    "Resume.forms.transports": "交通機関",
    "Resume.forms.transports.view": "通勤手段 {{transportation}}",
    "Resume.offer.motivationFor": "この仕事に応募する動機",
    "Resume.offer.motivation": "動機",
    "Resume.offer.reason": "私が応募した理由は、",
    "Resume.offer.placeholder": "+ ここに記入",
    "Resume.forms.workhistory": "職歴",
    "Resume.forms.workhistory.where": "働いた職場",
    "Resume.forms.workhistory.howLong": "働いた期間",
    "Resume.offer.industory": "業界",
    "Resume.forms.skills": "経験/スキル",
    "Resume.forms.languages": "言語",
    "Resume.forms.certifications": "資格",
    "Resume.forms.awards": "受賞歴",
    "Resume.forms.schools": "学歴",
    "Resume.forms.selfPromotions": "自己PR/追加情報",
    "Resume.forms.selfPromotionsFor": "私がこの仕事に適しているのは、",
    "Resume.forms.interestingSkills": "学びたいスキル",
    "Resume.forms.interestingIndustory": "興味のある業界",
    "Resume.forms.files": "ファイル:",
    "Resume.forms.files.description": "３つのファイルをアップロードできます。 形式：.jpg、 .doc、 .pdf",
    "Resume.forms.uploadFiles": "ファイルのアップロード",
    "Resume.forms.whenStart": "いつから始められますか？",
    "Resume.forms.workShift": "望ましい勤務シフト",
    "Resume.forms.workShift.monday": "月曜日",
    "Resume.forms.workShift.tuesday": "火曜日",
    "Resume.forms.workShift.wednesday": "水曜日",
    "Resume.forms.workShift.thursday": "木曜日",
    "Resume.forms.workShift.friday": "金曜日",
    "Resume.forms.workShift.saturday": "土曜日",
    "Resume.forms.workShift.sunday": "日曜日",
    "Resume.forms.workShift.mon": "月",
    "Resume.forms.workShift.tue": "火",
    "Resume.forms.workShift.wed": "水",
    "Resume.forms.workShift.thu": "木",
    "Resume.forms.workShift.fri": "金",
    "Resume.forms.workShift.sat": "土",
    "Resume.forms.workShift.sun": "日",
    "Resume.forms.saveChanges": "変更を保存",
    "Resume.forms.postMovie": "画像または1分以内の自己紹介動画",
    "Resume.forms.saved.text": "履歴書をリストに保存しました。",
    "Resume.forms.edit": "編集",
    "Resume.forms.saveToList": "リストに追加",
    "Resume.forms.inYourList": "リストを見る",
    "Resume.forms.downloadPDF": "PDFをダウンロード",
    "Resume.forms.share": "共有",
    "Resume.list.others.canSeeList": "「他の人の履歴書リスト」ページへ行くと、この履歴書やすでに保存済みの他の人の履歴書を見ることができます。",
    "Resume.list.see": "リストを見る",
    "Resume.settings.sentMessage.text": "あなたのメッセージは当社チームに送信済みです",
    "Resume.settings.waitReply.text": "Xをご利用いただき、ありがとうございます。なるべく早急にご連絡させていただきます。",
    "Resume.settings.backHome": "ホームに戻る",
    "Resume.settings.notExistEmail": "このメールアドレスのアカウントが見つかりません。",
    "Resume.settings.unCorrectEmailOrPassword": "誤ったメールまたはパスワード",
    "Resume.forms.saved": "リストに保存",
    "Resume.showToast.notCorrectEmail": "メールの形式が正しくありません",
    "Resume.showToast.needFillField": "必須の項目を埋めてください",
    "Resume.showToast.tooShortPassword": "パスワードが短すぎます",
    "Resume.showToast.NotCopyOfResume": "コピーした履歴書は存在しません",
    "Resume.showToast.NeedPublishedForDownloading": "PDFをダウンロードするには履歴書を発行してください",
    "Resume.showToast.LinkCopied": "リンクをコピーしました",
    "Resume.showToast.FileTooLarge": "ファイルの容量が大きすぎます",
    "Resume.showToast.maxImageSize": "{{number}}MB以下のサイズの画像をアップロードしてください",
    "Resume.showToast.fillWorkshift": "勤務シフトを埋めてください",
    "Resume.showToast.fillHighlightedField": "ハイライトした項目を埋めてください",
    "Resume.showToast.newEmailSaved": "新しいメールアドレスが保存されました",
    "Resume.showToast.newPasswordSaved": "新しいパスワードが保存されました",
    "Resume.showToast.cannotEmptyField": "項目は空白にはできません",
    "Resume.showToast.writeCorrectEmail": "正しいメールアドレスを入力してください",
    "Resume.showToast.needGreaterThan8ch": "８文字以上が必要です",
    "Resume.showToast.needSamePassword": "同じパスワードを入力してください",
    "Resume.showToast.sendQuestiongEmail": "メールが送信されました",
    "Resume.unpublish.confirmMessage": "公開を解除すると、プロフィールを保存していない人はオンライン履歴書にアクセスできなくなります。\n続行しますか？",
    "Resume.unpublish.successfull": "Unpublished",
    "Resume.publish.successfull": "Published",
    "Resume.save.successfull": "保存されました",
    "Resume.remove.successfull": "削除完了しました。",
    "Resume.copy.successfull": "コピーされました",
    "Resume.file.view": "表示",
    "Resume.file.change": "更新",
    "Resume.file.delete": "削除",
    "Resume.loginToSaveResume": "保存するために<a id='login'>ログイン</a>か<a id='signup'>アカウント登録</a>",
    "ImageViewer.failedToLoadFile": "ファイルの読み込みに失敗しました",
    "Upload.fileTypeNotAllowed": "ファイルの形式に問題が見つかりました"
}

const en = {
    "dateFormat": "MM-DD-YYYY",
    "Resume.title": "Part-time Resume",
    "Resume.title.subDescription": "Create your own resume, download the PDF or send link to your employer.",
    "Resume.resumeInMinutes": "Resume in Minutes",
    "Resume.welcome": "Welcome",
    "Resume.welcome.Back": "Welcome Back",
    "Resume.welcome.letsAccept": "Get yourself started",
    "Resume.settings.privacy.requirement": "This Privacy Policy was last updated on: YYYY/MM/DD.<br/>Please confirm the following document and if you <br/>agree, please press ‘Agree and Next’ button to continue.<br/><br/>If you don’t agree, you won’t be able to use this service.",
    "Resume.settings.privacy.accept": "Agree and next",
    "Resume.actions.yes": "Yes",
    "Resume.actions.no": "No",
    "Resume.nav.resumeList": "Resume List",
    "Resume.nav.settings": "Settings",
    "Resume.nav.help": "Help",
    "Resume.contact.title": "Contact Us",
    "Resume.contact.name": "Name",
    "Resume.contact.subject": "Subject",
    "Resume.contact.email": "Email",
    "Resume.contact.message": "Message",
    "Resume.contact.sendMessage": "Send message",
    "Resume.contact.submit": "Send",
    "Resume.nav.createNew": "Create New Resume",
    "Resume.nav.logout": "Logout",
    "Resume.footer.japanese": "Japanese",
    "Resume.footer.english": "English",
    "Resume.footer.company": "WorkStyleTech Co.,Ltd",
    "Resume.footer.termsOfService" : "Terms of Service",
    "Resume.footer.privacyPolicy" : "Privacy Policy",
    "Settings.agreement.disagree": "Disagree",
    "Authen.password": "Password",
    "Authen.currentPassword": "Current password",
    "Authen.newPassword": "New password", 
    "Authen.confirmNewPassword": "Confirm new password",
    "Authen.updatePassword": "パスワードの更新",
    "Authen.forgotPassword": "Forgot your Password?",
    "Authen.enterEmail": "Enter your email and we’ll send you a new password.",
    "Authen.isSignuped.need": "Don’t have an account?",
    "Authen.Login": "Login",
    "Authen.LoginFor": "Login to your account",
    "Authen.Signup": "Sign Up",
    "Authen.Login.Twitter": "Login with Twitter",
    "Authen.Login.loadingByTwitter": "loging in with Twitter",
    "Authen.Login.LINE": "Login with LINE",
    "Authen.Login.loadingByLINE": "loging in with LINE",
    "Authen.Login.RememberMe": "Remember me",
    "Authen.isSignuped.already": "Already have an account?",
    "Authen.loginPage.createResumeApp": "Create New Account",
    "Authen.resetPassword.sendNewPassword": "Please check your email for detail instructions.",
    "HR.initialError.hayamaru": "Whoops, Hayamaru-kun is confused.",
    "HR.initialError.lostPage": "We can’t find the page you’re looking for.",
    "Resume.list.me": "My Resume",
    "Resume.list.others": "Other's Resume",
    "Resume.page.createNew": "Create New Resume",
    "Resume.page.lastEditDate": "Last Edit:",
    "Resume.page.createDate": "Created:",
    "Resume.page.lastSaveDate": "Saved:",
    "Resume.page.published": "PUBLISHED",
    "Resume.page.editing": "EDITING",
    "Resume.page.publish": "Publish",
    "Resume.page.unpublish": "Unpublish",
    "Resume.list.others.see": "Other user resume that you saved can be accessed here.",
    "Resume.page.coping": "Copying Resume ABCD...",
    "Resume.page.copedTitle": "Created “Copy of Resume ABCD”",
    "Resume.page.delete": "Delete resume",
    "Resume.page.confirmDelete": "will be permanently deleted.",
    "Resume.page.deleted": "“Copy of Resume ABCD” deleted",
    "Resume.forms.lastName": "Last name",
    "Resume.forms.firstName": "First name",
    "Resume.forms.lastNameKana": "Last name Kana",
    "Resume.forms.firstNameKana": "First name Kana",
    "Resume.forms.lastNameKanaEx": "Ex : Tanaka",
    "Resume.forms.firstNameKanaEx": "Ex : Taro",
    "Resume.forms.positions": "Position at Work",
    "Resume.forms.positionsEx": "Ex : Floor Staff",
    "Resume.forms.nationality": "Nationality",
    "Resume.forms.gender": "Gender",
    "Resume.forms.gender.male": "Male",
    "Resume.forms.gender.female": "Female",
    "Resume.forms.birthday": "Birthday",
    "Resume.forms.phoneNumber": "Phone Number",
    "Resume.forms.phoneNumberEX": "Ex : 080-0000-0000",
    "Resume.forms.email": "E-mail",
    "Resume.forms.emailEx": "Ex : name@example.com",
    "Resume.forms.address": "Address",
    "Resume.forms.nearestStation": "Closest Train/Bus Station",
    "Resume.forms.nearestStation.view": "Close to {{busStation}} / Bus Stop",
    "Resume.forms.transports": "Transportation",
    "Resume.forms.transports.view": "Transport by {{transportation}}",
    "Resume.offer.motivationFor": "Motivation for this Job",
    "Resume.offer.motivation": "Motivation",
    "Resume.offer.reason": "I applied because",
    "Resume.offer.placeholder": "+ Write here",
    "Resume.forms.workhistory": "Work History",
    "Resume.forms.workhistory.where": "Where did you work?",
    "Resume.forms.workhistory.howLong": "How long did you work?",
    "Resume.offer.industory": "Industry",
    "Resume.forms.skills": "Experience / Skills",
    "Resume.forms.languages": "Languages",
    "Resume.forms.certifications": "Certifications",
    "Resume.forms.awards": "Awards",
    "Resume.forms.schools": "School",
    "Resume.forms.selfPromotions": "Self-Promotion / Extra Info",
    "Resume.forms.selfPromotionsFor": "Why I’m the best fit for this job...",
    "Resume.forms.interestingSkills": "Skills I want to Learn",
    "Resume.forms.interestingIndustory": "Industries I am Interested in",
    "Resume.forms.files": "Files:",
    "Resume.forms.files.description": "You can upload up to 3 files with .jpg , .doc or .pdf formats.",
    "Resume.forms.uploadFiles": "Upload your file",
    "Resume.forms.whenStart": "When can I start?",
    "Resume.forms.workShift": "Desirable Workshift",
    "Resume.forms.workShift.monday": "Monday",
    "Resume.forms.workShift.tuesday": "Tuesday",
    "Resume.forms.workShift.wednesday": "Wednesday",
    "Resume.forms.workShift.thursday": "Thursday",
    "Resume.forms.workShift.friday": "Friday",
    "Resume.forms.workShift.saturday": "Saturday",
    "Resume.forms.workShift.sunday": "Sunday",
    "Resume.forms.workShift.mon": "Mon",
    "Resume.forms.workShift.tue": "Tue",
    "Resume.forms.workShift.wed": "Wed",
    "Resume.forms.workShift.thu": "Thu",
    "Resume.forms.workShift.fri": "Fri",
    "Resume.forms.workShift.sat": "Sat",
    "Resume.forms.workShift.sun": "Sun",
    "Resume.forms.saveChanges": "Save Changes",
    "Resume.forms.postMovie": "Image or up to 1min Video with self introduction",
    "Resume.forms.saved.text": "saved this resume to your list",
    "Resume.forms.edit": "Edit",
    "Resume.forms.saveToList": "Save to list",
    "Resume.forms.inYourList": "See in your list",
    "Resume.forms.downloadPDF": "Download PDF",
    "Resume.forms.share": "Share",
    "Resume.list.others.canSeeList": "You can see this resume and other resume you have already saved by accessing the Other’s Resume List page.",
    "Resume.list.see": "See in my list",
    "Resume.settings.sentMessage.text": "Your message has been sent to our team",
    "Resume.settings.waitReply.text": "Thank you for using X. We will be contacting you as soon as possible.",
    "Resume.settings.backHome": "Return to home",
    "Resume.settings.notExistEmail": "We cannot find account with this email address.",
    "Resume.settings.unCorrectEmailOrPassword": "Wrong e-mail or password",
    "Resume.forms.saved": "Save to your List",
    "Resume.showToast.notCorrectEmail": "This Email is not correct",
    "Resume.showToast.needFillField": "Please fill the field",
    "Resume.showToast.tooShortPassword": "Password is too short",
    "Resume.showToast.NotCopyOfResume": "Copy of this resume may not exist.",
    "Resume.showToast.NeedPublishedForDownloading": "For downloading the pdf, make the resume published",
    "Resume.showToast.LinkCopied": "Link Copied",
    "Resume.showToast.FileTooLarge": "File size is too large",
    "Resume.showToast.maxImageSize": "Image size should not be greater than {{number}}MB.",
    "Resume.showToast.fillWorkshift": "Please fill the workshift",
    "Resume.showToast.fillHighlightedField": "Please fill the highlighted fields",
    "Resume.showToast.newEmailSaved": "New email address saved",
    "Resume.showToast.newPasswordSaved": "Password has been changed",
    "Resume.showToast.cannotEmptyField": "Field cannot be empty",
    "Resume.showToast.writeCorrectEmail": "Write correct email",
    "Resume.showToast.needGreaterThan8ch": "Field cannot be less than 8 characters",
    "Resume.showToast.needSamePassword": "Passwords should be the same",
    "Resume.showToast.sendQuestiongEmail": "Email has been sending",
    "Resume.unpublish.confirmMessage": "If you unpublish, people who have not saved your profile will not be able to access your Online Resume.\nDo you wish to continue?",
    "Resume.unpublish.successfull": "Unpublished",
    "Resume.publish.successfull": "Published",
    "Resume.save.successfull": "Saved",
    "Resume.remove.successfull": "Deleted",
    "Resume.copy.successfull": "Copied",
    "Resume.file.view": "View",
    "Resume.file.change": "Change",
    "Resume.file.delete": "Delete",
    "Resume.loginToSaveResume": "<a id='login'>Login</a> or <a id='signup'>Sign up</a> to Save Resume",
    "ImageViewer.failedToLoadFile": "Failed to load file",
    "Upload.fileTypeNotAllowed": "File type not allowed"
  }