import { text } from "colors";

export const styles = {
  container: {
    background: "#FCFCFC",
    color: `${text}`,
    padding: "20px 0",
    "@media (max-width: 1120px)": {
      padding: "12px 0",
    },
  },

  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 auto",
    width: "90%",
    position: "relative",
    flexWrap: "wrap",
    gap: '0px 10px',
  },

  item: {
    fontSize: '12px',
  },

  languageWrapper: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
  },

  languageCountryName: {
    fontSize: '12px',
    margin: '0px 6px'
  },

  copyright: {
    fontFamily: "Arial",
    fontSize: "10px",
    "@media (max-width: 1120px)": {
      width: "100%",
      marginTop: "5px",
      textAlign: "center",
      fontSize: "10px",
    },
  }
};
