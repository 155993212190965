import {
  UPDATE_PUBLISHED_RESUME,
  UPDATE_PUBLISHED_RESUME_ERROR,
  UPDATE_RESUME,
  UPDATE_RESUME_ERROR,
} from "./updateResume.types";

const initialState = {
  loading: false,
};

export const updateResumeReducer = (state = initialState, { type }) => {
  switch (type) {
    case UPDATE_RESUME:
      return { ...state, loading: true };

    case UPDATE_RESUME_ERROR:
      return { ...state, loading: false };

    case UPDATE_PUBLISHED_RESUME:
      return { ...state, loading: true };

    case UPDATE_PUBLISHED_RESUME_ERROR:
      return { ...state, loading: false };

    default:
      return state;
  }
};
