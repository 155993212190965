import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useAuthActions } from "redux/auth/useAuthActions";

import { Box, Typography } from "@mui/material";
import { desktop, desktopUnauthor, mobile, mobileUnauthor } from "./navigationData";
import { styles } from "./navigation.styles";
import "./navigation.styles.css";
import { routes } from "routing/Routes";

export default function Navigation({ setIsShow, isShow }) {
  const history = useHistory();
  let location = useLocation();
  const { logout } = useAuthActions();
  const user = useSelector((state) => state.profile.accessToken);
  const language = useSelector((state) => state.footer.language);

  const goToLogin = () => {
    let redirectURL = routes().signin;
    const currentPath = window.location.pathname.split('?')[0];
    if (currentPath.indexOf('/resumes/') == 0) {
      const resumeId = currentPath.substring('/resumes/'.length);
      redirectURL = redirectURL + '?resume_id=' + resumeId;
    }
    history.push(redirectURL);
  };

  const goToSignup = () => {
    let redirectURL = routes().signup;
    const currentPath = window.location.pathname.split('?')[0];
    if (currentPath.indexOf('/resumes/') == 0) {
      const resumeId = currentPath.substring('/resumes/'.length);
      redirectURL = redirectURL + '?resume_id=' + resumeId;
    }
    history.push(redirectURL);
  };

  const handleLogout = () => {
    logout();
    history.push(routes().signin);
  };

  return (
    <Box sx={styles.navigation}>
      {/* mobile */}
      <Box sx={styles.mobile}>
        {(user ? mobile : mobileUnauthor).map((link) => {
          return (
            <div key={link.link}>
              <Typography
                sx={link.link === "Create New Resume" ? styles.mobileMenuItemTextActive : styles.mobileMenuItemText}
                onClick={() => {
                  setIsShow(!isShow);
                  if (link.link === 'Login') {
                    goToLogin();
                  } else if (link.link === 'Sign Up') {
                    goToSignup();
                  } else if (link.link === 'Logout') {
                    handleLogout();
                  } else {
                    history.push(link.path);
                  }
                }}
              >
                {language[link.i18n]}
              </Typography>
            </div>
          );
        })}
      </Box>

      {/* desktop */}
      <Box sx={styles.desktop}>
        {(user ? desktop : desktopUnauthor).map((link) => {
          let isActive = false;
          const urlPath = window.location.pathname.split('?')[0];
          if (link.path === '' || link.path === '/') {
            // resumes
            if (urlPath === '' || urlPath === '/' ||
            urlPath.indexOf('/resumes') == 0
            ) {
              isActive = true;
            }
          } else if (urlPath === `${link.path}` || urlPath.indexOf(`${link.path}`) == 0) {
            isActive = true;
          }
          
          return (
            <Link
              key={link.path}
              to={link.path}
              className="link"
              style={{ marginRight: "40px" }}
            >
              <Typography
                style={isActive ? styles.desktopLinkActive : styles.desktopLink}
              >
                {language[link.i18n]}
              </Typography>
            </Link>
          );
        })}
      </Box>
    </Box>
  );
}
