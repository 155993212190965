export const Utils = new class {

    createResume_mainImage = null;
    createResume_files = [];

    gup(name, url) {
        if (!url) url = location.href;
        // eslint-disable-next-line
        name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
        var regexS = "[\\?&]"+name+"=([^&#]*)";
        var regex = new RegExp( regexS );
        var results = regex.exec( url );
        return results == null ? null : results[1];
    }

    isExisty(value) {
        return value !== null && value !== undefined;
    }

    isSamsungBrowser() {
        return navigator.userAgent.match(/SamsungBrowser/i) ? true : false;
    }

    isSafariBrowser() {
        return !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);
    }

    isIEBrowser():boolean {
        const ua = window.navigator.userAgent;
        const msie = ua.indexOf("MSIE ");

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./))  // If Internet Explorer, return version number
        {
            return true;
        }

        return false;
    }

    isChromeIOSBrowser() {
        return navigator.userAgent.match('CriOS') ? true : false;
    }

    isChromeBrowser() {
        if (this.isChromeIOSBrowser()) {
            return true;
        }

        const isChromium = window.chrome;
        const winNav = window.navigator;
        const vendorName = winNav.vendor;
        const isOpera = typeof window.opr !== "undefined";
        const isIEedge = winNav.userAgent.indexOf("Edg") > -1;

        if (
            isChromium !== null &&
            typeof isChromium !== "undefined" &&
            vendorName === "Google Inc." &&
            isOpera === false &&
            isIEedge === false
        ) {
            return true;
        }

        return false;
    }

    isWindowOS() {
        return !!navigator.userAgent.match(/Win/i);
    }

    isIOS() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    }

    isAndroid() {
        return navigator.userAgent.match(/Android/i);
    }
    
    isBlackBerry() {
        return navigator.userAgent.match(/BlackBerry/i);
    }
    
    isOperaMobile() {
        return navigator.userAgent.match(/Opera Mini/i);
    }
    
    isWindowsMobile() {
        return navigator.userAgent.match(/IEMobile/i);
    }
    
    isMobile() {
        return this.isAndroid() || this.isBlackBerry() || this.isIOS() || this.isOperaMobile() || this.isWindowsMobile();
    }
    
    isImageFile(url) {
        if (url && url.toLowerCase().endsWith('.jpg') ||
        url.toLowerCase().endsWith('.jpeg') ||
        url.toLowerCase().endsWith('.png') ||
        url.toLowerCase().endsWith('.gif') ||
        url.toLowerCase().endsWith('.webp')) {
            return true;
        }
        return false;
    }

    isVideoFile(url) {
        if (url && url.toLowerCase().endsWith('.mov') ||
        url.toLowerCase().endsWith('.mp4')) {
            return true;
        }
        return false;
    }

    isPDFFile(url) {
        if (url && url.toLowerCase().endsWith('.pdf')) {
            return true;
        }
        return false;
    }

    async getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file)
            reader.onload = () => {
                resolve(reader.result);
            }
            reader.onerror = reject
        })
    }

}