import React from "react";
import AuthLayout from "layouts/AuthLayout";

export default function AcceptLine() {
  let params = new URLSearchParams(document.location.search);
  let token = params.get("token");
  let redirect = params.get("route");
  localStorage.setItem("accessToken", token);
  document.location.href = `/${redirect}`;

  return (
    <AuthLayout>
      <div>Redirect</div>
    </AuthLayout>
  );
}
