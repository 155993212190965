export const styles = {
  modalCaption: {
    fontSize: "18px",
    fontWeight: 400,
    marginBottom: "30px",
  },
  modalInfoText: { fontSize: "14px", lineHeight: "20px", fontWeight: 400 },
  modalButtonsBlock: {
    display: "flex",
    justifyContent: "end",
    marginTop: "30px",
  },
};
