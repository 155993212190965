import React, { useState } from "react";
import { danger, grey } from "colors";
import { useSelector } from "react-redux";
import { useAuthActions } from "redux/auth/useAuthActions";
import { useToastActions } from "redux/toast/useToastActions";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Box, Typography } from "@mui/material";
import { styles } from "./settings.styles";
import MainLayout from "layouts/MainLayout";

const EmailInput = ({
  value,
  title,
  onChange,
  name,
  errors,
  defaultEmail,
  isEditable,
}) => {
  return (
    <Box sx={styles.block}>
      <Typography sx={styles.caption}>{title}</Typography>
      {!isEditable ? (
        <input
          type="email"
          autoComplete="on"
          name={name}
          value={defaultEmail}
          onChange={onChange}
          readOnly
          style={
            errors[name]
              ? { ...styles.input, border: `1px solid ${danger}` }
              : { ...styles.input, border: `1px solid ${grey}` }
          }
        />
      ) : (
        <input
          type="email"
          autoComplete="on"
          name={name}
          value={value}
          onChange={onChange}
          style={
            errors[name]
              ? { ...styles.input, border: `1px solid ${danger}` }
              : { ...styles.input, border: `1px solid ${grey}` }
          }
        />
      )}
    </Box>
  );
};

const PasswordInput = ({ value, title, onChange, name, errors }) => {
  return (
    <Box sx={styles.block}>
      <Typography sx={styles.caption}>{title}</Typography>
      <Box sx={{ position: "relative" }}>
        <input
          type="password"
          name={name}
          value={value}
          autoComplete="on"
          onChange={onChange}
          style={
            errors[name]
              ? { ...styles.input, border: `1px solid ${danger}` }
              : { ...styles.input, border: `1px solid ${grey}` }
          }
        />
        <button style={{ position: "absolute", right: "12px", top: "5px" }}>
          <VisibilityOffIcon sx={{ color: " #D9D9D9" }} />
        </button>
      </Box>
    </Box>
  );
};

export default function Settings() {
  const { showToast } = useToastActions();
  const { updatePassword, updateEmail } = useAuthActions();
  const { email, userId } = useSelector((state) => state.profile);
  const { errorMessage, loading } = useSelector((state) => state.auth);
  const [isEditable, setIsEditable] = useState(false);
  const language = useSelector((state) => state.footer.language);

  const [values, setValues] = useState({
    email: "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    email: false,
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const handleChange = (e) => {
    let clonedErrors = Object.assign({}, errors);
    const { name, value } = e.target;
    if (!e.target) {
      clonedErrors[name] = true;
    } else {
      clonedErrors[name] = false;
    }
    setErrors(clonedErrors);
    setValues({ ...values, [name]: value });
  };

  const changeEmail = () => {
    if (!validateEmail()) return;
    let data = {
      email: values.email,
    };

    updateEmail(userId, data);

    if (!loading && errorMessage.length !== 0) {
      showToast(errorMessage);
    } else {
      showToast(language['Resume.showToast.newEmailSaved']);
      setIsEditable(false);
    }
  };

  const changePassword = () => {
    if (!validatePassword()) return;

    let data = {
      password: values.currentPassword,
      newPassword: values.confirmPassword,
    };

    updatePassword(userId, data);

    if (!loading && errorMessage.length !== 0) {
      showToast(errorMessage);
    } else {
      showToast(language['Resume.showToast.newPasswordSaved']);
    }
  };

  const validateEmail = () => {
    let isValid = true;
    let errorsData = {};

    if (!values.email) {
      errorsData.email = true;
      isValid = false;
      showToast(language['Resume.showToast.cannotEmptyField']);
    }

    if (
      !values.email.match(/^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/g) &&
      values.email.length !== 0
    ) {
      errorsData.email = true;
      isValid = false;
      showToast(language['Resume.showToast.writeCorrectEmail']);
    }

    setErrors(errorsData);
    return isValid;
  };

  const validatePassword = () => {
    let isValid = true;
    let errorsData = {};

    if (
      !values.currentPassword ||
      !values.newPassword ||
      !values.confirmPassword
    ) {
      errorsData.currentPassword = true;
      errorsData.newPassword = true;
      errorsData.confirmPassword = true;
      isValid = false;
      showToast(language['Resume.forms.cannotEmptyField']);
    }

    if (values.newPassword.length < 8) {
      errorsData.newPassword = true;
      isValid = false;
      showToast(language['Resume.forms.needGreaterThan8ch']);
    }

    if (values.newPassword !== values.confirmPassword) {
      errorsData.confirmPassword = true;
      isValid = false;
      showToast(language['Resume.forms.needSamePassword']);
    }

    setErrors(errorsData);
    return isValid;
  };

  return (
    <MainLayout>
      <Box sx={styles.section}>
        <Box sx={{ ...styles.container, ...styles.leftContainer }}>
          <Box sx={styles.box}>
            <Box sx={styles.flexbox}>
              <Typography sx={styles.title}>{language['Resume.forms.email']}</Typography>
              {!isEditable ? (
                <button
                  onClick={() => setIsEditable(true)}
                  style={styles.editButton}
                >
                  <img
                    src="../../svg/edit.svg"
                    alt=""
                    style={{ width: "inherit" }}
                  />
                </button>
              ) : (
                <div style={{ display: "flex" }}>
                  <button
                    style={{ ...styles.button, ...styles.cancelButton }}
                    onClick={() => {
                      setIsEditable(false);
                      errors[email] = false;
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    style={{ ...styles.button, ...styles.saveButton }}
                    onClick={() => changeEmail()}
                  >
                    {language['Resume.forms.saveChanges']}
                  </button>
                </div>
              )}
            </Box>
            <EmailInput
              name={"email"}
              value={values.email}
              title={language['Resume.forms.email']}
              onChange={handleChange}
              errors={errors}
              defaultEmail={email}
              isEditable={isEditable}
            />
          </Box>
        </Box>

        <Box sx={styles.container}>
          <Box sx={{ ...styles.box, marginLeft: "auto" }}>
            <Box sx={styles.flexbox}>
              <Typography sx={styles.title}>{language['Authen.password']}</Typography>
              <button
                style={{ ...styles.button, ...styles.saveButton }}
                type="submit"
                onClick={() => changePassword()}
              >
                {language['Authen.updatePassword']}
              </button>
            </Box>

            <PasswordInput
              name={"currentPassword"}
              value={values.currentPassword}
              title={language['Authen.currentPassword']}
              onChange={handleChange}
              errors={errors}
            />
            <PasswordInput
              name={"newPassword"}
              value={values.newPassword}
              title={language['Authen.newPassword']}
              onChange={handleChange}
              errors={errors}
            />
            <PasswordInput
              name={"confirmPassword"}
              value={values.confirmPassword}
              title={language['Authen.confirmNewPassword']}
              onChange={handleChange}
              errors={errors}
            />
          </Box>
        </Box>
      </Box>
    </MainLayout>
  );
}
