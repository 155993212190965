import React from "react";
import Footer from "components/footer/Footer";

import { Box } from "@mui/material";

const styles = {
  footer: {
    "@media (max-width: 800px)": {
      display: "none",
    },
  },
};
export default function SampleLayout(props) {
  return (
    <div>
      {props.children}
      <Box sx={styles.footer}>
        <Footer />
      </Box>
    </div>
  );
}
