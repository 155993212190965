import { CREATE_RESUME, CREATE_RESUME_ERROR } from "./createResume.types";

const initialState = {
  loading: false,
};

export const createResumeReducer = (state = initialState, { type }) => {
  switch (type) {
    case CREATE_RESUME:
      return { ...state, loading: true };

    case CREATE_RESUME_ERROR:
      return { ...state, loading: false };

    default:
      return state;
  }
};
