import React, { useState, useRef } from "react";
import DotButtonMenu from "custom/buttons/dotButtonMenu/DotButtonMenu";
import moment from "moment";
import { Link } from "react-router-dom";
import { routes } from "routing/Routes";
import { useToastActions } from "redux/toast/useToastActions";
import { useSelector } from "react-redux";

import { Box, Typography } from "@mui/material";
import { styles } from "./resumeCard.styles";
import ModalConfirmation from "../modalConfirmation/ModalConfirmation";
import ApiResumeService from "services/ApiResumeService";

export default function OtherResumeCard({ resume, onReloadResumeList }) {
  const [show, setShow] = useState(false);
  const [isDisplayModalConfirmDelete, setIsDisplayModalConfirmDelete] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const containerRef = useRef();
  const { showToast } = useToastActions();
  const language = useSelector((state) => state.footer.language);

  const removeResume = async () => {
    setIsDisplayModalConfirmDelete(false);
    const result = await ApiResumeService.removeResume(resume._id);
    if (result && result.success) {
      showToast(language['Resume.remove.successfull']);
      onReloadResumeList && onReloadResumeList();
    } else if (containerRef) {
      if (result && result.message) {
        showToast(result.message);
      }
    }
  };

  return (
    <>
      <ModalConfirmation
        resume={resume}
        isOpen={isDisplayModalConfirmDelete}
        setIsOpen={setIsDisplayModalConfirmDelete}
        onYesButtonClick={removeResume}
        type={"other"}
        language={language}
      />
      <Box sx={styles.cardsItem} ref={containerRef}>
        <Box sx={styles.card}>
          <Link className="link" to={routes({ id: resume._id }).resumeById}>
            <Box sx={{ height: "91px" }}>
              <img
                src={`${process.env.REACT_APP_MEDIA_STORAGE}/${resume?.mainImage?.name}`}
                style={styles.mainImage}
                alt=""
              />
            </Box>
          </Link>
          <Box
            sx={{
              ...styles.flexBox,
              ...styles.captionBox,
            }}
          >
            <Box sx={styles.otherCaption}>
              <span style={{ paddingRight: "3px" }}>{resume.surname}</span>
              <span>{resume.name}</span>
            </Box>

            <Box
              sx={{
                ...styles.flexBox,
                alignItems: "center",
                paddingBottom: "15px",
              }}
            >
              <Typography sx={styles.text}>
                <b>Saved: </b>
                {moment(resume?.savedAt).format("YYYY/MM/DD")}
              </Typography>

              <DotButtonMenu
                show={show}
                setShow={setShow}
                onDelete={() => setIsDisplayModalConfirmDelete(true)}
                openShareModal={openShareModal}
                setOpenShareModal={setOpenShareModal}
                resume={resume}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
