import React, { useState, useEffect } from "react";
import AuthPasswordInput from "custom/inputs/authInput/AuthPasswordInput";
import { TwitterAuthProvider, signInWithPopup } from "firebase/auth";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useAuthActions } from "redux/auth/useAuthActions";
import { useToastActions } from "redux/toast/useToastActions";
import { routes } from "routing/Routes";

import { Grid, Typography, CircularProgress, Box, Button } from "@mui/material";
import { styles } from "./authForm.styles";
import AuthInput from "../../../custom/inputs/authInput/AuthInput";
import SeparatorLine from "../../../custom/separatorLine/SeparatorLine";
import { authentication } from "../../context/base";
import AuthCheckbox from "../authCheckbox/AuthCheckbox";
// import AuthFooter from "../authFooter/AuthFooter";
import FormText from "../authText/FormText";
import { Utils } from "utils/utils";

const SigninForm = () => {
  const history = useHistory();
  const { signin, signInWithTwitter, signInLine} = useAuthActions();
  const { showToast } = useToastActions();
  const accessToken = useSelector((state) => state.profile.accessToken);
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const language = useSelector((state) => state.footer.language);
  const [isLINE, setIsLINE] = useState(true);

  useEffect(() => {
    if (accessToken) {
      const resumeId = Utils.gup('resume_id');
      let url = routes().resumes;
      if (resumeId && resumeId !== '') {
        url = routes({id: resumeId}).resumeById;
      }
      history.push(url);
    }
  }, [accessToken]);

  const search = useLocation().search;
  const queryPath = new URLSearchParams(search);

  useEffect(() => {
    if (queryPath.get('code') !== null && queryPath.get('state') !== null ) {
      loading();
      const code = queryPath.get('code');
      const state = queryPath.get('state');
      const data = {'code': code, 'state': state};
      var cookies = document.cookie;
      var cookiesArray = cookies.split(';'); 
      var statekey = '';
      for(var c of cookiesArray){ 
        var cArray = c.split('=');
        if( cArray[0] == 'lineStateToken'){
            statekey = cArray[1];
        }
      }
      if (state == statekey) {
        const fetchData = async () => {
          signInLine(data)
          .then(() => {
          })
          .catch(() => {
            loading();
          });
        }
        fetchData();
      }
    }
  }, []);

  const [errors, setErrors] = useState({
    email: false,
    password: false,
  });

  const loading = () => {
    setIsLoading(!isLoading);
  };
  
  async function onSubmitForm(e) {
    e && e.preventDefault();

    if (!isFormValid(user.email, user.password)) return;

    await signin(user.email, user.password);
  }

  const isFormValid = () => {
    let isValid = true;
    let errorsData = {};

    if (!user.email) {
      errorsData.email = true;
      isValid = false;
      showToast(language['Resume.showToast.notCorrectEmail']);
    }

    if (user.password.length === 0) {
      errorsData.password = true;
      isValid = false;
      showToast(language['Resume.showToast.needFillField']);
    }

    setErrors(errorsData);
    return isValid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let clonedErrors = Object.assign({}, errors);
    if (!e.target.value) {
      clonedErrors[name] = true;
    } else {
      clonedErrors[name] = false;
    }
    setErrors(clonedErrors);
    setUser({ ...user, [name]: value });
  };

  function goToSignup() {
    let url = routes().signup;
    const resumeId = Utils.gup('resume_id');
    if (resumeId && resumeId !== '') {
      url = url + '?resume_id=' + resumeId;
    }
    history.push(url);
  }

  function goWithTwitter() {
    setIsLINE(false);
    const provider = new TwitterAuthProvider();
    signInWithPopup(authentication, provider)
      .then((res) => {
        let data = {
          user: res.user,
          name: res._tokenResponse.screenName,
          externalId: res.user.uid,
        };
        signInWithTwitter(data);
      })
      .catch(() => {});
  }

  const line = () => {
    setIsLINE(true);
    const url = `${process.env.REACT_APP_LINE_API_URL}`;
    const stateToken= Math.random().toString(32).substring(2);
    document.cookie = `lineStateToken=${stateToken}`;
    const scope = '&scope=profile';
    const key = ', openid';
    const combinedURL = url + stateToken + scope + key;
    window.location.href = combinedURL;
  };

  const showLoading = () => {
    return (
      <>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", margin: "0 auto" }}>
          <CircularProgress color="success" />
          <div style={{ marginTop: "25px"}} >
            { isLINE 
              ? language['Authen.Login.loadingByLINE']
              : language['Authen.Login.loadingByTwitter']
            }
          </div>
        </div>
      </>
    )
  };
  
  return (
    <>
      { isLoading
          ? showLoading()
          : <>
          <Grid item xs={12} sm={12} md={6} sx={{ ...styles.authform }}>
            <Grid sx={styles.fieldGrid}>
              <FormText label={language['Resume.welcome.Back']} caption={language['Authen.LoginFor']} />
              <form name="form" onSubmit={onSubmitForm}>
                <AuthInput
                  name={"email"}
                  onChange={handleInputChange}
                  value={user.email}
                  placeholder={"Email"}
                  errors={errors}
                />
                <AuthPasswordInput
                  name={"password"}
                  onChange={handleInputChange}
                  value={user.password}
                  placeholder={"Password"}
                  errors={errors}
                  minLength={8}
                />

                <Box className="relative flexrow hoz-right">
                  <Link to={routes().forgotPassword}>
                    <Typography sx={styles.forgotPassword}>
                    {language['Authen.forgotPassword']}
                    </Typography>
                  </Link>
                </Box>
        
                <Button
                  sx={{ ...styles.button, ...styles.login }}
                  onClick={() => onSubmitForm()}
                >
                  {language['Authen.Login']}
                </Button>
        
                <AuthCheckbox
                // isRememberMe={isRememberMe}
                // setIsRememberMe={setIsRememberMe}
                />
              </form>

              {/* TODO: https://app.clickup.com/t/85zu48gu1 */}
              <Grid sx={styles.content}>
                <SeparatorLine />
                {/* < button
                  style={{ ...styles.buttonWithIcon, ...styles.twitButton}}
                  onClick={goWithTwitter}
                >
                  <img src="../../logo/twitter_social_icons.png" alt="" />
                  <div style={{ ...styles.buttonContent}}>
                    {language['Authen.Login.Twitter']}
                  </div>
                </button>
                < button style={{ ...styles.buttonWithIcon, ...styles.lineButton }}
                  onClick={() => {
                    line()
                  }}
                >
                  <img src="../../logo/LINE_APP_iOS 1.png" alt="" />
                  <div style={{ ...styles.buttonContent}}>
                    {language['Authen.Login.LINE']}
                  </div>
                </button> */}

                {/* <AuthFooter type={"dekstop"} /> */}
                <Button
                  sx={{ ...styles.button, ...styles.signup }}
                  onClick={() => goToSignup()}
                >
                  {language['Authen.Signup']}
                </Button>
              </Grid>
              
            </Grid>
          </Grid>
          {/* TODO: https://app.clickup.com/t/85zu48gu1 */}
          {/* <AuthFooter type={"mobile"} /> */}
        </>
      }
    </>
  );
};

export default SigninForm;
