import React, { useRef, useState } from "react";
import { Formik } from "formik";
import { useAuthActions } from "redux/auth/useAuthActions";
import { useToastActions } from "redux/toast/useToastActions";
import * as Yup from "yup";

import Input from "./components/Input";
import ModalInformation from "./components/ModalInformation";
import Textarea from "./components/Textarea";
import { Box, Typography } from "@mui/material";
import { styles } from "./help.styles";
import { useSelector } from "react-redux";
import MainLayout from "layouts/MainLayout";

const validation = Yup.object({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(30, "Too Long!")
    .required("Please fill the highlighted fields"),
  subject: Yup.string()
    .min(5, "Too Short!")
    .max(30, "Too Long!")
    .required("Please fill the highlighted fields"),
  email: Yup.string().email("Invalid email address").required("Required"),
  message: Yup.string()
    .min(2, "Too Short!")
    .max(90, "Too Long!")
    .required("Please fill the highlighted fields"),
});

export default function Help() {
  const { sendQuestionEmail } = useAuthActions();
  const { showToast } = useToastActions();
  const formikContactRef = useRef();
  const [isSubmitted, setSubmitted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const language = useSelector((state) => state.footer.language);

  const sendMessage = () => {
    if (formikContactRef.current) {
      formikContactRef.current
        .validateForm()
        .then((errors) => {
          if (Object.keys(errors).length !== 0) {
            setSubmitted(true);
            showToast(language['Resume.showToast.fillHighlightedField']);
          } else {
            setSubmitted(false);
            sendQuestionEmail(formikContactRef.current.values);
            setIsOpen(true);
          }
        })
        .catch((error) => {
          showToast(error);
        });
    }
  };

  return (
    <MainLayout>
      <Box sx={styles.section}>
        <Box sx={{ width: "100%" }}>
          <Typography sx={styles.title}>{language['Resume.contact.title']}</Typography>

          <Formik
            innerRef={formikContactRef}
            initialValues={{
              name: "",
              subject: "",
              email: "",
              message: "",
            }}
            validationSchema={validation}
          >
            {({ values, handleChange, errors }) => (
              <Box sx={styles.container}>
                <Input
                  title={language['Resume.contact.name']}
                  value={values.name}
                  type={"text"}
                  name={"name"}
                  onChange={handleChange}
                  errors={errors.name}
                  isSubmitted={isSubmitted}
                />
                <Input
                  title={language['Resume.contact.subject']}
                  value={values.subject}
                  type={"text"}
                  name={"subject"}
                  onChange={handleChange}
                  errors={errors.subject}
                  isSubmitted={isSubmitted}
                />
                <Input
                  title={language['Resume.contact.email']}
                  value={values.email}
                  type={"email"}
                  name={"email"}
                  onChange={handleChange}
                  errors={errors.email}
                  isSubmitted={isSubmitted}
                />
                <Textarea
                  title={language['Resume.contact.message']}
                  value={values.message}
                  name={"message"}
                  onChange={handleChange}
                  errors={errors.message}
                  isSubmitted={isSubmitted}
                />
              </Box>
            )}
          </Formik>
          <button onClick={() => sendMessage()} style={styles.button}>
          {language['Resume.contact.sendMessage']}
          </button>
        </Box>

        <ModalInformation isOpen={isOpen} />
      </Box>
    </MainLayout>
  );
}
