import React, { useState } from "react";
import { warning } from "colors";
import { styles } from "./editPhotoButton.styles";
import { useSelector } from "react-redux";

export default function EditPhotoButton({
  isViewEnabled,
  onView,
  onChange,
  onDelete
}) {
  const [show, setShow] = useState(false);
  const language = useSelector((state) => state.footer.language);

  return (
    <div style={styles.editButton}>
      <button style={styles.button} onClick={() => setShow(!show)}>
        <img src="../../action/camera.png" alt="camera" />
      </button>
      {show && (
        <div style={styles.actions}>
          {isViewEnabled &&
            <button style={{ ...styles.actionButton }} onClick={() => {setShow(false); onView(); }}>
              {language['Resume.file.view']}
            </button>
          }
          <button style={{ ...styles.actionButton }} onClick={() => {setShow(false); onChange(); }}>
            {language['Resume.file.change']}
          </button>
          <button
            style={{ ...styles.actionButton, color: `${warning}` }}
            onClick={() => {setShow(false); onDelete(); }}
          >
            {language['Resume.file.delete']}
          </button>
        </div>
      )}
    </div>
  );
}
