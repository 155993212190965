import React from "react";

import { Box } from "@mui/material";

const AuthLayout = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      {props.children}
    </Box>
  );
};

export default AuthLayout;
