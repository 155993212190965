import React from "react";
import { danger, grey } from "colors";

import { Box, Typography } from "@mui/material";
import { styles } from "../help.styles";

export default function Textarea({
  title,
  value,
  onChange,
  name,
  errors,
  isSubmitted,
}) {
  let isError =
    isSubmitted && errors ? `1px solid ${danger}` : `1px solid ${grey}`;
  return (
    <Box sx={styles.box}>
      <Typography sx={styles.caption}>{title}</Typography>
      <textarea
        value={value}
        rows="8"
        cols="30"
        style={{ ...styles.input, resize: "none", border: isError }}
        onChange={onChange}
        name={name}
      />
    </Box>
  );
}
