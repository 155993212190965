import React from "react";
import { danger, grey } from "colors";

import { Box, Typography } from "@mui/material";
import { styles } from "../help.styles";

export default function Input({
  title,
  value,
  type,
  onChange,
  name,
  errors,
  isSubmitted,
}) {
  let isError =
    isSubmitted && errors ? `1px solid ${danger}` : `1px solid ${grey}`;

  return (
    <Box sx={styles.box}>
      <Typography sx={styles.caption}>{title}</Typography>
      <input
        type={type}
        value={value}
        style={{ ...styles.input, border: isError }}
        onChange={onChange}
        name={name}
      />
    </Box>
  );
}
