import React from "react";

import ModalPortalComponent from "./ModalPortalComponent";

export default function ModalComponent({ children, isOpen }) {
  return (
    <>
      {isOpen && (
        <ModalPortalComponent>
          <div className="modal-container">
            <div className="modal-background" />
            <div className="modal-content">{children}</div>
          </div>
        </ModalPortalComponent>
      )}
    </>
  );
}
