import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import {
    updateI18n,
} from "./footer.actions";

export const useI18nActions = () => {
    const dispatch = useDispatch();

    return bindActionCreators(
        {
            updateI18n,
        },
        dispatch
    );
};