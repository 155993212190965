import React, { useEffect, useState, useRef } from "react";
import * as styles from './styles';
import { Utils } from "utils/utils";
import MaterialIconClose from '@mui/icons-material/Close';
import MaterialIconFileDownload from '@mui/icons-material/FileDownload';
import MaterialIconRotateRight from '@mui/icons-material/RotateRight';
import MaterialIconZoomIn from '@mui/icons-material/ZoomIn';
import MaterialIconZoomout from '@mui/icons-material/ZoomOut';
import PDFViewer from "./PdfViewer";
import Loader from "custom/loader/Loader";

export default function PDFModal({ 
    file, fileName, maxWidth = 1100, onClose
}) {
    let _keyPressListener;
    let _windowResizeListener;
    let _isDragging = false;
    let _previousPosX = 0;
    let _previousPosY = 0;
    let _pageCount = 1;

    const [imageRotateDeg, setImageRotateDeg] = useState(0);
    const [initialWidth, setInitialWidth] = useState(0);
    const [initialHeight, setInitialHeight] = useState(0);

    const [imgVisibleWidth, setImgVisibleWidth] = useState(window.innerWidth);
    const [imgVisibleHeight, setImgVisibleHeight] = useState(window.innerWidth);
    const [imgVisibleTop, setImgVisibleTop] = useState(0);
    const [imgVisibleLeft, setImgVisibleLeft] = useState(0);

    const containerRef = useRef();
    let contentRef = useRef();

    useEffect(() => {
        registerEventListeners();

        show();
        calculateInitialSize();

        // 👇️ remove the event listener when the component unmounts
        return () => {
            removeEventListeners();
            hide();
        };
    }, []);

    const registerEventListeners = () => {
        _keyPressListener = (e) => handleOnKeyPress(e);
        document.addEventListener("keydown", _keyPressListener);
        _windowResizeListener = () => calculateInitialSize();
        window.addEventListener("resize", _windowResizeListener);

        document.addEventListener('mousedown', (e) => handleOnImageMouseDown(e));
        document.addEventListener('touchstart', (e) => handleOnImageMouseDown(e));
        document.addEventListener('mouseup', () => handleOnImageMouseUp());
        document.addEventListener('mouseleave', () => handleOnImageMouseUp());
        document.addEventListener('touchend', () => handleOnImageMouseUp());
        document.addEventListener('mousemove', (e) => handleOnImageMouseMove(e));
        document.addEventListener('touchmove', (e) => handleOnImageMouseMove(e));
    };

    const removeEventListeners = () => {
        if (_keyPressListener) {
            document.removeEventListener("keydown", _keyPressListener);
            _keyPressListener = null;
        }
        if (_windowResizeListener) {
            window.removeEventListener("resize", _windowResizeListener);
            _windowResizeListener = null;
        }

        document.removeEventListener('mousedown', (e) => handleOnImageMouseDown(e));
        document.removeEventListener('touchstart', (e) => handleOnImageMouseDown(e));
        document.removeEventListener('mouseup', () => handleOnImageMouseUp());
        document.removeEventListener('mouseleave', () => handleOnImageMouseUp());
        document.removeEventListener('touchend', () => handleOnImageMouseUp());
        document.removeEventListener('mousemove', (e) => handleOnImageMouseMove(e));
        document.removeEventListener('touchmove', (e) => handleOnImageMouseMove(e));
    };

    const show = () => {
        const modalContainer = document.getElementById('abc-modal-container');
        if (!Utils.isSamsungBrowser() && modalContainer) {
            modalContainer.style.right = '0px';
            modalContainer.style.bottom = '0px';
            modalContainer.appendChild(containerRef);
        }
    };

    const hide = () => {
        const modalContainer = document.getElementById('abc-modal-container');
        if (!Utils.isSamsungBrowser() && modalContainer) {
            modalContainer.removeChild(containerRef);
            modalContainer.style.right = 'auto';
            modalContainer.style.bottom = 'auto';
        }
    };

    const calculateInitialSize = () => {
        const ele = contentRef?.current;
        if (ele) {
            let __initialWidth = initialWidth;
            if (__initialWidth == 0) {
                __initialWidth = ele.getBoundingClientRect().width;
                if (maxWidth && maxWidth > 0) {
                    __initialWidth = Math.min(__initialWidth, maxWidth);
                }
            } else {
                __initialWidth = ele.getBoundingClientRect().width;
            }
            
            if (initialWidth == 0) {
                setInitialWidth(__initialWidth);
                setInitialHeight(ele.getBoundingClientRect().height);
                setImgVisibleWidth(__initialWidth);
                setImgVisibleHeight(ele.getBoundingClientRect().height);
                setImgVisibleLeft((ele.getBoundingClientRect().width - __initialWidth) / 2);
            } else {
                setInitialWidth(__initialWidth);
                setInitialHeight(ele.getBoundingClientRect().height);
            }
        }
    };

    const recalculateVisibleSizeWithOnlyOnePage = (originalWidth, originalHeight) => {
        let __imgVisibleHeight = imgVisibleWidth * Math.ceil(originalHeight / originalWidth);
        let __imgVisibleTop = imgVisibleTop;
        const ele = contentRef?.current;
        if (ele) {
            __imgVisibleHeight = Math.min(imgVisibleHeight, ele.getBoundingClientRect().height);
            __imgVisibleTop = (ele.getBoundingClientRect().height - imgVisibleHeight) / 2;
        }
        setImgVisibleHeight(__imgVisibleHeight);
        setImgVisibleTop(__imgVisibleTop);
    };

    const handleOnKeyPress = (e) => {
        if ((e.key === 'Escape' || e.key === 'Esc') && e.keyCode == 27) {
            handleOnCloseClick();
        } else if (e.keyCode == 37) { // left
            setImgVisibleLeft(imgVisibleLeft - 1);
        } else if (e.keyCode == 39) { // right
            setImgVisibleLeft(imgVisibleLeft + 1);
        } else if (e.keyCode == 38) { // up
            setImgVisibleTop(imgVisibleTop - 1);
        } else if (e.keyCode == 40) { // down
            setImgVisibleTop(imgVisibleTop + 1);
        }
    };

    const handleOnDownloadClick = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        const url = file;

        if (Utils.isIOS()) {
            window.location.href = url;
            return;
        }

        let __fileName = fileName;
        if (!__fileName || __fileName === '') {
            for (let i = 0; i < url.split('/').length; i++) {
                const path = (url.split('/')[i] + '').toLowerCase();
                if (path.endsWith('.pdf')) {
                    __fileName = path;
                    break;
                }
            }
        }

        fetch(url)
        .then(response => response.blob())
        .then(blob => {
            const blobURL = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = blobURL;
            a.style = "display: none";
            a.download = __fileName;
            a.target = "_blank";
            document.body.appendChild(a);
            a.click();
        })
        .catch(() => {});
    };

    const handleOnCloseClick = () => {
        if (onClose) {
            onClose();
        }
    };

    const handleOnRotateClick = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        const __imgVisibleWidth = imgVisibleHeight;
        const __imgVisibleHeight = imgVisibleWidth;

        const ele = contentRef?.current;
        const __imgVisibleTop = (ele.getBoundingClientRect().height - __imgVisibleHeight) / 2;
        const __imgVisibleLeft = (ele.getBoundingClientRect().width - __imgVisibleWidth) / 2;

        setImageRotateDeg(imageRotateDeg + 90);
        setImgVisibleWidth(__imgVisibleWidth);
        setImgVisibleHeight(__imgVisibleHeight);
        setImgVisibleTop(__imgVisibleTop);
        setImgVisibleLeft(__imgVisibleLeft);
    };

    const handleOnZoomClick = (e, isZoomIn=true) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        const zoomPercent = 20; // %
        const zoomWidthAmount = Math.round((initialWidth * zoomPercent) / 100) * (isZoomIn ? 1 : -1);
        const zoomHeightAmount = Math.round((initialHeight * zoomPercent) / 100) * (isZoomIn ? 1 : -1);
        let __imgVisibleWidth = imgVisibleWidth + zoomWidthAmount;
        let __imgVisibleHeight = imgVisibleHeight + zoomHeightAmount;
        
        if (__imgVisibleWidth / initialWidth >= 4 || initialWidth / __imgVisibleWidth >= 4) { // max zoom-in 4x
            return;
        }

        let __imgVisibleTop = imgVisibleTop - (zoomHeightAmount/2);
        let __imgVisibleLeft = imgVisibleLeft - (zoomWidthAmount/2);
        
        setImgVisibleWidth(__imgVisibleWidth);
        setImgVisibleHeight(__imgVisibleHeight);
        setImgVisibleTop(__imgVisibleTop);
        setImgVisibleLeft(__imgVisibleLeft);
    };

    const handleOnImageMouseDown = (e, isTouchMode=false) => {
        const isMouseLeftClick = !e.button || e.button == 0;
        if (!Utils.isMobile() && !isTouchMode && isMouseLeftClick) {
            _isDragging = true;
            if (e.target && e.touches && e.touches.length > 0) {
                _previousPosX = e.touches[0].clientX;
                _previousPosY = e.touches[0].clientY;
            } else {
                _previousPosX = e.clientX;
                _previousPosY = e.clientY;
            }
        }
    };

    const handleOnImageMouseMove = (e) => {
        if (_isDragging) {
            let deltaX = 0;
            let deltaY = 0;
            
            if (e.touches && e.touches.length > 0) {
                deltaX = e.touches[0].clientX - _previousPosX;
                deltaY = e.touches[0].clientY - _previousPosY;
                _previousPosX = e.touches[0].clientX;
                _previousPosY = e.touches[0].clientY;
            } else {
                deltaX = e.clientX - _previousPosX;
                deltaY = e.clientY - _previousPosY;
                _previousPosX = e.clientX;
                _previousPosY = e.clientY;
            }
            setImgVisibleTop((pre) => pre + deltaY);
            setImgVisibleLeft((pre) => pre + deltaX);
        }
    };

    const handleOnImageMouseUp = () => {
        _isDragging = false;
    };

    // =========================== render ===========================
    
    const renderActionButtons = () => {
        return (
            <div className="imgview-topright-buttons flexrow ver-center">
                <div
                    style={styles.topRightButton} className="flexrow ver-center"
                    onClick={(e) => handleOnZoomClick(e, true)}
                >
                    <MaterialIconZoomIn style={{width: '24px', height: '24px', color: '#000'}} />
                </div>
                <div
                    style={styles.topRightButton} className="flexrow ver-center"
                    onClick={(e) => handleOnZoomClick(e, false)}
                >
                    <MaterialIconZoomout style={{width: '24px', height: '24px', color: '#000'}} />
                </div>
                <div
                    style={styles.topRightButton} className="flexrow ver-center"
                    onClick={(e) => handleOnRotateClick(e)}
                >
                    <MaterialIconRotateRight style={{width: '24px', height: '24px', color: '#000'}} />
                </div>
                {file &&
                    <div 
                        style={styles.topRightButton} className="flexrow ver-center"
                        onClick={(e) => handleOnDownloadClick(e)}
                    >
                        <MaterialIconFileDownload style={{width: '24px', height: '24px', color: '#000'}} />
                    </div>
                }
                <div
                    style={styles.topRightButton} className="flexrow ver-center"
                    onClick={() => handleOnCloseClick()}
                >
                    <MaterialIconClose style={{width: '24px', height: '24px', color: '#000'}} />
                </div>
            </div>
        );
    }

    const imageStyle: any = {
        width: imgVisibleWidth + 'px',
        height: imgVisibleHeight + 'px',
        top: imgVisibleTop + 'px',
        left: imgVisibleLeft+ 'px',
        transform: 'rotate(' + imageRotateDeg +'deg)',
    };

    return (
        <div>
            <div ref={containerRef} className={"PDFViewerModal-200227 flexrow hoz-center ver-center" + (Utils.isIOS() ? ' ios ' : '')}>
                <div className="pdfviewerm-wrapper flexitem self-stretch flexrow hoz-center">
                    <div ref={contentRef} className="pdfviewerm-content flexitem self-stretch">
                        <div className="pdfviewerm-handle"
                            style={imageStyle}
                            /* https://app.clickup.com/t/2j904nn : cannot click the link (<a>)
                            onClick={(e) => {
                                if (!isMobile.any() && !('ontouchstart' in window)) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }
                            }}
                            */
                        >
                            {file ?
                                <PDFViewer 
                                    file={file} 
                                    isInModal={true} 
                                    viewerWidth={imgVisibleWidth}
                                    onDocumentLoad={(pageCount) => _pageCount = pageCount}
                                    onPageLoad={(index, originalWidth, originalHeight) => {
                                        if (_pageCount == 1) {
                                            recalculateVisibleSizeWithOnlyOnePage(originalWidth, originalHeight);
                                        }
                                    }}
                                />
                                :
                                <div className="PDFViewer-191203">
                                    <div className="pdfviewer-scroll-view">
                                        <div style={styles.preparingLabel} className="pdfviewer-loading text-center">
                                            <div className="flexrow hoz-center relative">
                                                <Loader />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {renderActionButtons()}
            </div>
        </div>
    );
}
