import React, { useState } from "react";
import { danger, main } from "colors";

import { Typography } from "@mui/material";
import { useSelector } from "react-redux";

import "./mobileSchedule.styles.css";

const initialProgressShift = { day: "", hours: [] };

export default function MobileSchedule({ schedules, setSchedules }) {
  let startedTime = 0;
  const [progressShift, setProgressShift] = useState(initialProgressShift);
  const [toDelete, setToDelete] = useState(initialProgressShift);
  const times = [...Array(48).keys()];
  const language = useSelector((state) => state.footer.language);

  const chooseBlock = (schedule, hourIndex, scheduleIndex) => {
    let clonedSchedules = [...schedules];

    let findSched = schedule.hours;
    findSched.map((el, index) => {
      if (hourIndex === index) {
        clonedSchedules[scheduleIndex].hours[index] = true;
      }
    });

    setSchedules(clonedSchedules);
    setProgressShift(clonedSchedules);
  };

  const deleteSchedule = (e) => {
    e.stopPropagation();

    const clonedSchedules = JSON.parse(JSON.stringify(schedules));
    let findSched = clonedSchedules.find(
      (schedule) => schedule.day === toDelete.day
    ).hours;

    for (let i = toDelete.start; i <= toDelete.end; i++) {
      findSched[i] = false;
    }

    setSchedules(clonedSchedules);
    setToDelete(initialProgressShift);
  };

  const press = (day, hourIndex) => {
    let findSched = day.hours;

    if (findSched[hourIndex]) {
      while (findSched[hourIndex]) {
        hourIndex--;
      }
      hourIndex++;

      const start = hourIndex;

      while (findSched[hourIndex]) {
        hourIndex++;
      }
      hourIndex--;

      const end = hourIndex;
      setToDelete({ day: day.day, start, end });
    }
  };

  const styleForLine = (hours, hourIndex) => {
    if (hourIndex - 1 < 0) {
      return "mobBlock-first";
    }
    if (hourIndex + 1 > 47) {
      return "mobBlock-last";
    }
    if (hours[hourIndex - 1] === false && hours[hourIndex + 1] === true) {
      return "mobBlock-first";
    }
    if (hours[hourIndex - 1] === true && hours[hourIndex + 1] === true) {
      return "mobBlock";
    }
    if (hours[hourIndex - 1] === true && hours[hourIndex + 1] === false) {
      return "mobBlock-last";
    }
  };

  return (
    <section style={{ padding: "0 16px" }}>
      <Typography
        style={{
          textTransform: "uppercase",
          color: "#323232",
          lineHeight: "16px",
          margin: "40px 0",
          fontSize: "12px",
        }}
      >
        <span style={{ color: `${danger}` }}>*</span>
        {language['Resume.forms.workShift']};
      </Typography>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div className="times-block">
            {times.map((t, timeIndex) => {
              if (timeIndex % 2) {
                if (timeIndex > 1) {
                  startedTime++;
                }
                return (
                  <div key={timeIndex} className="mobtime">
                    {startedTime.toString().padStart(2, "0")}
                  </div>
                );
              }
            })}
          </div>
          {/*  */}
          <div className="main-block">
            {schedules &&
              schedules?.map((schedule, scheduleIndex) => {
                let scheduleDay = schedule.day.substr(0, 3);
                return (
                  <div key={scheduleIndex} className="schedule-day">
                    <p>{language[`Resume.forms.workShift.${scheduleDay}`]}</p>

                    <div>
                      {schedule?.hours.map((hour, hourIndex) => {
                        let activeStyle =
                          hour ||
                          (progressShift.day === schedule.day &&
                            hourIndex === progressShift.duration)
                            ? `${main}`
                            : "transparent";

                        return (
                          <div
                            key={hourIndex}
                            className="schedule-hour"
                            onClick={() =>
                              chooseBlock(schedule, hourIndex, scheduleIndex)
                            }
                            value={hourIndex}
                          >
                            <div
                              onClick={() => press(schedule, hourIndex)}
                              className={
                                progressShift.day === schedule.day
                                  ? "pickedMobileBlock"
                                  : styleForLine(schedule.hours, hourIndex)
                              }
                              style={{
                                background: activeStyle,
                                width: "70%",
                                margin: "-1px auto",
                                padding: "8px 0",
                                opacity:
                                  toDelete.day === schedule.day &&
                                  hourIndex <= toDelete.end &&
                                  hourIndex >= toDelete.start
                                    ? "0.5"
                                    : "1",
                              }}
                            ></div>

                            {toDelete.day === schedule.day &&
                            hourIndex === toDelete.end ? (
                              <button
                                className="mobile-remove-btn"
                                onClick={(e) => deleteSchedule(e)}
                              >
                                <img src="../../svg/delete.svg" alt="" />
                              </button>
                            ) : null}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
}
