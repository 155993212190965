import {
  GET_NATIONALITY,
  GET_OWN_RESUME,
  GET_OWN_RESUME_ERROR,
  GET_OWN_RESUME_SUCCESS,
  SAVE_TO_MY_LIST,
  SAVE_TO_MY_LIST_ERROR,
} from "./resumeDetail.types";

const initialState = {
  resume: {},
  nationality: [],
  loading: false,
};

export const resumeDetailReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case GET_OWN_RESUME:
      return { ...state, loading: true };
    case GET_OWN_RESUME_SUCCESS:
      return { ...state, resume: payload, loading: false };
    case GET_OWN_RESUME_ERROR:
      return { ...state, loading: false };

    case SAVE_TO_MY_LIST:
      return { ...state, loading: true };
    case SAVE_TO_MY_LIST_ERROR:
      return { ...state, loading: false };

    case GET_NATIONALITY:
      return { ...state, nationality: payload };

    default:
      return state;
  }
};
