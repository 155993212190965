import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import { createResume } from "./createResume/createResume.actions";
import {
  removeSelectedPublishedResume,
  removeSelectedOtherResume,
  removeSelectedEditedResume,
  removeSelectedSavedResume,
} from "./removeResume/removeResume.actions";
import {
  saveToMyList,
  getOwnResumeDetail,
  getNationality,
} from "./resumeDetail/resumeDetail.actions";
import {
  getOwnResumeList,
  getOtherResumeList,
  copyResume,
} from "./resumeList/resumeList.actions";
import {
  updatePublishedResume, unpublishResume,
  saveChangesResume,
} from "./updateResume/updateResume.actions";

export const useResumeActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(
    {
      getOwnResumeList,
      getOtherResumeList,
      getOwnResumeDetail,
      getNationality,
      createResume,
      updatePublishedResume,
      unpublishResume,
      saveChangesResume,
      copyResume,
      saveToMyList,
      removeSelectedPublishedResume,
      removeSelectedSavedResume,
      removeSelectedOtherResume,
      removeSelectedEditedResume,
    },
    dispatch
  );
};
