import { axiosInstance } from "api/axios";

class ApiAuthService {
  signup(email, password) {
    return axiosInstance.post("api/auth/signup", {
      email,
      password,
    });
  }

  signin(email, password) {
    return axiosInstance.post("api/auth/signin", {
      email,
      password,
    });
  }

  authLine(data) {
    return axiosInstance.get("api/auth/line/signin", {params: {code: data.code, state: data.state}})
  }

  authTwitter(data) {
    return axiosInstance.post("api/auth/twitter/signin", {
      data,
    });
  }

  sendNewPasswordToEmail(data) {
    return axiosInstance.post("api//email/html-email", data);
  }

  sendQuestionEmail(data) {
    return axiosInstance.post("api/auth/contact", data);
  }

  getUser() {
    return axiosInstance.get("api/auth/me");
  }

  changeEmail(userId, data) {
    return axiosInstance.put(`api/auth/email/${userId}`, data);
  }

  changePassword(userId, data) {
    return axiosInstance.put(`api/auth/password/${userId}`, data);
  }

  forgotPassword(data) {
    return axiosInstance.put("api//auth", data);
  }
}
export default new ApiAuthService();
