import { axiosInstance } from "api/axios";
import { Utils } from "utils/utils";

class ApiResumeService {

  getNationality() {
    return axiosInstance.get("api/nationality");
  }

  async getAllOwn() {
    try {
      const result = await axiosInstance.get(`api/resumes/own`);
      return {success: true, data: result.data};
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
				return {success: false, message: error.response.data.message};
			}
    }

    return {success: false};
  }

  async getAllOthers() {
    try {
      const result = await axiosInstance.get(`api/resumes/others`);
      return {success: true, data: result.data};
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
				return {success: false, message: error.response.data.message};
			}
    }

    return {success: false};
  }

  async getResumeDetail(resumeId) {
    try {
      const result = await axiosInstance.get(`api/resumes/${resumeId}`);
      return {success: true, data: this.parseResumeResponsedData(result.data)};
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
				return {success: false, message: error.response.data.message};
			}
    }

    return {success: false};
  }

  async createResume(jsonData) {
    try {
      const result = await axiosInstance.post(`api/resumes`, this.convertJSONToFormData(jsonData));
      return {success: true, data: this.parseResumeResponsedData(result.data)};
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
				return {success: false, message: error.response.data.message};
			}
    }

    return {success: false};
  }

  async updateResume(resumeId, jsonData) {
    try {
      const result = await axiosInstance.put(`api/resumes/${resumeId}`, this.convertJSONToFormData(jsonData));
      return {success: true, data: this.parseResumeResponsedData(result.data)};
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
				return {success: false, message: error.response.data.message};
			}
    }

    return {success: false};
  }

  async copyResume(resumeId) {
    try {
      const result = await axiosInstance.patch(`api/resumes/${resumeId}/copy`);
      return {success: true, data: this.parseResumeResponsedData(result.data)};
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
				return {success: false, message: error.response.data.message};
			}
    }

    return {success: false};
  }

  async saveToList(resumeId) {
    try {
      const result = await axiosInstance.patch(`api/resumes/${resumeId}/save-to-my-list`);
      return {success: true, data: this.parseResumeResponsedData(result.data)};
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
				return {success: false, message: error.response.data.message};
			}
    }

    return {success: false};
  }

  async removeResume(resumeId) {
    try {
      await axiosInstance.delete(`api/resumes/${resumeId}`);
      return {success: true};
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
				return {success: false, message: error.response.data.message};
			}
    }

    return {success: false};
  }

  // publish and save form data
  async publishResume(resumeId, jsonData) {
    if (!resumeId || resumeId === '') {
      return await this.createResume(jsonData);
    }
    return await this.updateResume(resumeId, jsonData);
  } 

  async unpublishResume(resumeId) {
    try {
      const result = await axiosInstance.patch(`api/resumes/${resumeId}/unpublish`);
      return {success: true, data: this.parseResumeResponsedData(result.data)};
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
				return {success: false, message: error.response.data.message};
			}
    }

    return {success: false};
  }

  convertJSONToFormData(jsonData) {
    try {
      let formData = new FormData();

      if (jsonData.status && jsonData.status !== '') {
        formData.append("status", jsonData.status);
      }

      formData.append("resumeTitle", jsonData.resumeTitle);
      formData.append("surname", jsonData.surname);
      formData.append("name", jsonData.name);
      formData.append("kanaSurname", Utils.isExisty(jsonData.kanaSurname) ? jsonData.kanaSurname : '');
      formData.append("kanaName", Utils.isExisty(jsonData.kanaName) ? jsonData.kanaName : '');
      formData.append("position", jsonData.position);
      formData.append("nationality", jsonData.nationality);
      formData.append("gender", jsonData.gender);
      formData.append("birthday", jsonData.birthday);
      formData.append("phone", jsonData.phone);
      formData.append("eMail", jsonData.eMail);
      formData.append("address", jsonData.address);
      formData.append("busStation", jsonData.busStation);
      formData.append("transport", jsonData.transport);
      formData.append("motivation", jsonData.motivation);
      formData.append("workHistory", JSON.stringify(jsonData.workHistory));
      formData.append("industries", JSON.stringify(jsonData.industries));
      formData.append("experiences", JSON.stringify(jsonData.experiences));
      formData.append("languages", JSON.stringify(jsonData.languages));
      formData.append("certifications", JSON.stringify(jsonData.certifications));
      formData.append("awards", JSON.stringify(jsonData.awards));
      formData.append("schools", JSON.stringify(jsonData.schools));
      formData.append("selfPromotion", jsonData.selfPromotion);
      formData.append("skills", JSON.stringify(jsonData.skills));
      formData.append("interests", JSON.stringify(jsonData.interests));
      formData.append("whenStart", jsonData.whenStart);
      formData.append("workshift", JSON.stringify(jsonData.workshift));

      if (jsonData.mainImage && jsonData.mainImage.length) {
        formData.append("mainImage", jsonData.mainImage[0]);
      } else {
        formData.append("mainImage", true);
      }

      if (jsonData.files) {
        for (let i = 0; i < jsonData.files.length; i++) {
          formData.append("uploadFiles", jsonData.files[i]);
        }
      }

      formData.append("uploadFilesLeft", JSON.stringify(jsonData.uploadFiles));
      console.log('formData');
      console.log(formData);
      return formData;
    } catch (error) {
      console.log('formData');
      console.log(error);
      return null;
    }
  }

  parseResumeResponsedData(responseData) {
    let __resume = responseData;
    let __originalWorkshift = __resume.workshift;
    let arr = [];
    if (__originalWorkshift) {
      for (const key in __originalWorkshift) {
        for (let i = 0; i < __originalWorkshift[key].length; i++) {
          arr.push(__originalWorkshift[key][i]);
        }
      }
    }
    __resume.workshift = arr;

      return __resume;
  }

}
export default new ApiResumeService();
