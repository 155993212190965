export const GET_OWN_RESUME = "GET_OWN_RESUME_REQUEST";
export const GET_OWN_RESUME_SUCCESS = "GET_OWN_RESUME_SUCCESS";
export const GET_OWN_RESUME_ERROR = "GET_OWN_RESUME_ERROR";

export const GET_OTHER_RESUME = "GET_OTHER_RESUME";

export const GET_NATIONALITY = "GET_NATIONALITY";

export const SAVE_TO_MY_LIST = "SAVE_TO_MY_LIST";
export const SAVE_TO_MY_LIST_SUCCESS = "SAVE_TO_MY_LIST_SUCCESS";
export const SAVE_TO_MY_LIST_ERROR = "SAVE_TO_MY_LIST_ERROR";
