import { main, white } from "colors";

export const styles = {
  mobileAction: {
    display: "none",
    "@media (max-width: 800px)": {
      marginTop: "50px",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      height: "220px",
    },
  },
  btn: {
    width: "calc(100% - 32px)",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    padding: "10px 0",
    margin: "10px auto",
  },
  save: {
    color: `${white}`,
    background: `${main}`,
    marginBottom: "20px",
  },
  publish: {
    color: `${main}`,
    border: `1px solid ${main}`,
  },
  goTop: {
    position: "absolute",
    bottom: "30px",
    right: "16px",
  },
};
