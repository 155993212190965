import React, { useEffect, useState, useRef } from "react";
import * as styles from './styles';
import './styles.css';
import { Utils } from "utils/utils";
import MaterialIconArrowLeft from '@mui/icons-material/ChevronLeft';
import MaterialIconArrowRight from '@mui/icons-material/ChevronRight';
import Loader from "custom/loader/Loader";
import { useSelector } from "react-redux";
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const PDF_PAGES_SPACING = 20;

const PDFLoadingState = {
    loading: 0,
    success: 1,
    error: 2,
};

export default function PDFViewer({ 
    file, initViewerWidth, minViewerWidth, pagesSpacing,
    onDocumentLoad, onPageLoad, onReactToEndPage
 }) {
    
    let _windowResizeListener;
    let _pageIndex = 0; // mobile device doesnot use state.pageIndex (not smooth)

    const language = useSelector((state) => state.footer.language);

    const [viewerWidth, setViewerWidth] = useState(initViewerWidth || 0);
    const [viewerFrameRatio, setViewerFrameRatio] = useState(0);
    const [loadingState, setLoadingState] = useState(PDFLoadingState.loading);
    const [pageCount, setPageCount] = useState(1);
    const [pageIndex, setPageIndex] = useState(0);

    let containerRef = useRef();
    let scrollRef = useRef();
    let scrollContentRef = useRef();

    useEffect(() => {
        _windowResizeListener = () => updateViewerWidth();
        window.addEventListener("resize", _windowResizeListener);
        updateViewerWidth();

        // 👇️ remove the event listener when the component unmounts
        return () => {
            if (_windowResizeListener) {
                window.removeEventListener("resize", _windowResizeListener);
            }
        };
    }, []);

    useEffect(() => {
        setViewerWidth(initViewerWidth);
        updateViewerWidth();
    }, [initViewerWidth]);

    const updateViewerWidth = () => {
        if (containerRef) {
            let windowWidth = window.innerWidth;
            if (Utils.isIOS() && screen && screen.width) {
                windowWidth = screen.width;
            }
            let __viewerWidth = Math.min((containerRef.current).getBoundingClientRect().width, windowWidth - 26);
            if (minViewerWidth && minViewerWidth > 0) {
                __viewerWidth = Math.max(viewerWidth, minViewerWidth);
            }
            setViewerWidth(__viewerWidth);
        }
    };

    const handleOnLoadPDFError = () => {
        if (!containerRef) {
            return;
        }
        setLoadingState(PDFLoadingState.error);
        if (onReactToEndPage) {
            onReactToEndPage();
        }
    };

    const handleOnLoadPDFSuccess = (__pageCount) => {
        if (!containerRef) {
            return;
        }
        setLoadingState(PDFLoadingState.success);
        setPageCount(__pageCount);
        if (onDocumentLoad) {
            onDocumentLoad(__pageCount);
        }
        if (__pageCount <= 1 && onReactToEndPage) {
            onReactToEndPage();
        }
    };

    const handleOnPageLoad = (e) => {
        if (!containerRef) {
            return;
        }

        if (pageCount > 1 && viewerFrameRatio == 0) {
            setViewerFrameRatio(e.originalHeight / e.originalWidth);
        }
        
        if (onPageLoad) {
            let originalWidth = e.originalWidth;
            let originalHeight = e.originalHeight;
            if (e.pageInfo && e.pageInfo.rotate) {
                const rotate = Math.abs(e.pageInfo.rotate);
                if (rotate == 90 || rotate == 270) {
                    originalWidth = e.originalHeight;
                    originalHeight = e.originalWidth;
                }
            }
            onPageLoad(e.pageIndex, originalWidth, originalHeight);
        }
    };

    const handleOnPageError = (e) => {
        console.log('handleOnPageError');
        console.log(e);
    };

    const handleOnPageRender = () => {
        console.log('handleOnPageRender');  
    };

    const handleOnGoPreviousPageClick = () => {
        if (pageIndex > 0) {
            _pageIndex = pageIndex - 1;
            setPageIndex(_pageIndex);
            if (pageCount > 1) {
                const scrollView = scrollRef?.current;
                if (scrollView) {
                    const __pagesSpacing = pagesSpacing || PDF_PAGES_SPACING;
                    scrollView.scrollTop = (viewerWidth * viewerFrameRatio + __pagesSpacing) * pageIndex;
                }
            }
        }
    };

    const handleOnGoNextPageClick = () => {
        if (pageIndex < pageCount - 1) {
            _pageIndex = pageIndex + 1;
            setPageIndex(_pageIndex);
            if (pageCount > 1) {
                const scrollView = scrollRef?.current;
                if (scrollView) {
                    const __pagesSpacing = pagesSpacing || PDF_PAGES_SPACING;
                    scrollView.scrollTop = (viewerWidth * viewerFrameRatio + __pagesSpacing) * pageIndex;
                }
            }
        }
    };

    useEffect(() => {
        handleOnScroll();
    }, [viewerFrameRatio]);

    const handleOnScroll = () => {
        if (containerRef && pageCount > 1) {
            const scrollView = scrollRef?.current;
            if (scrollView) {
                const isMobileDevice = Utils.isMobile() || ('ontouchstart' in window);
                const oldPageIndex = isMobileDevice ? _pageIndex : pageIndex;
                const pageHeight = (viewerWidth * viewerFrameRatio);
                // const contentHeight = scrollContentRef.current.getBoundingClientRect().height;
                const scrollFrameHeight = scrollView.getBoundingClientRect().height;
                let newPageIndex = Math.floor((scrollView.scrollTop + scrollFrameHeight) / pageHeight);

                newPageIndex = Math.min(newPageIndex, pageCount - 1);
                newPageIndex = Math.max(newPageIndex, 0);
                if (newPageIndex != oldPageIndex) {
                    _pageIndex = newPageIndex;
                    if (!isMobileDevice) { // not mobile devices
                        setPageIndex(newPageIndex);
                    }
                }
                
                if (newPageIndex >= pageCount - 1 && onReactToEndPage) {
                    onReactToEndPage();
                }
            }
        }
    };

    // ===========================================================================

    let pdfPageList = [];
    if (pageCount > 0) {
        for (let i = 0; i < pageCount; i++) {
            pdfPageList.push(
                <Page key={'page_' + i}
                    className={i > 0 ? 'pdfviewer-pages-spacing' : ''}
                    pageIndex={i}
                    width={viewerWidth} 
                    onLoadError={() => console.log('page.load error')}
                    onLoadSuccess={(e) => handleOnPageLoad(e)}
                />
            );
        }
    }

    return (
        <div ref={containerRef} className="PDFViewer-191203" key="PDFViewer-191203---">
            <div ref={scrollRef} className="pdfviewer-scroll-view"
                onScroll={() => handleOnScroll()}
            >
                <div ref={scrollContentRef}>
                    <div key="pdf-page-0">
                        <Document
                            file={file}
                            loading={''}
                            error={<div style={styles.errorText}>
                                <div className="text-center">{language['ImageViewer.failedToLoadFile']}</div>
                                <div className="flexrow hoz-center">
                                    <a onClick={() => window.open(file, '_blank')} href="#" target="_blank" className="text-center pointer">
                                        {'Open file in a new tab'}
                                    </a>
                                </div>
                            </div>}
                            onLoadError={() => handleOnLoadPDFError()}
                            onLoadSuccess={(e: any) => handleOnLoadPDFSuccess(e.numPages)}
                            onPageLoad={(e: any) => handleOnPageLoad(e)}
                            onPageRender={() => handleOnPageRender()}
                            onPageError={(e) => handleOnPageError(e)}
                        >
                            {pdfPageList}
                        </Document>
                    </div>
                </div>
            </div>
            {(loadingState == PDFLoadingState.success && !Utils.isMobile() && !('ontouchstart' in window)) &&
                <div className="pdfviewer-toolbar flexrow hoz-center ver-center">
                    <div className={pageIndex == 0 ? '' : 'pointer mouse-over-opacity'}
                        style={{opacity: pageIndex == 0 ? 0.3 : 1}}
                        onClick={() => handleOnGoPreviousPageClick()}
                    >
                        <div className="flexrow ver-center">
                            <MaterialIconArrowLeft color="#FFF" style={{width: '24px', height: '24px'}} />
                        </div>
                    </div>
                    <div className="pdfviewer-page-number">
                        {(pageIndex + 1) + '/' + pageCount}
                    </div>
                    <div className={pageIndex < (pageCount - 1) ? 'pointer mouse-over-opacity' : ''}
                        style={{opacity: pageIndex < (pageCount - 1) ? 1 : 0.3}}
                        onClick={() => handleOnGoNextPageClick()}
                    >
                        <div className="flexrow ver-center">
                            <MaterialIconArrowRight color="#FFF" style={{width: '24px', height: '24px'}} />
                        </div>
                    </div>
                </div>
            }
            {loadingState == PDFLoadingState.loading &&
                <div className="pdfviewer-loading flexrow hoz-center relative">
                    <Loader />
                </div>
            }
        </div>
    );
}
