import React from "react";
import Footer from "components/footer/Footer";
import Header from "components/header/Header";

import { Box } from "@mui/material";

const ResumeLayout = ({ children, headerProps, innerRef }) => {
  return (
    <Box ref={innerRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Header {...headerProps} />
      <Box
        sx={{
          flexGrow: "1",
          "@media (max-width: 800px)": {
            padding: "0",
            marginTop: " -41px",
          },
        }}
      >
        {children}
      </Box>
      <Footer />
    </Box>
  );
};

export default ResumeLayout;
