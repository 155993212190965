import { main } from "colors";

export const styles = {
  box: {
    width: "100%",
    position: "relative",
  },
  input: {
    width: "calc(100% - 62px)",
    borderRadius: "3px",
    padding: "10px 15px 10px 45px",
    outlineColor: `${main}`,
    marginBottom: "20px",
    fontSize: '16px'
  },
  iconAtTheStart: {
    position: "absolute",
    top: "6px",
    left: "12px",
    color: "#ababab",
  },
  iconAtTheEnd: {
    position: "absolute",
    top: "6px",
    right: "12px",
    color: "#ababab",
  },
};
