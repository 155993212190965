import React from "react";

import { styles } from "./pageNotFound.styles";
import { Typography, Box } from "@mui/material";
import SampleLayout from "layouts/SampleLayout";

export default function PageNotFound() {
  return (
    <SampleLayout>
      <div style={styles.section}>
        <Box sx={styles.box}>
          <img src="../../svg/404.svg" alt="" />
          <Typography sx={styles.caption}>
            Whoops, Hayamaru-kun is confused.
          </Typography>
          <Typography>We can’t find the page you’re looking for.</Typography>
          <a href="/resumes" style={styles.link}>
            Go Back
          </a>
        </Box>
      </div>
    </SampleLayout>
  );
}
