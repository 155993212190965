import React from "react";
import { main } from "colors";
import ModalComponent from "components/modal/ModalComponent";

import { Box, Typography } from "@mui/material";

const styles = {
  block: {
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: "400",
    background: "white",
    textAlign: "center",
    padding: "25px",
    color: "#222222",
    width: "240px",
    margin: "0 16px",
  },
  image: {
    margin: "20px auto",
    display: "block",
  },
  button: {
    display: "block",
    border: `1px solid ${main}`,
    color: `${main}`,
    borderRadius: "4px",
    marginLeft: "auto",
    // marginTop: "19px",
    padding: "8px 17px",
  },
};
export default function OpenQRCode({ resume, isOpen, setIsOpen, code }) {
  return (
    <ModalComponent isOpen={isOpen}>
      <Box sx={styles.block}>
        <Typography>
          {resume.surname} {resume.name}
        </Typography>
        <Typography>Resume QR Code</Typography>
        <img src={code} alt="qrcode" style={styles.image} />
        <button style={styles.button} onClick={() => setIsOpen(false)}>
          Close
        </button>
      </Box>
    </ModalComponent>
  );
}
