import React, { useState } from "react";
import { danger, grey } from "colors";
import { v4 as uuidv4 } from "uuid";

import { Box, Typography } from "@mui/material";
import { styles } from "./autoInput.styles";
import AutoOtput from "../../outputs/autoOutput/AutoOtput";
import { useSelector } from "react-redux";

export default function AutoInput({
  type,
  value,
  name,
  title,
  errors,
  isRequired,
  setFieldValue,
  isSubmitted,
}) {
  const [text, setText] = useState("");
  let isError =
    isSubmitted && errors ? `1px solid ${danger}` : `1px solid ${grey}`;
  const language = useSelector((state) => state.footer.language);

  const handleKeyPress = (event) => {
    if (text === "") return;
    if (event.key === "Enter" && event.keyCode == 13) {
      let obj = {
        id: uuidv4(),
        title: text,
      };
      if (value.length <= 9) {
        setFieldValue(name, [...value, obj], false);
      }
      setText('');
    }
  };

  const handleRemoveItem = (item) => {
    let filteredArray;
    if (item._id) {
      filteredArray = value.filter((el) => el._id !== item._id);
    } else {
      filteredArray = value.filter((el) => el.id !== item.id);
    }

    setFieldValue(name, filteredArray);
  };

  return (
    <Box sx={{ marginBottom: "13px" }}>
      <Typography sx={styles.title}>
        {isRequired && <span style={{ color: `${danger}` }}>*</span>}
        {title}
      </Typography>
      <input
        type={type}
        name={name}
        value={text}
        onChange={(e) => setText(e.target.value)}
        onKeyUp={(event) => handleKeyPress(event)}
        maxLength="35"
        placeholder={language['Resume.offer.placeholder']}
        style={{
          ...styles.textField,
          width: `${(text.length + 1) * 7}` + "px",
          border: isError,
        }}
        enterKeyHint="enter"
      />
      {value?.map((item, index) => {
        return (
          <AutoOtput
            key={index}
            item={item}
            handleRemoveItem={handleRemoveItem}
          />
        );
      })}
    </Box>
  );
}
