export const styles = {
  inputResumeTitle: {
    width: '100%',
    maxWidth: "292px",
    border: "none",
    fontWeight: "400",
    fontSize: "19px",
    outline: "none",
  },
  iconEdit: {
    height: '20px',
  }
};
