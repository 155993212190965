import { danger, main, dark_grey } from "colors";

export const styles = {
  boxWrapper: {
    marginBottom: '23px'
  },

  input: {
    border: `1px solid ${dark_grey}`,
    borderRadius: "5px",
    height: "40px",
    paddingLeft: "10px",
    outlineColor: `${main}`,
    fontSize: '16px'
  },

  require: {
    color: `${danger}`,
  },

  dateIconWrapper: {
    position: 'absolute',
    right: '8px',
    bottom: '10px'
  },

  dateIcon: {
    width: '20px',
    height: '20px',
  },

  datePicker: {
    opacity: 0,
    pointerEvents: 'none',
    width: '100%',
    height: '0px',
    overflow: 'hidden'
  }
};
