import React, { useEffect, useState } from "react";
import UploadImage from "custom/buttons/uploadButton/UploadImage";
import TextInput, { TextInputType } from "custom/inputs/textInput/TextInput";
import Selector from "custom/selector/Selector";
import { useSelector } from "react-redux";
import { useResumeActions } from "redux/resume/useResumeActions";

import { Box } from "@mui/material";
import { styles } from "./index.styles";

export default function SelfIntroduction({
  values,
  handleChange,
  setFieldValue,
  errors,
  typeAction,
  isSubmitted,
  setImageAction,
}) {
  const { getNationality } = useResumeActions();
  const nationality = useSelector((state) => state.resumeDetail.nationality);
  const language = useSelector((state) => state.footer.language);
  const languageCode = useSelector((state) => state.footer.languageCode);
  const [ countries, setCountries ] = useState([]);

  useEffect(() => {
    if (!nationality || nationality.length == 0) {
      getNationality();
    }
  }, []);

  useEffect(() => {
    let countryList = [];
    if (nationality && nationality.length) {
      for (let i = 0; i < nationality.length; i++) {
        if (nationality[i].language && nationality[i].language === languageCode) {
          countryList = nationality[i].countries;
          break;
        }
      }
      // default: en
      if (countryList.length == 0) {
        for (let i = 0; i < nationality.length; i++) {
          if (nationality[i].language && nationality[i].language === 'en') {
            countryList = nationality[i].countries;
            break;
          }
        }
      }
    }
    setCountries(countryList);
  }, [nationality, languageCode]);

  return (
    <div style={styles.block}>
      <UploadImage
        setFieldValue={setFieldValue}
        uploadImage={values.mainImage}
        typeAction={typeAction}
        setImageAction={setImageAction}
        language={language}
      />

      <Box sx={styles.innerBlock}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <TextInput
            name={"surname"}
            text={language['Resume.forms.lastName']}
            isRequired={true}
            value={values.surname}
            errors={errors.surname}
            onChange={handleChange}
            half={"49%"}
            isSubmitted={isSubmitted}
          />

          <TextInput
            name={"name"}
            text={language['Resume.forms.firstName']}
            isRequired={true}
            value={values.name}
            errors={errors.name}
            onChange={handleChange}
            half={"49%"}
            isSubmitted={isSubmitted}
          />
        </Box>

        {languageCode === 'ja' ?
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <TextInput
              name={"kanaSurname"}
              text={language['Resume.forms.lastNameKana']}
              isRequired={true}
              value={values.kanaSurname}
              errors={errors.kanaSurname}
              onChange={handleChange}
              placeholder={language['Resume.forms.lastNameKanaEx']}
              half={"49%"}
              isSubmitted={isSubmitted}
            />

            <TextInput
              name={"kanaName"}
              text={language['Resume.forms.firstNameKana']}
              isRequired={true}
              value={values.kanaName}
              errors={errors.kanaName}
              onChange={handleChange}
              placeholder={language['Resume.forms.firstNameKanaEx']}
              half={"49%"}
              isSubmitted={isSubmitted}
            />
          </Box>
          : null
        }

        <TextInput
          name={"position"}
          text={language['Resume.forms.positions']}
          isRequired={false}
          value={values.position}
          onChange={handleChange}
          placeholder={language['Resume.forms.positionsEx']}
        />

        <Selector
          name={"nationality"}
          text={language['Resume.forms.nationality']}
          value={values.nationality}
          onChange={handleChange}
          options={countries}
          errors={errors.nationality}
          isSubmitted={isSubmitted}
        />

        <Selector
          name={"gender"}
          text={language['Resume.forms.gender']}
          value={values.gender}
          onChange={handleChange}
          options={[
            {value: 'male', label: language['Resume.forms.gender.male']},
            {value: 'female', label: language['Resume.forms.gender.female']}
          ]}
          errors={errors.gender}
          isSubmitted={isSubmitted}
        />

        <TextInput
          type={TextInputType.DATE}
          dateFormat={language['dateFormat']}
          name={"birthday"}
          text={language['Resume.forms.birthday']}
          isRequired={true}
          value={values.birthday}
          onChange={(__value) => {
            setFieldValue('birthday', __value);
          }}
          errors={errors.birthday}
          isSubmitted={isSubmitted}
        />

        <TextInput
          name={"phone"}
          text={language['Resume.forms.phoneNumber']}
          isRequired={true}
          value={values.phone}
          onChange={handleChange}
          placeholder={language['Resume.forms.phoneNumberEX']}
          errors={errors.phone}
          isSubmitted={isSubmitted}
        />

        <TextInput
          inputType={"email"}
          name={"eMail"}
          text={language['Resume.forms.email']}
          isRequired={true}
          value={values.eMail}
          onChange={handleChange}
          placeholder={language['Resume.forms.emailEx']}
          errors={errors.eMail}
          isSubmitted={isSubmitted}
        />

        <TextInput
          name={"address"}
          text={language['Resume.forms.address']}
          isRequired={true}
          value={values.address}
          onChange={handleChange}
          errors={errors.address}
          isSubmitted={isSubmitted}
        />

        <TextInput
          name={"busStation"}
          text={language['Resume.forms.nearestStation']}
          isRequired={true}
          value={values.busStation}
          onChange={handleChange}
          errors={errors.busStation}
          isSubmitted={isSubmitted}
        />

        <TextInput
          name={"transport"}
          text={language['Resume.forms.transports']}
          isRequired={true}
          value={values.transport}
          onChange={handleChange}
          errors={errors.transport}
          isSubmitted={isSubmitted}
        />
      </Box>
    </div>
  );
}
