import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { routes } from "routing/Routes";

import MenuIcon from "@mui/icons-material/Menu";
import { Box, Button } from "@mui/material";
import { styles } from "./header.styles";
import Avatar from "../avatar/Avatar";
import PartTimeLogo from "../logo/PartTimeLogo";
import Navigation from "../navigation/Navigation";
import ParsedText from "utils/parsed-text/ParsedText";
import { Typography } from "../../../node_modules/@mui/material/index";

export default function Header({ rightButtonTitle, rightButtonClick }) {
  const history = useHistory();
  const [isShow, setIsShow] = useState(false);
  const user = useSelector((state) => state.profile.accessToken);
  const language = useSelector((state) => state.footer.language);

  const goToLogin = () => {
    let redirectURL = routes().signin;
    const currentPath = window.location.pathname.split('?')[0];
    if (currentPath.indexOf('/resumes/') == 0) {
      const resumeId = currentPath.substring('/resumes/'.length);
      redirectURL = redirectURL + '?resume_id=' + resumeId;
    }
    history.push(redirectURL);
  };

  const goToSignup = () => {
    let redirectURL = routes().signup;
    const currentPath = window.location.pathname.split('?')[0];
    if (currentPath.indexOf('/resumes/') == 0) {
      const resumeId = currentPath.substring('/resumes/'.length);
      redirectURL = redirectURL + '?resume_id=' + resumeId;
    }
    history.push(redirectURL);
  };

  return (
    <Box sx={styles.header}>

      {/* mobile */}
      <Box sx={styles.mobile}>
        <Box onClick={() => history.push(!user ? routes().signup : routes().resumes)}>
          <img src="../../logo/logo.svg" alt="logo" />
        </Box>
        <Box>
          <MenuIcon sx={styles.burgerMenu} onClick={() => setIsShow(!isShow)} />
        </Box>
      </Box>
      {isShow && <Navigation setIsShow={setIsShow} isShow={isShow} />}

      {/* desktop */}
      <Box sx={styles.content}>
        <Box sx={styles.box}>
          {/* menu links */}
          <Navigation />
          <PartTimeLogo language={language} />
        </Box>

        <img src="../../header.png" alt="header" />

        <Box sx={Object.assign({}, styles.box, styles.boxOnRightSide)}>
          <div>
            {user ? 
              <Avatar />
              :
              <div>
                <Typography sx={styles.loginToSave} display="inline">
                  <ParsedText 
                    text={language['Resume.loginToSaveResume']}
                    useWrapperInlineElement={true}
                    parse={
                      [
                        {
                          pattern: /<a([^>]*)>([^<]*)<\/a>/i,
                          renderText: (text, matches) => {
                            if (matches.some((__t) => __t.indexOf('login') >= 0)) {
                              return <a className="app-color-primary pointer mouse-over-opacity"
                                onClick={() => goToLogin()}
                              >
                                {matches[matches.length - 1]}
                              </a>;
                            } else if (matches.some((__t) => __t.indexOf('signup') >= 0)) {
                              return <a className="app-color-primary pointer mouse-over-opacity"
                                onClick={() => goToSignup()}
                              >
                                {matches[matches.length - 1]}
                              </a>;
                            }
                          }
                        },
                      ]
                    }
                  />
                </Typography>
              </div>
            }
          </div>
          <div>
            {(rightButtonTitle && rightButtonTitle !== '') &&
              <Button
                sx={styles.createButton}
                onClick={() => rightButtonClick()}
              >
                {rightButtonTitle}
              </Button>
            }
          </div>
        </Box>
      </Box>
    </Box>
  );
}
