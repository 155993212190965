import * as React from "react";
import {Component, HTMLProps} from "react";

interface Props extends HTMLProps<any> {
    color?: string;
    width?: string;
}

interface State {
    
}

export default class SvgIcon_Share extends Component<Props, State> {
    
    static defaultProps = {
        color: '#29CC8F',
        width: '17px',
    };

    constructor(props: Props) {
        super(props);
    }

    render() {
        let fillColor = this.props.color || '';
        if (fillColor.length == 0) {
            fillColor = '#29CC8F';
        }

        return (
            <svg width={this.props.width} height={this.props.width} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M7.01504 1.80066H2C0.89543 1.80066 0 2.69609 0 3.80066V15C0 16.1046 0.89543 17 2 17H13.1993C14.3039 17 15.1993 16.1046 15.1993 15V3.80066C15.1993 3.79655 15.1993 3.79244 15.1993 3.78833V9.98421H13.1993V15H2L2 3.80066H7.01504V1.80066Z" fill={fillColor}/>
                <path fillRule="evenodd" clipRule="evenodd" d="M12.7865 2.79993H9.35372V0.799927H15.1996H16.1996V1.79993V7.64583H14.1996V4.21519L7.72281 10.692L6.30859 9.27779L12.7865 2.79993Z" fill={fillColor}/>
            </svg>
        );
    }

}