import React, { useEffect } from "react";
import { main } from "colors";
import {
  EmailShareButton,
  EmailIcon,
  LineShareButton,
  LineIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";
import { useToastActions } from "redux/toast/useToastActions";
import { useSelector } from "react-redux";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, Typography } from "@mui/material";
import { styles } from "./sharing.styles";

export default function Sharing({ setIsOpen }) {
  let _keyPressListener;

  const shareUrl = window.location.href;
  const { showToast } = useToastActions();
  const language = useSelector((state) => state.footer.language);

  useEffect(() => {
    _keyPressListener = (e) => handleOnKeyPress(e);
    document.addEventListener("keydown", _keyPressListener);

    return () => {
      if (_keyPressListener) {
        document.removeEventListener("keydown", _keyPressListener);
        _keyPressListener = null;
      }
    };
  }, []);

  const handleOnKeyPress = (e) => {
    if ((e.key === 'Escape' || e.key === 'Esc') && e.keyCode == 27) {
      setIsOpen(false);
    }
  };

  const copy = () => {
    setIsOpen(false);
    navigator.clipboard.writeText(window.location.href);
    showToast("Link Copied");
  };

  return (
    <Box sx={styles.container}>
      <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
        {language['Resume.forms.share']}
      </Typography>
      <Box sx={styles.inner}>
        <Box sx={styles.social} onClick={copy}>
          <Box sx={styles.copyContainer}>
            <Box sx={styles.copyInner}>
              <ContentCopyIcon sx={{ fill: "#ffffff" }} />
            </Box>
          </Box>
          <Typography sx={{ ...styles.caption, marginTop: "4px" }}>
            Copy Link
          </Typography>
        </Box>

        <Box sx={styles.social}>
          <EmailShareButton url={shareUrl} beforeOnClick={() => setIsOpen(false)}>
            {(shareCount) => (
              <span className="myShareCountWrapper">{shareCount}</span>
            )}
            <EmailIcon round={true} bgStyle={{ fill: `${main}` }} />
          </EmailShareButton>
          <Typography sx={styles.caption}>E-mail</Typography>
        </Box>

        <Box sx={styles.social}>
          <LineShareButton url={shareUrl} beforeOnClick={() => setIsOpen(false)}>
            {(shareCount) => (
              <span className="myShareCountWrapper">{shareCount}</span>
            )}
            <LineIcon round={true} />
          </LineShareButton>
          <Typography sx={styles.caption}>Line</Typography>
        </Box>

        <Box sx={styles.social}>
          <TwitterShareButton url={shareUrl} beforeOnClick={() => setIsOpen(false)}>
            {(shareCount) => (
              <span className="myShareCountWrapper">{shareCount}</span>
            )}
            <TwitterIcon round={true} />
          </TwitterShareButton>

          <Typography sx={styles.caption}>Twitter</Typography>
        </Box>
      </Box>

      <Box sx={styles.buttonBlock}>
        <button style={styles.btn} onClick={() => setIsOpen(false)}>
          Cancel
        </button>
      </Box>
    </Box>
  );
}
