export const styles = {
    block: {
        position: 'relative',
        // padding: '0 16px'
    },
    innerBlock: {
        "@media (max-width: 800px)": {
            padding: '0 16px'           
        },
    }
}