import { dark_grey, grey, main } from "colors";

export const styles = {
  block: {
    position: "relative",
    display: "inline-flex",
    alignItems: "center",
    justifyItems: "start",
    border: `1px solid ${main}`,
    borderRadius: "31px",
    minHeight: "27px",
    marginRight: "10px",
    marginBottom: "20px",
    padding: "7px 0px 7px 8px",
  },
  title: {
    fontSize: '16px',
    fontFamily: 'Roboto',
    paddingRight: '7px',
    wordBreak: 'break-word',
    flex: '1'
  },
  duration: {
    fontSize: '11px',
    fontFamily: 'Roboto',
    paddingLeft: '7px',
    paddingRight: '7px',
  },
  removeButton: {
    fontSize: "18px",
    border: "none",
    padding: '2px 4px 2px 2px',
    display: 'inline-flex'
  },
  removeIcon: {
    transform: "rotate(45deg)",
    color: `${dark_grey}`,
  },
  separator1: {
    width: "1px",
    borderRight: `1px solid ${grey}`,
    alignSelf: 'stretch'
  },
  separator2: {
    width: "1px",
    borderRight: `1px solid ${main}`,
    alignSelf: 'stretch'
  },
};
