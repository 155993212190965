import { showToast } from "redux/toast/toast.actions";
import ApiResumeService from "services/ApiResumeService";

import {
  CLONE_SELECTED_RESUME,
  CLONE_SELECTED_RESUME_ERROR,
  CLONE_SELECTED_RESUME_SUCCESS,
  GET_OTHER_RESUME_LIST,
  GET_OTHER_RESUME_LIST_ERROR,
  GET_OTHER_RESUME_LIST_SUCCESS,
  GET_OWN_RESUMES_LIST,
  GET_OWN_RESUMES_LIST_ERROR,
  GET_OWN_RESUMES_LIST_SUCCESS,
} from "./resumeList.types";

export const getOwnResumeList = () => {
  return async (dispatch) => {
    try {
      dispatch(getOwnResumeListInit());
      const response = await ApiResumeService.getAllOwn();
      dispatch(getOwnResumeListSuccess(response.data));
    } catch (error) {
      dispatch(getOwnResumeListError(error.response.data.message));
    }
  };
};

export const getOwnResumeListInit = () => {
  return { type: GET_OWN_RESUMES_LIST };
};

export const getOwnResumeListSuccess = (payload) => {
  return { type: GET_OWN_RESUMES_LIST_SUCCESS, payload };
};

export const getOwnResumeListError = (payload) => {
  return { type: GET_OWN_RESUMES_LIST_ERROR, payload };
};

export const getOtherResumeList = () => {
  return async (dispatch) => {
    try {
      dispatch(getOtherResumeListInit());
      const response = await ApiResumeService.getAllOthers();
      dispatch(getOtherResumeListSuccess(response.data));
    } catch (error) {
      dispatch(getOtherResumeListError(error.response.data.message));
    }
  };
};

export const getOtherResumeListInit = () => {
  return { type: GET_OTHER_RESUME_LIST };
};

export const getOtherResumeListSuccess = (payload) => {
  return { type: GET_OTHER_RESUME_LIST_SUCCESS, payload };
};

export const getOtherResumeListError = (payload) => {
  return { type: GET_OTHER_RESUME_LIST_ERROR, payload };
};

// COPY
export const copyResume = (resumeId) => {
  return async (dispatch) => {
    try {
      dispatch(cloneResumeInit());
      const response = await ApiResumeService.copyResume(resumeId);

      dispatch(cloneResumeSuccess(response.data));
      dispatch(showToast(`Created "${response.data.resumeTitle}"`));
    } catch (error) {
      dispatch(cloneResumeError(error.response.data.message));
      dispatch(showToast(error.response.data.message));
    }
  };
};

export const cloneResumeInit = () => {
  return { type: CLONE_SELECTED_RESUME };
};

export const cloneResumeSuccess = (payload) => {
  return { type: CLONE_SELECTED_RESUME_SUCCESS, payload };
};

export const cloneResumeError = (payload) => {
  return { type: CLONE_SELECTED_RESUME_ERROR, payload };
};
