import React, { useState, useRef } from "react";
import { main, warning } from "colors";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { useToastActions } from "redux/toast/useToastActions";
import { routes } from "routing/Routes";
import { useSelector } from "react-redux";

import { Box, Typography } from "@mui/material";
import { styles } from "./resumeCard.styles";
import ModalConfirmation from "../modalConfirmation/ModalConfirmation";
import { ResumeStatus } from "models/resume";
import ApiResumeService from "services/ApiResumeService";

export default function OwnResumeCard({ resume, onReloadResumeList }) {
  const history = useHistory();
  const [isDisplayModalConfirmDelete, setIsDisplayModalConfirmDelete] = useState(false);
  const { showToast } = useToastActions();
  const language = useSelector((state) => state.footer.language);
  const containerRef = useRef();

  const removeResume = async () => {
    setIsDisplayModalConfirmDelete(false);
    const result = await ApiResumeService.removeResume(resume._id);
    if (result && result.success) {
      showToast(language['Resume.remove.successfull']);
      onReloadResumeList && onReloadResumeList();
    } else if (containerRef) {
      if (result && result.message) {
        showToast(result.message);
      }
    }
  };

  const copyResume = async () => {
    const result = await ApiResumeService.copyResume(resume._id);
    if (result && result.success) {
      showToast(language['Resume.copy.successfull']);
      onReloadResumeList && onReloadResumeList();
    } else if (containerRef) {
      if (result && result.message) {
        showToast(result.message);
      }
    }
  };

  const openResume = () => {
    if (resume.status !== ResumeStatus.published) {
      showToast(language['Resume.showToast.NeedPublishedForDownloading']);
      return;
    }
    history.push(routes({ id: resume._id }).resumeById);
  };

  return (
    <>
      <ModalConfirmation
        onYesButtonClick={removeResume}
        setIsOpen={setIsDisplayModalConfirmDelete}
        isOpen={isDisplayModalConfirmDelete}
        resume={resume}
        language={language}
      />
      <Box sx={styles.cardsItem} ref={containerRef}>
        <button
          onClick={() => {
            setIsDisplayModalConfirmDelete(true);
          }}
          style={styles.deleteButton}
        >
          <img src="../../svg/delete.svg" alt="remove" />
        </button>
        <Box
          sx={{
            ...styles.card,
            ...styles.columnFlexbox,
          }}
        >
          {resume.status && (
            <Box
              sx={
                resume.status === ResumeStatus.published
                  ? { ...styles.status, background: `${main}` }
                  : resume.status === ResumeStatus.editing
                  ? { ...styles.status, background: `${warning}` }
                  : { display: "none" }
              }
            >
              {
                resume.status === ResumeStatus.published ?
                language['Resume.page.published']
                :
                (
                  resume.status === ResumeStatus.editing ?
                  language['Resume.page.editing']
                  :
                  resume.status
                )
              }
            </Box>
          )}
          <Box>
            <Link
              className="link"
              to={routes({ id: resume._id }).resumeById}
            >
              <Box
                sx={{
                  ...styles.columnFlexbox,
                  justifyContent: "space-between",
                  height: "100px",
                }}
              >
                <Typography sx={styles.resumeTitle}>
                  {resume?.resumeTitle?.length > 50
                    ? resume?.resumeTitle.substr(0, 50) + "..."
                    : resume?.resumeTitle}
                </Typography>
                <Box sx={{ ...styles.detail, ...styles.columnFlexbox }}>
                  <Typography sx={styles.text}>
                    <b>{language['Resume.page.lastEditDate']}</b>{" "}
                    {moment(resume?.updatedAt).format("YYYY/MM/DD")}
                  </Typography>
                  <Typography sx={styles.text}>
                    <b>{language['Resume.page.createDate']}</b>
                    {moment(resume?.createdAt).format("YYYY/MM/DD")}
                  </Typography>
                </Box>
              </Box>
            </Link>
          </Box>

          <Box sx={styles.actionBox}>
            <button
              onClick={() =>
                history.push(routes({ id: resume._id }).editResumeById)
              }
              style={styles.actionButton}
            >
              <img
                src="../../svg/edit.svg"
                alt="edit"
                style={{
                  cursor: "pointer",
                  marginRight: "10px",
                  width: "100%",
                }}
              />
            </button>

            <button
              style={styles.actionButton}
              onClick={() => copyResume()}
            >
              <img
                src="../../svg/copy.svg"
                alt="copy"
                style={{ width: "100%" }}
              />
            </button>

            <button
              style={styles.actionButton}
              onClick={() => openResume()}
            >
              <img
                src="../../../svg/download.svg"
                alt="download"
                style={{ width: "100%" }}
              />
            </button>
          </Box>
        </Box>
      </Box>
    </>
  );
}
