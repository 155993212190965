import React, { useRef } from "react";

import { Box } from "@mui/material";
import { styles } from "./resumeTitleInput.styles";

export default function ResumeTitleInput({ resumeTitle, setResumeTitle }) {
  let inputRef = useRef();
  return (
    <Box className="flexitem flexrow ver-center">
      <input ref={inputRef}
        type="text" name="resume-name"
        value={resumeTitle}
        onChange={(event) => setResumeTitle(event.target.value)}
        placeholder="resume title"
        className="flexitem"
        style={{ ...styles.inputResumeTitle }}
      />
      <div onClick={() => inputRef.current.focus()} className="pointer">
        <img src="../../svg/edit.svg" alt="" style={styles.iconEdit} />
      </div>
    </Box>
  );
}
