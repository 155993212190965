import { white } from "colors";

export const styles = {
    infoBlock: {
        width: "calc(100% - 80px)",
        position: "absolute",
        bottom: "0px",
        background: "rgba(0, 0, 0, 0.5)",
        "@media (min-width: 800px)": {
            display: "none",
        },
    },
    person: {
        fontSize: "28px",
        color: `${white}`,
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "33px",
    },
    post: {
        marginTop: "5px",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "18px",
        lineHeight: "21px",
        color: `${white}`,
    },
    action: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "11px 0",
    },
    button: {
        width: "32px",
        height: "32px",
    },
    icon: {
        width: "100%",
        height: "100%",
    },
};