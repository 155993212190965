import React from "react";

import { styles } from "./scrollTopButton.styles";

export default function ScrollTopButton() {
  return (
    <button
      style={styles.goTop}
      onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
    >
      <img src="../../svg/arrow.svg" alt="" />
    </button>
  );
}
